<template lang="pug">
    transition
        .questionnaire-modal(
            v-if="isOpen"
            @click.self="close"
        )
            .content(ref="content")
                .wrapper
                    slot
                    dropper-icon.closer(@click="close")
</template>

<script>
import dropperIcon from '@/assets/images/icons/dropper.svg';
let VueScrollTo = require('vue-scrollto');

export default {
    name: 'questionnaire-modal',

    components: {
        dropperIcon,
    },

    data: () => ({
        isOpen: false,
        scrollbar: null,
    }),

    methods: {
        open() {
            this.isOpen = true;
            window.disableScroll();

            setTimeout(() => {
                if (!window.isMobileOrTablet) {
                    this.scrollbar = window.Scrollbar.init(this.$refs.content, {
                        alwaysShowTracks: true,
                        plugins:{ horizontalScroll: false },
                    });
                }
            });
        },

        close() {
            this.isOpen = false;
            window.enableScroll();
        },

        scrollToInvalidField() {
            this.$nextTick(() => {
                const invalidField = this.$refs.content.querySelector('.app-text-field--invalid, .app-choice--invalid');
                const invalidFieldBlock = invalidField.closest('.block-field, .block-choices, .block-range, .block-question');
                const addOffset = -25;
                if (invalidField) {
                    if (window.isMobileOrTablet) {
                        VueScrollTo.scrollTo(invalidFieldBlock, 500, {
                            container: '.questionnaire-modal .content',
                            offset: addOffset,
                        });
                    } else {
                        const offset = invalidFieldBlock.getBoundingClientRect().top + this.scrollbar.offset.y - this.$refs.content.getBoundingClientRect().top + addOffset;
                        this.scrollbar.scrollTo(0, offset, 500);
                    }
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100vw;

    background: rgba($dark, 0.5);

    .content {
        width: rem(966px);
        max-width: 100%;
        max-height: 90%;

        background: $white;
        border-radius: rem(8px);
        box-shadow: 0 rem(1px) rem(20px) rgba(0, 0, 0, 0.03);

        &::v-deep .scrollbar {
            &-track {
                width: rem(4px);

                background: rgba($blue, 0.25);
            }

            &-thumb {
                width: inherit;

                background: $blue;
            }
        }
    }

    .wrapper {
        position: relative;

        padding: rem(32px);
    }

    .closer {
        position: absolute;
        top: rem(34px);
        right: rem(34px);

        width: rem(20px);

        border-radius: rem(4px);

        cursor: pointer;
        transition: background-color .2s;

        fill: $blue;

        &:hover {
            background-color: rgba($blue, 0.1);
        }
    }

    &::v-deep {
        .title {
            margin-bottom: rem(32px);

            font-size: rem(24px);
            font-weight: 700;
        }

        .block {
            margin-bottom: rem(16px);

            &__name {
                margin-bottom: rem(16px);
            }

            &__footnote {
                font-size: rem(14px);
            }

            &-row {
                display: flex;
                flex-wrap: wrap;

                width: 100%;

                &__item {
                    width: calc((100% - #{rem(32px)}) / 2);

                    &:nth-of-type(2n + 1) {
                        margin-right: rem(32px);
                    }
                }
            }

            &-field {
                display: flex;
                justify-content: space-between;
                align-items: center;

                margin-bottom: rem(16px);

                &__label {
                    font-size: rem(14px);
                    color: rgba($dark, 0.5);
                }

                .app-text-field {
                    width: rem(85px);
                    margin: 0;
                }
            }

            &-choices {
                margin-right: 0;

                .app-choice-group__label {
                    margin-bottom: rem(16px);

                    font-size: rem(14px);
                }

                .app-choice:last-of-type {
                    margin-right: 0;
                }
            }

            &-range {
                width: 100%;

                .app-choice-group {
                    &__label {
                        margin-bottom: rem(16px);

                        font-size: rem(14px);
                    }

                    &__list {
                        display: flex;
                        justify-content: space-between;
                    }
                }

                .app-choice:last-child {
                    margin-right: 0;
                }

                &__extremes {
                    display: flex;
                    justify-content: space-between;

                    margin-top: rem(16px);

                    font-size: rem(14px);

                    span:first-of-type {
                        color: $green;
                    }

                    span:last-of-type {
                        color: $red;
                    }
                }
            }

            &-question {
                &__name {
                    margin-bottom: rem(12px);
                }

                .app-choice-group {
                    width: 100%;
                }

                .app-choice {
                    margin-bottom: rem(10px);

                    label {
                        white-space: initial;
                    }
                }
            }
        }

        .bottom {
            display: flex;
            align-items: center;

            padding-top: rem(32px);

            border-top: 2px solid $light-blue;

            .result {
                font-size: rem(24px);
            }

            .app-button {
                justify-content: center;

                width: rem(240px);
                margin-bottom: 0;

                &:last-child {
                    margin-right: 0;
                }

                &:nth-last-child(2) {
                    margin-left: auto;
                }
            }
        }
    }

    @include mobile_or_P {
        .content {
            max-height: 100%;
            overflow: auto;

            border-radius: 0;
        }

        .wrapper {
            max-width: 100vmin;
            margin: 0 auto;
            padding: rem(32px) rem(15px);
        }

        .closer {
            top: rem(38px);
            right: rem(20px);
        }

        &::v-deep {
            .block {
                &__name {
                    margin-bottom: rem(24px);
                }

                &-row {
                    &__item {
                        width: 100%;

                        &:nth-of-type(2n + 1) {
                            margin-right: 0;
                        }
                    }
                }

                &-choices {
                    margin-bottom: rem(8px);

                    .app-choice-group__list {
                        display: flex;
                        justify-content: space-between;
                    }

                    .app-choice {
                        &__circle {
                            margin: 0 0 rem(8px);
                        }

                        label {
                            flex-direction: column;
                        }
                    }
                }

                &-range {
                    margin-bottom: rem(16px);

                    .app-choice-group {
                        &__label {
                            margin-bottom: rem(24px);
                        }

                        &__list {
                            flex-wrap: wrap;
                        }
                    }

                    .app-choice {
                        width: rem(48px);
                        margin-right: calc((100% - #{rem(48px)} * 5) / 4);
                        margin-bottom: rem(24px);

                        &:nth-child(5n) {
                            margin-right: 0;
                        }
                    }

                    &__extremes {
                        display: none;
                    }
                }

                &-question {
                    .app-choice-group {
                        margin-bottom: rem(16px);
                    }
                }
            }

            .bottom {
                flex-direction: column;

                .result {
                    margin-bottom: rem(32px);
                }

                .app-button {
                    width: 100%;

                    &:nth-last-child(2) {
                        margin-bottom: rem(16px);
                    }
                }
            }
        }
    }
}
</style>
