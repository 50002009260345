<template lang="pug">
    .sick-relatives-block(:class="{'sick-relatives-block--addition-only': additionOnly}")
        .sick-relatives-block__row(
            v-for="(item, index) in relatives"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-select.sick-relatives-block--relative(
                    v-model="item.relative"
                    :options="relativesList"
                    label="Степень родства"
                    :error="getFieldError(item, index, 'relative')"
                )
                app-select.sick-relatives-block--illness(
                    v-model="item.illness"
                    :options="illnessesList"
                    searchable
                    :internal-search="false"
                    @search-change="fetchIllnesses"
                    noOptionsText="Начните вводить значение"
                    label="Заболевание (код МКБ или название)"
                    :error="getFieldError(item, index, 'illness')"
                )
                app-dropper(
                    :class="{hidden: (required && relatives.length === 1) || additionOnly && item.old}"
                    @click="dropRelative(index)"
                )
            hr.narrow(v-if="index < relatives.length - 1")
        .br
        app-button(
            plus
            @click="addNewRelative"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import illnessesList from '@/mixins/illnessesList';

export default {
    name: 'sick-relatives-block',

    mixins: [illnessesList],

    props: {
        value: {
            type: Array,
            required: true,
        },

        type: {
            type: String,
            required: true,
        },

        required: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        relatives: [],
    }),

    validations: {
        relatives: {
            $each: {
                relative: {
                    required,
                    uniqual(value, item) {
                        return !this.relatives.some(r => r.id !== item.id
                            && item.relative
                            && r.relative.id === item.relative.id
                            && r.illness.id === item.illness.id);
                    },
                },
                illness: {
                    required,
                    uniqual(value, item) {
                        return !this.relatives.some(r => r.id !== item.id
                            && item.relative
                            && r.relative.id === item.relative.id
                            && r.illness.id === item.illness.id);
                    },
                },
            },
        },
    },

    computed: {
        relativesList() {
            return this.$store.getters.relatives;
        },
    },

    watch: {
        relatives(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.value.length) {
                this.relatives = this.value;
            } else if (this.required) {
                this.addNewRelative();
            }
        });
    },

    methods: {
        addNewRelative() {
            this.relatives.push({
                id: uniqueId(),
                relative: '',
                illness: '',
            });
            this.$v.$reset();
        },

        dropRelative(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.relatives = this.relatives.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.relatives.$each.$iter[index].$dirty) {
                if (!this.$v.relatives.$each.$iter[index][field].required) {
                    return 'Заполните поле';
                } else if (!this.$v.relatives.$each.$iter[index][field].uniqual) {
                    return 'Не уникальные значения полей';
                }
            }
            return null;
        },

        getFieldsForSave() {
            return this.relatives.map(d => {
                return {
                    relatives_id: d.relative.id,
                    illnesses_id: d.illness.id,
                    type: this.type,
                };
            });
        },

        getFormattedSavedFields(relatives) {
            return relatives.map(d => ({
                id: d.id,
                relative: d.relative,
                illness: {
                    ...d.illness,
                    name: `${d.illness.code} - ${d.illness.name}`,
                },
                old: true,
            }));
        },
    },
};
</script>

<style lang="scss">
.sick-relatives-block {
    .sick-relatives-block {
        &--relative {
            flex-basis: rem(438px);
        }

        &--illness {
            flex-basis: rem(734px);
        }
    }

    &--addition-only {
        .sick-relatives-block__row.old {
            .app-select {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .sick-relatives-block {
            &--relative, &--illness {
                flex-basis: 100%;
            }
        }
    }
}
</style>
