<template lang="pug">
    .causes-exacerbations-dictionary
        .edit-dictionary__top
            .edit-dictionary__name Причины обострения
            p Данный справочник служит для указания препаратов таргетной терапии. Доза, единицы измерения и кратность могут быть указаны только в соответствии с приведенными в справочнике значениями. Если поле оставлено пустым (например, не указано значение дозы), то пи заполнении карты пациента можно будет указать любое значение.
        dictionary-default-template(
            action-name="fetchCausesExacerbations"
            getter-name="causesExacerbations"
            url-part="causes-exacerbations"
        )
</template>

<script>
import DictionaryDefaultTemplate from '@/components/pages/dictionaries/DictionaryDefaultTemplate';

export default {
    name: 'causes-exacerbations-dictionary',

    components: {
        DictionaryDefaultTemplate,
    },
};
</script>
