<template lang="pug">
    .app-horizontal-tabs(ref="app-horizontal-tabs")
        .app-horizontal-tabs__wrapper
            .app-horizontal-tabs__item(
                v-for="tab in tabs"
                :class="{active: tab.id === value, disabled: tab.disabled}"
                @click="$emit('input', tab.id)")
                span(v-html="tab.name")
            .app-horizontal-tabs__line
                .app-horizontal-tabs__indicator(:style="indicatorStyles")
</template>

<script>

export default {
    name: 'app-horizontal-tabs',

    props: {
        value: {
            type: [Number, String],
            required: true,
        },

        tabs: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        lastLeft: 0,
        indicatorStyles: {},
    }),

    watch: {
        value() {
            this.updateIndicatorStyles();
            if (window.isMobileOrTablet) {
                this.$nextTick(() => {
                    const activeTab = this.$refs['app-horizontal-tabs'].querySelector('.app-horizontal-tabs__item.active');
                    this.$refs['app-horizontal-tabs'].scrollTo({
                        left: activeTab.offsetLeft,
                        behavior: 'smooth',
                    });
                });
            }
        },
    },

    created() {
        this.updateIndicatorStyles();
    },

    methods: {
        updateIndicatorStyles() {
            const width = 100 / this.tabs.length;
            const activeTabIndex = this.tabs.findIndex((t, ind) => ind === this.value);
            const left = width * activeTabIndex;
            let transition = 'left .2s, right .2s .1s';
            if (this.lastLeft < left) {
                transition = 'left .2s .1s, right .2s';
            }
            this.lastLeft = left;
            this.indicatorStyles =  {
                left: `${left}%`,
                right: `${100 - left - width}%`,
                transition,
            };
        },
    },
};
</script>
