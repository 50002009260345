<template lang="pug">
    .contact-block
        .row
            app-datepicker.contact-block--date(
                v-model="date"
                label="Дата"
                :error="getFieldError('date')"
            )
            app-select.contact-block--type(
                v-model="contactType"
                :options="contactTypeList"
                label="Вид контакта"
                :error="getFieldError('contactType')"
            )
            app-text-field.contact-block--doctorName(
                v-model="doctorName"
                label="Фамилия врача"
                :error="getFieldError('doctorName')"
            )
            .app-choice-group.app-choice-group--center
                app-choice(
                    v-model="serumTaken"
                    type="checkbox"
                    label="Сыворотка взята"
                )
            app-text-field.contact-block--number(
                v-model="number"
                label="Номер"
                :disabled="!serumTaken"
            )
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { CONTACT_TYPES } from '@/js/const';
import moment from 'moment';

export default {
    name: 'contact-block',

    data: () => ({
        date: +moment(),
        contactType: '',
        doctorName: '',
        serumTaken: false,
        number: '',

        contactTypeList: CONTACT_TYPES,
    }),

    validations: {
        date: { required },
        contactType: { required },
        doctorName: { required },
    },

    methods: {

        getFieldError(field) {
            if (this.$v.$dirty && !this.$v[field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            const obj = {
                date_at: moment(this.date).format('YYYY-MM-DD'),
                contact_type: this.contactType.id,
                doctor_name: this.doctorName,
                serum_taken: +this.serumTaken,
            };
            if (this.number) {
                obj.number = this.number;
            }
            return obj;
        },
    },
};
</script>

<style lang="scss">
.contact-block {
    margin-bottom: -0.5rem;
    .contact-block {
        &--date, &--type, &--number, &--doctorName {
            width: rem(203px);
        }

        &--number {
            margin-right: 0;
        }
    }

    @include mobile_or_P {
        margin-bottom: 0;

        .contact-block {
            &--date, &--type, &--number, &--doctorName {
                width: 100%;
            }
        }
    }
}
</style>
