<template lang="pug">
    .smoking-block(:class="{'smoking-block--addition-only': additionOnly}")
        .row
            .app-choice-group
                .app-choice-group__label Курение
                .app-choice-group__list
                    app-choice(
                        v-model="smoking.presence"
                        type="radio"
                        label="Нет"
                        :radio-value="false"
                        :disabled="isReContact && smoking.old"
                    )
                    app-choice(
                        v-model="smoking.presence"
                        type="radio"
                        label="Да"
                        :radio-value="true"
                        :disabled="isReContact && smoking.old"
                    )
            app-text-field.smoking-block--experience(
                v-model="smoking.experience"
                is-natural-number
                label="Стаж курения"
                :disabled="!smoking.presence"
                :error="getFieldError('experience')"
            )
            app-text-field.smoking-block--perDay(
                v-model="smoking.perDay"
                is-natural-number
                label="Сигарет в день"
                :disabled="!smoking.presence"
                :error="getFieldError('perDay')"
            )
            app-text-field.smoking-block--index(
                :value="calculateSmokingIndex(smoking.experience, smoking.perDay)"
                no-pointer-events
                :disabled="!smoking.presence"
                label="Индекс курильщика"
            )

            .app-choice-group
                .app-choice-group__label Отказ от курения
                .app-choice-group__list
                    app-choice(
                        v-model="smoking.giveUp"
                        type="radio"
                        label="Нет"
                        :radio-value="false"
                        :disabled="!smoking.presence"
                    )
                    app-choice(
                        v-model="smoking.giveUp"
                        type="radio"
                        label="Да"
                        :radio-value="true"
                        :disabled="!smoking.presence"
                    )
            app-datepicker.smoking-block--give-up-date(
                v-model="smoking.giveUpDate"
                label="Дата отказа"
                :disabled="!smoking.presence || !smoking.giveUp"
                :error="getFieldError('giveUpDate')"
            )
</template>

<script>
import moment from 'moment';

export default {
    name: 'smoking-block',

    props: {
        value: {
            validator(){
                return 1;
            },
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        smoking: {
            presence: false,
            experience: '',
            perDay: '',
            giveUp: false,
            giveUpDate: '',
        },
    }),

    validations: {
        smoking: {
            experience: {
                required(value) {
                    return value || !this.smoking.presence;
                },
            },
            perDay: {
                required(value) {
                    return value || !this.smoking.presence;
                },
            },
            giveUpDate: {
                required(value) {
                    return value || !this.smoking.presence || !this.smoking.giveUp;
                },
            },
        },
    },

    watch: {
        smoking: {
            handler: function (value) {
                if (this.smoking.presence) {
                    this.$emit('input', value);
                } else {
                    this.$emit('input', null);
                }
            },
            deep: true,
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.value) {
                this.smoking = this.value;
            }
        });
    },

    methods: {
        calculateSmokingIndex(experience, perDay) {
            const index = perDay * experience / 20;
            if (index) {
                return Math.floor(index);
            }
            return '';
        },

        getFieldError(field) {
            if (this.$v.smoking.$dirty && !this.$v.smoking[field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            const obj = {};
            if (this.smoking.presence) {
                obj.experience = this.smoking.experience;
                obj.cigarettes_a_day = this.smoking.perDay;
                obj.smoker_index = this.calculateSmokingIndex(this.smoking.experience, this.smoking.perDay);

                if (this.smoking.giveUp) {
                    obj.give_up_smoking_date = moment(this.smoking.giveUpDate).format('YYYY-MM-DD');
                }
            }
            return obj;
        },

        getFormattedSavedFields(s) {
            if (s) {
                return {
                    presence: true,
                    experience: s.experience,
                    perDay: s.cigarettes_a_day,
                    giveUp: Boolean(s.give_up_smoking_date),
                    giveUpDate: s.give_up_smoking_date ? +moment(s.give_up_smoking_date) : '',
                    old: true,
                };
            } else {
                return this.smoking;
            }
        },
    },
};
</script>

<style lang="scss">
.smoking-block {
    .smoking-block {
        &--experience {
            width: rem(134px);
        }

        &--perDay {
            width: rem(144px);
        }

        &--index {
            width: rem(180px);
        }

        &--give-up-date {
            width: rem(192px);
        }
    }

    &--addition-only {
        .app-text-field, .app-choice {
            opacity: 0.5;

            pointer-events: none;
        }
    }

    @include mobile_or_P {
        .smoking-block {
            &--experience,
            &--perDay,
            &--index,
            &--give-up-date {
                width: 100%;
            }

        }
    }
}
</style>
