<template lang="pug">
    .questionnaire-cu-q2ol
        app-text-field.result-field(
            v-model="result"
            label="CU-Q2OL"
            is-number
            :max="92"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет CU-Q2OL

            .block(v-for="(block, blockIndex) in blocks")
                .block__name(
                    v-if="block.name"
                    v-html="block.name"
                )
                .block-question(v-for="(question, id) in block.questions")
                    .block-question__name(v-html="id + '. ' + question.name")
                    .app-choice-group
                        app-choice(
                            v-for="variant in block.variants"
                            v-model="question.value"
                            type="radio"
                            :label="variant.name"
                            :radio-value="variant.value"
                            :error="$v.$dirty && $v.blocks.$each.$iter[blockIndex].questions.$each.$iter[id].value.$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") CU-Q2OL = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-cu-q2ol',

    mixins: [questionnaireMixin],

    data: () => ({
        blocks: [
            {
                questions: {
                    1: { name: 'Насколько сильно Вас беспокоит зуд в&nbsp;течение <b>последних 4-х недель?</b>' },
                    2: { name: 'Насколько сильно Вас беспокоят волдыри в&nbsp;течение <b>последних 4-х недель?</b>?' },
                    3: { name: 'Насколько сильно Вас беспокоит припухлость глаз в&nbsp;течение <b>последних 4-х недель?</b>?' },
                    4: { name: 'Насколько сильно Вас беспокоит припухлость губ в&nbsp;течение <b>последних 4-х недель?</b>?' },
                },
                variants: [
                    { name: 'Абсолютно не беспокоит', value: 0 },
                    { name: 'Незначительно (можно не замечать, если не думать)', value: 1 },
                    { name: 'Умеренно (не&nbsp;удается не&nbsp;замечать, но&nbsp;не&nbsp;нарушает дневную активность или сон)', value: 2 },
                    { name: 'Сильно (нарушает дневную активность или сон)', value: 3 },
                    { name: 'Очень сильно (нарушает дневную активность или сон)', value: 4 },
                ],
            },
            {
                name: 'В&nbsp;следующих вопросах говорится о&nbsp;Вашей обычной ежедневной деятельности в&nbsp;течение <b>последних 4-х недель.</b> На&nbsp;каждый вопрос дайте, пожалуйста, один ответ, наиболее точно характеризующий Ваши ощущения',
                questions: {
                    5.1: { name: 'Крапивница мешает моей работе' },
                    5.2: { name: 'Крапивница мешает моей физической активности' },
                    5.3: { name: 'Крапивница нарушает мой сон' },
                    5.4: { name: 'Крапивница мешает полноценно проводить свободное время' },
                    5.5: { name: 'Крапивница мешает моей общественной жизни' },
                    5.6: { name: 'Крапивница мешает моим пищевым пристрастиям' },
                },
                variants: [
                    { name: 'Абсолютно не мешает', value: 0 },
                    { name: 'Немного мешает', value: 1 },
                    { name: 'Умеренно мешает', value: 2 },
                    { name: 'Достаточно сильно мешает', value: 3 },
                    { name: 'Сильно мешает', value: 4 },
                ],
            },
            {
                name: 'Следующие вопросы касаются Вашего сна и&nbsp;эмоционального состояния в&nbsp;течение <b>последних 4-х недель.</b> На&nbsp;каждый вопрос дайте, пожалуйста, один ответ, наиболее точно характеризующий Ваши ощущения.',
                questions: {
                    6.1: { name: 'Вы трудно засыпаете?' },
                    6.2: { name: 'Вы просыпаетесь по ночам?' },
                    6.3: { name: 'Вы чувствуете себя усталым в течение дня, потому что плохо спали ночью?' },
                    6.4: { name: 'Вам трудно сосредоточиться?' },
                    6.5: { name: 'Вы чувствуете себя нервозным?' },
                },
                variants: [
                    { name: 'Никогда', value: 0 },
                    { name: 'Очень редко', value: 1 },
                    { name: 'Иногда', value: 2 },
                    { name: 'Очень часто', value: 3 },
                    { name: 'Всегда', value: 4 },
                ],
            },
            {
                name: 'За&nbsp;<b>последние 4&nbsp;недели</b> в&nbsp;какой степени следующие вопросы соответствуют действительности? На&nbsp;каждый вопрос дайте, пожалуйста, один ответ, наиболее точно характеризующий Ваши ощущения.',
                questions: {
                    7.1: { name: 'Чувствуете ли Вы себя в плохом настроении?' },
                    7.2: { name: 'Вынуждены ли Вы ограничивать себя в выборе еды?' },
                },
                variants: [
                    { name: 'Никогда', value: 0 },
                    { name: 'Очень редко', value: 1 },
                    { name: 'Иногда', value: 2 },
                    { name: 'Очень часто', value: 3 },
                    { name: 'Всегда', value: 4 },
                ],
            },
            {
                questions: {
                    8: { name: 'Ограничивает&nbsp;ли крапивница Вашу физическую активность в&nbsp;течение последних 4-х недель?' },
                    9: { name: 'Вы беспокоитесь о побочном действии лекарств?' },
                },
                variants: [
                    { name: 'Абсолютно нет', value: 0 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Умеренно', value: 2 },
                    { name: 'Сильно', value: 3 },
                    { name: 'Очень сильно', value: 4 },
                ],
            },
            {
                name: 'Следующие вопросы касаются Вашего внешнего вида за&nbsp;<b>последние 4&nbsp;недели.</b> На&nbsp;каждый вопрос дайте, пожалуйста, один ответ, наиболее точно характеризующий Ваши ощущения.',
                questions: {
                    10.1: { name: 'Стесняетесь ли вы из-за симптомов крапивницы?' },
                    10.2: { name: 'Стесняетесь ли вы при посещении общественных мест?' },
                    10.3: { name: 'У вас есть проблемы с использованием косметики?' },
                    10.4: { name: 'Испытываете ли Вы ограничения в выборе материала одежды?' },
                },
                variants: [
                    { name: 'Абсолютно нет', value: 0 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Умеренно', value: 2 },
                    { name: 'Сильно', value: 3 },
                    { name: 'Очень сильно', value: 4 },
                ],
            },
        ],
    }),

    validations: {
        blocks: {
            $each: {
                questions: {
                    $each: {
                        value: { required },
                    },
                },
            },
        },
    },

    created() {
        this.blocks.forEach(block => {
            for (let id in block.questions) {
                this.$set(block.questions[id], 'value', null);
            }
        });
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = this.blocks.reduce((sum, block) => {
                return sum + Object.values(block.questions).reduce((sumB, question) => sumB + question.value, 0);
            }, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-cu-q2ol {
    .result-field {
        width: rem(100px);
    }

    .block__name {
        font-weight: 700;
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
