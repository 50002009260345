export default (user) => {
    let abbr = user?.last_name;
    if (user?.first_name) {
        abbr += ` ${user.first_name[0]}.`;
    }
    if (user?.middle_name) {
        abbr += ` ${user.middle_name[0]}.`;
    }
    return abbr;
};
