<template lang="pug">
    .laboratory-indicators-block(:class="{'laboratory-indicators-block--re-contact': isReContact, 'laboratory-indicators-block--addition-only': additionOnly}")
        .laboratory-indicators-block__row(
            v-for="(item, index) in laboratoryIndicators"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.laboratory-indicators-block--date(
                    v-model="item.date"
                    label="Дата забора"
                    :error="getFieldError(item, index, 'date')"
                )
                app-select.laboratory-indicators-block--select(
                    v-model="item.indicator"
                    :options="indicatorList"
                    searchable
                    label="Показатель"
                    :error="getFieldError(item, index, 'indicator')"
                )
                app-text-field.laboratory-indicators-block--result(
                    v-model="item.result"
                    is-float-number
                    label="Результат"
                    :error="getFieldError(item, index, 'result')"
                )
                .app-choice-group.laboratory-indicators-block--test-result
                    .app-choice-group__label Результат
                    .app-choice-group__list
                        app-choice(
                            v-model="item.testResult"
                            type="radio"
                            label="Нет"
                            :radio-value="0"
                            :disabled="additionOnly"
                        )
                        app-choice(
                            v-model="item.testResult"
                            type="radio"
                            label="Да"
                            :radio-value="1"
                            :disabled="additionOnly"
                        )
                app-text-field.laboratory-indicators-block--note(
                    v-if="item.testResult"
                    v-model="item.note"
                    label="Заметки"
                )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropLaboratoryIndicator(index)"
                )
            hr.narrow(v-if="index < laboratoryIndicators.length - 1")
        .br
        app-button(
            plus
            @click="addNewLaboratoryIndicator"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'laboratory-indicators-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        laboratoryIndicators: [],
    }),

    validations: {
        laboratoryIndicators: {
            $each: {
                date: { required },
                indicator: { required },
                result: { required },
            },
        },
    },

    computed: {
        indicatorList(){
            return this.$store.getters.laboratoryIndicators;
        },
    },

    watch: {
        laboratoryIndicators(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.laboratoryIndicators = this.value;
        });
    },

    methods: {
        addNewLaboratoryIndicator() {
            this.laboratoryIndicators.push({
                id: uniqueId(),
                date: '',
                indicator: '',
                result: '',
                testResult: 0,
                note: '',
            });
            this.$v.$reset();
        },

        dropLaboratoryIndicator(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить показатель?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.laboratoryIndicators = this.laboratoryIndicators.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.laboratoryIndicators.$each.$iter[index].$dirty && !this.$v.laboratoryIndicators.$each.$iter[index][field].required) {
                return 'Заполните поле';
            }
            return null;
        },

        getFieldsForSave() {
            return this.laboratoryIndicators.map(d => {
                const obj = {
                    date_at: moment(d.date).format('YYYY-MM-DD'),
                    laboratory_indicator_id: d.indicator.id,
                    result: d.result,
                    test_result: d.testResult,
                    note: d.testResult ? d.note : '',
                };
                return obj;
            });
        },

        getFormattedSavedFields(laboratoryIndicators) {
            let fields = laboratoryIndicators.map(d => ({
                id: d.id,
                date: +moment(d.date_at),
                indicator: d.laboratory_indicator,
                result: d.result,
                testResult: d.test_result,
                note: d.note || '',
                old: true,
            }));
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.laboratory-indicators-block {
    .laboratory-indicators-block {
        &--date {
            width: rem(168px);
        }

        &--select {
            width: rem(438px);
        }

        &--result {
            width: rem(105px);
        }

        &--note {
            width: rem(294px);
        }
    }

    &--re-contact {
        .laboratory-indicators-block__row.old {
            display: none;
        }
    }

    &--addition-only {
        .laboratory-indicators-block__row.old {
            .app-text-field, .app-select, .app-datepicker, .app-choice {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .laboratory-indicators-block {
            &--date,
            &--select,
            &--result,
            &--test-result,
            &--note {
                width: 100%;
            }
        }
    }
}
</style>
