<template lang="pug">
    .layout-header
        .layout-header__left.forDesktop
            router-link(to="/")
                logo
            h1 Регистр аллергологии и иммунологии
        .burger.forMobile_or_p(
            @click="toggleMenu"
            :class="{'burger--open': openMenu}"
        )
            span
            span
            span
            span
        .layout-header-user
            .layout-header-user__avatar
                img(:src="require('@/assets/images/profile_default.png')" alt="")
            .layout-header-user__info
                b {{ userName }}
                span {{ userRole }}
            .layout-header-user__logout(
                title="Выйти"
                @click="logout"
            )
                logout-icon
</template>

<script>
import logo from '@/assets/images/logo.svg';
import logoutIcon from '@/assets/images/icons/logout.svg';
import { ROLES } from '@/js/const';
import { bus } from '@/main';
import getFIOAbbreviation from '@/js/utils/getFIOAbbreviation';

export default {
    name: 'layout-header',

    components: {
        logo,
        logoutIcon,
    },

    data: () => ({
        openMenu: false,
    }),

    computed: {
        user() {
            return this.$store.getters.user;
        },

        userName() {
            return getFIOAbbreviation(this.user);
        },

        userRole() {
            return ROLES[this.user?.role];
        },
    },

    watch: {
        $route() {
            if (this.openMenu) {
                this.toggleMenu();
            }
        },
    },

    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.replace('/auth');
        },

        toggleMenu() {
            this.openMenu = !this.openMenu;
            bus.$emit('toggleMenu');
            if (this.openMenu) {
                window.disableScroll();
            } else {
                window.enableScroll();
            }
        },
    },
};
</script>

<style lang="scss">
.layout-header {
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    height: rem($header-height);
    padding: 0 rem(37px) 0 rem(32px);

    background: $white;
    box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);
    &__left {
        display: flex;
        align-items: center;

        svg {
            width: rem(50px);
            margin-right: rem(16px);
        }

        h1 {
            font-size: rem(28px);
            font-weight: bold;
        }
    }

    &-user {
        display: flex;
        align-items: center;

        padding: rem(8px);

        background-color: $light-blue;
        border-radius: rem(8px);

        &__avatar {
            width: rem(38px);
            height: rem(38px);
            margin-right: rem(8px);

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                border-radius: 50%;
            }
        }

        &__info {
            min-width: rem(150px);
            margin-right: rem(14px);

            b, span {
                display: block;
            }

            b {
                margin-bottom: rem(4px);
            }

            span {
                font-size: rem(12px);
                line-height: 1.2;
            }
        }

        &__logout {
            width: rem(24px);
            height: rem(24px);
            margin-left: auto;

            cursor: pointer;
            transition: fill .2s;

            svg {
                width: 100%;
                height: 100%;
            }

            &:hover {
                fill: $dark;
            }
        }
    }

    @include mobile_or_P {
        height: rem(81px);
        padding: 0 1rem;

        .burger {
            position: relative;

            width: rem(24px);
            height: rem(24px);

            span {
                position: absolute;

                width: rem(9px);
                height: rem(9px);

                border: rem(2px) solid $dark;
                border-radius: rem(3px);

                &:nth-of-type(1) {
                    top: rem(2px);
                    left: rem(2px);

                    transition: .3s;
                }

                &:nth-of-type(2) {
                    top: rem(2px);
                    right: rem(2px);

                    transition: .3s .1s;
                }

                &:nth-of-type(3) {
                    right: rem(2px);
                    bottom: rem(2px);

                    transition: .3s .2s;
                }

                &:nth-of-type(4) {
                    bottom: rem(2px);
                    left: rem(2px);

                    transition: .3s .3s;
                }
            }

            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;

                display: block;

                width: rem(10px);
                height: rem(2px);

                background-color: $red;
                border-radius: rem(2px);

                opacity: 0;
                transition: .2s;
            }

            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &--open {
                span {
                    width: rem(20px);
                    height: rem(20px);

                    border-color: $red;
                    border-radius: rem(6px);
                }
                &:before, &:after {
                    opacity: 1;
                    transition: .3s .5s;
                }
            }
        }

        &-user {
            &__avatar {
                display: none;
            }

            &__info {
                min-width: 7.7rem;

                font-size: rem(14px);

                span {
                    font-size: rem(10px);
                }
            }
        }
    }
}
</style>
