<template lang="pug">
    .unwanted-effects-block(:class="{'unwanted-effects-block--re-contact': isReContact, 'unwanted-effects-block--addition-only': additionOnly}")
        .unwanted-effects-block__rows
            .unwanted-effects-block__row(
                v-for="(item, index) in unwantedEffects"
                :key="item.id"
                :class="{old: item.old}"
            )
                .row
                    .app-choice-group
                        .app-choice-group__label Связаны с препаратом
                        .app-choice-group__list
                            app-choice(
                                v-model="item.associatedWithDrugs"
                                type="radio"
                                label="Нет"
                                radio-value="0"
                                :error="getFieldError(item, index, 'associatedWithDrugs')"
                            )
                            app-choice(
                                v-model="item.associatedWithDrugs"
                                type="radio"
                                label="Да"
                                radio-value="1"
                                :error="getFieldError(item, index, 'associatedWithDrugs')"
                            )
                    app-text-field.unwanted-effects-block--effects(
                        v-model="item.effects"
                        label="Нежелательные эффекты"
                        :error="getFieldError(item, index, 'effects')"
                    )
                    .app-choice-group
                        .app-choice-group__label Реакция
                        .app-choice-group__list
                            app-choice(
                                v-model="item.reaction"
                                type="radio"
                                label="Локальная"
                                radio-value="Локальная"
                                :error="getFieldError(item, index, 'reaction')"
                            )
                            app-choice(
                                v-model="item.reaction"
                                type="radio"
                                label="Системная"
                                radio-value="Системная"
                                :error="getFieldError(item, index, 'reaction')"
                            )
                            app-choice(
                                v-model="item.reaction"
                                type="radio"
                                label="Иная"
                                radio-value="Иная"
                                :error="getFieldError(item, index, 'reaction')"
                            )
                    app-datepicker.unwanted-effects-block--unwantedEffectsDate(
                        v-model="item.date"
                        label="Дата"
                        :error="getFieldError(item, index, 'date')"
                    )
                    app-dropper(
                        :class="{hidden: (isReContact || additionOnly) && item.old}"
                        @click="dropUnwantedEffects(index)"
                    )
                hr
        .br
        app-button(
            plus
            @click="addNewUnwantedEffects"
        ) Добавить
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { uniqueId } from 'lodash';
import moment from 'moment';

export default {
    name: 'unwanted-effects-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        unwantedEffects: [],
    }),

    validations: {
        unwantedEffects: {
            $each: {
                associatedWithDrugs: { required },
                effects: { required },
                reaction: { required },
                date: { required },
            },
        },
    },

    watch: {
        unwantedEffects(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.unwantedEffects = this.value;
        });
    },

    methods: {
        addNewUnwantedEffects() {
            this.unwantedEffects.push({
                id: uniqueId(),
                associatedWithDrugs: '',
                effects: '',
                reaction: '',
                date: '',
            });
            this.$v.$reset();
        },

        dropUnwantedEffects(index) {
          this.$confirm({
            message: `Вы действительно хотите удалить нежелательный эффект?`,
            button: {
              no: 'Нет',
              yes: 'Да',
            },
            callback: confirm => {
              if (confirm) {
                this.unwantedEffects = this.unwantedEffects.filter((d, i) => i !== index);
              }
            },
          });
        },

        getFieldError(item, index, field) {
            if (this.$v.unwantedEffects.$each.$iter[index].$dirty && !this.$v.unwantedEffects.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.unwantedEffects.map(d => ({
                associated_with_drugs: d.associatedWithDrugs,
                data: d.effects,
                reaction: d.reaction,
                date_at: moment(d.date).format('YYYY-MM-DD'),
            }));
        },

        getFormattedSavedFields(unwantedEffects) {
            return unwantedEffects.map(d => ({
                id: d.id,
                associatedWithDrugs: d.associated_with_drugs,
                effects: d.data,
                reaction: d.reaction,
                date: +moment(d.date_at),
                old: true,
            }));
        },
    },
};
</script>

<style lang="scss">
.unwanted-effects-block {

    &__row:last-of-type hr {
      display: none;
    }

    .unwanted-effects-block {
        &--effects {
            width: rem(294px);
        }

        &--unwantedEffectsDate {
            width: rem(168px);
        }
    }

    &--re-contact {
        .unwanted-effects-block__row.old {
            display: none;
        }
    }

    &--addition-only {
        .unwanted-effects-block__row.old {
            .app-text-field, .app-datepicker, .app-choice {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .unwanted-effects-block {
            &--effects, &--unwantedEffectsDate{
                width: 100%;
            }
        }
    }
}
</style>
