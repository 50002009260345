<template lang="pug">
    .app-toggler(:class="classes")
        .app-toggler__indicator(@click="toggle")
        .app-toggler__label(
            v-html="label"
            @click="toggle")
</template>

<script>
export default {
    name: 'app-toggler',

    props: {
        value: {
            type: Boolean,
            required: true,
        },

        label: {
            type: String,
            default: '',
        },

        disabled: {
            validator: () => true,
            required: false,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'app-toggler--active': this.value,
                'app-toggler--disabled': this.disabled,
            };
        },
    },

    methods: {
        toggle() {
            this.$emit('input', !this.value);
        },
    },
};
</script>
