<template lang="pug">
    .consultations-block
        .consultations-block__row(
            v-for="(item, index) of consultations"
            v-if="!isReContact || !item.old"
            :key="item.id"
        )
            .row
                app-text-field.consultations-block__data(
                    v-model="item.data"
                    label="Заключение ЛОР-врача"
                    is-textarea
                    :error="getFieldError(item, index, 'data')"
                    :disabled="additionOnly && item.old"
                )
                .row.column
                    app-datepicker.consultations-block__date-at(
                        v-model="item.dateAt"
                        label="Дата консультации"
                        :error="getFieldError(item, index, 'dateAt')"
                        :disabled="additionOnly && item.old"
                    )
                    app-text-field.consultations-block__doctor-last-name(
                        v-model="item.doctorLastName"
                        label="Фамилия врача"
                        :error="getFieldError(item, index, 'doctorLastName')"
                        :disabled="additionOnly && item.old"
                    )
                app-dropper(
                    :class="{hidden: additionOnly && item.old}"
                    @click="dropConsultation(index)"
                )
            hr(v-if="index < consultations.length - 1")
        .br
        app-button(
            plus
            @click="addNewConsultation"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'consultations-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        consultations: [],
    }),

    validations: {
        consultations: {
            $each: {
                data: { required },
                dateAt: { required },
                doctorLastName: { required },
            },
        },
    },

    watch: {
        consultations(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.consultations = this.value;
        });
    },

    methods: {
        addNewConsultation() {
            this.consultations.push({
                id: uniqueId(),
                data: '',
                dateAt: '',
                doctorLastName: '',
            });
            this.$v.$reset();
        },

        dropConsultation(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить консультацию?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.consultations = this.consultations.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.consultations.$each.$iter[index].$dirty && !this.$v.consultations.$each.$iter[index][field].required) {
                return 'Заполните поле';
            }
            return null;
        },

        getFieldsForSave() {
            return this.consultations.map(d => {
                return {
                    data : d.data ,
                    date_at: moment(d.dateAt).format('YYYY-MM-DD'),
                    doctor_last_name : d.doctorLastName,
                };
            });
        },

        getFormattedSavedFields(consultations) {
            return consultations.map(d => ({
                id: d.id,
                data: d.data,
                dateAt: +moment(d.date_at),
                doctorLastName: d.doctor_last_name,
                old: true,
            }));
        },
    },
};
</script>

<style lang="scss">
.consultations-block {
    .consultations-block {
        &__data {
            width: rem(700px);

            textarea {
                min-height: rem(120px);
            }
        }

        &__date-at,
        &__doctor-last-name {
            width: rem(215px);
        }
    }

    @include mobile_or_P {
        .row {
            width: 100%;
        }

        .consultations-block {
            &__date-at,
            &__doctor-last-name {
                width: 100%;
            }
        }
    }
}
</style>
