<template lang="pug">
    .specific-examinations-block(:class="{'specific-examinations-block--re-contact': isReContact, 'specific-examinations-block--addition-only': additionOnly}")
        .specific-examinations-block__row(
            v-for="(item, index) in specificExaminations"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.specific-examinations-block--date(
                    v-model="item.date"
                    label="Дата забора"
                    :error="getFieldError(item, index, 'date')"
                )
                app-select.specific-examinations-block--select(
                    v-model="item.examination"
                    :options="specificExaminationsList"
                    searchable
                    label="Обследование"
                    :error="getFieldError(item, index, 'examination')"
                )
                app-text-field.specific-examinations-block--allergen(
                    v-model="item.allergen"
                    :options="allergensList"
                    is-select
                    allow-custom-value
                    label="Аллергокомпонент / аллерген"
                    :error="getFieldError(item, index, 'allergen')"
                )
                app-text-field.specific-examinations-block--result(
                    v-model="item.result"
                    is-specific-number
                    label="Результат"
                    :error="getFieldError(item, index, 'result')"
                )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropSpecificExamination(index)"
                )
            hr.narrow(v-if="index < specificExaminations.length - 1")
        .br
        app-button(
            plus
            @click="addNewSpecificExamination"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'specific-examinations-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        specificExaminations: [],
    }),

    validations: {
        specificExaminations: {
            $each: {
                date: { required },
                examination: { required },
                allergen: { required },
                result: { required },
            },
        },
    },

    computed: {
        specificExaminationsList() {
            return this.$store.getters.specificExaminations;
        },

        allergensList() {
            return this.$store.getters.allergens.map(a => ({
                ...a,
                name: a.component,
            }));
        },
    },

    watch: {
        specificExaminations(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.specificExaminations = this.value;
        });
    },

    methods: {
        addNewSpecificExamination() {
            this.specificExaminations.push({
                id: uniqueId(),
                date: '',
                examination: '',
                allergen: '',
                result: '',
            });
            this.$v.$reset();
        },

        dropSpecificExamination(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить обследование?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.specificExaminations = this.specificExaminations.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.specificExaminations.$each.$iter[index].$dirty && !this.$v.specificExaminations.$each.$iter[index][field].required) {
                return 'Заполните поле';
            }
            return null;
        },

        getFieldsForSave() {
            return this.specificExaminations.map(d => {
                const obj = {
                    date_at: moment(d.date).format('YYYY-MM-DD'),
                    specific_examination_id: d.examination.id,
                    result: d.result,
                };
                const allergenFromList = this.allergensList.find(a => a.name === d.allergen);
                if (allergenFromList) {
                    obj.allergen_id = allergenFromList.id;
                } else {
                    obj.allergen_custom = d.allergen;
                }
                return obj;
            });
        },

        getFormattedSavedFields(specificExaminations) {
            let fields = specificExaminations.map(d => ({
                id: d.id,
                date: +moment(d.date_at),
                examination: d.specific_examination,
                allergen: d.allergen?.component || d.allergen_custom,
                result: d.result,
                old: true,
            }));
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.specific-examinations-block {
    .specific-examinations-block {
        &--date {
            width: rem(168px);
        }

        &--select {
            width: rem(438px);
        }

        &--allergen {
            width: rem(400px);
        }

        &--result {
            width: rem(105px);
        }
    }

    &--re-contact {
        .specific-examinations-block__row.old {
            display: none;
        }
    }

    &--addition-only {
        .specific-examinations-block__row.old {
            .app-text-field, .app-select, .app-datepicker {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .specific-examinations-block {
            &--date,
            &--select,
            &--allergen,
            &--result {
                width: 100%;
            }
        }
    }
}
</style>
