<template lang="pug">
    .app-choice(:class="classes")
        input(
            :id="uid"
            v-model="state"
            :type="type"
            :value="radioValue"
            @click="clickHandler"
        )

        label(:for="uid")
            .app-choice__circle
                checked-icon(v-if="type === 'checkbox'")
            span(v-html="label")
</template>

<script>
import checkedIcon from '@/assets/images/icons/checked.svg';
import { uniqueId } from 'lodash';

export default {
    name: 'app-choice',

    components: {
        checkedIcon,
    },

    props: {
        value: {
            type: null,
            required: true,
        },

        label: {
            type: [String, Number],
            required: true,
        },

        type: {
            type: String,
            required: true,
        },

        radioValue: {
            type: [Number, String, Object, Boolean],
            required: false,
            default: '',
        },

        disabled: {
            validator: () => true,
            required: false,
            default: false,
        },

        // можно отменить выбранное значение у радиокнопок
        unchecketable: {
            type: Boolean,
            default: false,
        },

        error: {
            type: [String, Boolean],
            required: false,
            default: false,
        },
    },

    data: () => ({
        uid: null,
    }),

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        classes() {
            return {
                'app-choice--invalid': this.error,
                'app-choice--disabled': this.disabled,
                'app-choice--checkbox': this.type === 'checkbox',
                'app-choice--radio': this.type === 'radio',
            };
        },
    },

    mounted() {
        this.uid = 'checkbox-' + uniqueId();
    },

    methods: {
        clickHandler() {
            if (this.unchecketable) {
                if (this.state) {
                    this.state = null;
                }
            }
        },
    },
};
</script>
