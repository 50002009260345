<template lang="pug">
    .questionnaire-act
        app-text-field.result-field(
            v-model="result"
            label="ACT"
            is-number
            :max="25"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет ACT

            .block
                .block-question(v-for="(question, id) in questions")
                    .block-question__name {{ question.name }}
                    .app-choice-group
                        app-choice(
                            v-for="answer in question.answers"
                            v-model="question.value"
                            type="radio"
                            :label="answer.name"
                            :radio-value="answer.value"
                            :error="$v.$dirty && $v.questions.$each.$iter[id].value.$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") ACT = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-act',

    mixins: [questionnaireMixin],

    data: () => ({
        questions: {
            1: {
                name: 'Как часто за последние 4 недели астма мешала Вам выполнять обычный объем работы в учебном заведении, на работе или дома?',
                answers: [
                    { name: 'все время', value: 1 },
                    { name: 'очень часто', value: 2 },
                    { name: 'иногда', value: 3 },
                    { name: 'редко', value: 4 },
                    { name: 'никогда', value: 5 },
                ],
                value: null,
            },
            2: {
                name: 'Как часто за последние 4 недели Вы отмечали у себя затрудненное дыхание?',
                answers: [
                    { name: 'чаще, чем раз в день', value: 1 },
                    { name: 'раз в день', value: 2 },
                    { name: 'от 3 до 6 раз в неделю', value: 3 },
                    { name: 'один или два раза в неделю', value: 4 },
                    { name: 'ни разу', value: 5 },
                ],
                value: null,
            },
            3: {
                name: 'Как часто за последние 4 недели Вы просыпались ночью или раньше, чем обычно, из-за симптомов астмы (свистящего дыхания, кашля, затрудненного дыхания, чувства стеснения или боли в груди)?',
                answers: [
                    { name: '4 ночи в неделю или чаще', value: 1 },
                    { name: '2-3 ночи в неделю', value: 2 },
                    { name: 'раз в неделю', value: 3 },
                    { name: 'один или два раза', value: 4 },
                    { name: 'ни разу', value: 5 },
                ],
                value: null,
            },
            4: {
                name: 'Как часто за последние 4 недели Вы использовали препарат для купирования бронхоспазма через ингалятор или небулайзер с лекарством?',
                answers: [
                    { name: '3 раза в день или чаще', value: 1 },
                    { name: '1 или 2 раза в день', value: 2 },
                    { name: '2 или 3 раза в неделю', value: 3 },
                    { name: 'один раз в неделю или реже', value: 4 },
                    { name: 'ни разу', value: 5 },
                ],
                value: null,
            },
            5: {
                name: 'Как бы Вы оценили, насколько Вам удавалось контролировать астму за последние 4 недели?',
                answers: [
                    { name: 'совсем не удавалось контролировать', value: 1 },
                    { name: 'плохо удавалось контролировать', value: 2 },
                    { name: 'в некоторой степени удавалось контролировать', value: 3 },
                    { name: 'хорошо удавалось контролировать', value: 4 },
                    { name: 'полностью удавалось контролировать', value: 5 },
                ],
                value: null,
            },
        },
    }),

    validations: {
        questions: {
            $each: {
                value: { required },
            },
        },
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = Object.values(this.questions).reduce((sum, question) => sum + question.value, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-act {
    .result-field {
        width: rem(85px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
