(function() {
    let pageOffset = 0;

    window.disableScroll = () => {
        let offset = window.pageYOffset;
        //document.documentElement.style.overflow = 'hidden';
        pageOffset = offset;
        document.body.style.cssText = 'position: fixed;top: -' + offset + 'px';
    };

    window.enableScroll = () => {
        //document.documentElement.style.overflow = 'initial';
        document.body.style.cssText = '';
        window.scrollTo(0, pageOffset);
    };
})();
