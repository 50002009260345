import Vue from 'vue';
import store from '../store';
import axios from 'axios';
import VueAxios from 'vue-axios';

axios.defaults.baseURL = process.env.VUE_APP_API_PATH;

axios.interceptors.request.use(config => {
    if (store.state.auth.token) {
        config.headers.Authorization = `Bearer ${store.state.auth.token}`;
    }
    return config;
});

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        return Promise.reject(error);
    },
);

Vue.use(VueAxios, axios);
