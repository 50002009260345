<template lang="pug">
    .questionnaire-bsa
        app-text-field.result-field(
            v-model="result"
            label="BSA"
            is-float-number
            :max="100"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет BSA
            .block
                .block-row
                    .block-row__item.block-field(v-for="(item, index) in fields")
                        .block-field__label {{ item.name }}
                        app-text-field(
                            v-model="item.value"
                            is-float-number
                            center
                            :max="item.max"
                            :error="$v.$dirty && !$v.fields.$each.$iter[index].value.required ? 'Заполните поле' : ''"
                        )

            .bottom
                .result(v-if="tempResult !== null") BSA = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-bsa',

    mixins: [questionnaireMixin],

    data: () => ({
        fields: [
            { name: 'Передняя поверхность головы', max: 4.5, value: 0 },
            { name: 'Задняя поверхность головы', max: 4.5, value: 0 },
            { name: 'Гениталии', max: 1, value: 0 },
            { name: 'Задняя поверхность туловища', max: 18, value: 0 },
            { name: 'Передняя поверхность туловища', max: 18, value: 0 },
            { name: 'Задняя поверхность левой руки', max: 4.5, value: 0 },
            { name: 'Передняя поверхность левой руки', max: 4.5, value: 0 },
            { name: 'Задняя поверхность правой руки', max: 4.5, value: 0 },
            { name: 'Передняя поверхность правой руки', max: 4.5, value: 0 },
            { name: 'Задняя поверхность левой ноги', max: 9, value: 0 },
            { name: 'Передняя поверхность левой ноги', max: 9, value: 0 },
            { name: 'Задняя поверхность правой ноги', max: 9, value: 0 },
            { name: 'Передняя поверхность правой ноги', max: 9, value: 0 },
        ],
    }),

    validations: {
        fields: {
            $each: {
                value: { required },
            },
        },
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = this.fields.reduce((sum, item) => sum + Number(item.value), 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-bsa {
    .result-field {
        width: rem(65px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: rem(24px);
        }
    }
}
</style>
