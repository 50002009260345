<template lang="pug">
    .provocative-tests-dictionary
        .edit-dictionary__top
            .edit-dictionary__name Провокационные тесты
        dictionary-default-template(
            action-name="fetchProvocativeTests"
            getter-name="provocativeTests"
            url-part="provocative-test"
        )
</template>

<script>
import DictionaryDefaultTemplate from '@/components/pages/dictionaries/DictionaryDefaultTemplate';

export default {
    name: 'provocative-tests-dictionary',

    components: {
        DictionaryDefaultTemplate,
    },
};
</script>
