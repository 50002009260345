<template lang="pug">
    .page.users(:class="{'users--more-filters-opened': moreFiltersOpened}")
        .users-filters.forDesktop
            .row
                app-text-field.users-filter--search(
                    v-model="search"
                    label="Поиск по ФИО / ID"
                )
                app-select.users-filter--MO(
                    v-model="MO"
                    :options="medicalOrganizations"
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    label="Медицинская организация"
                )
                app-select.users-filter--status(
                    v-model="status"
                    :options="statusList"
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    label="Статус"
                )
                app-more-btn(
                    :class="{'app-more-btn--active': moreFiltersOpened}"
                    @click="moreFiltersOpened=!moreFiltersOpened"
                ) Еще фильтры
                app-button(
                    title="Добавить пользователя"
                    tag="router-link"
                    to="/users/new"
                    large
                )
                    add-user-icon
            .row(v-if="moreFiltersOpened")
                app-datepicker.users-filter--registerDate(
                    v-model="registerDate"
                    range
                    label="Дата регистрации"
                )
                app-datepicker.users-filter--lastUsageDate(
                    v-model="lastUsageDate"
                    range
                    label="Дата последнего использования сервиса"
                )
                .app-choice-group
                    .app-choice-group__label Группа пользователей
                    .app-userhoice-group__listusers
                        app-choice(
                            v-model="showDoctors"
                            type="checkbox"
                            label="Врач"
                        )
                        app-choice(
                            v-model="showDoctorsEditors"
                            type="checkbox"
                            label="Врач-редактор"
                        )
                        app-choice(
                            v-model="showAdministrators"
                            type="checkbox"
                            label="Администратор"
                        )
                app-button.users-filter--reset(
                    large
                    color="red"
                    @click="reset"
                ) Сбросить фильтр

        .users-filters.forMobile_or_p(:class="{'open-half': allFiltersOpenedOnMobile, 'open-full': allFiltersOpenedOnMobile && moreFiltersOpened}")
            .users-filters__mobile-head
                .row
                    app-more-btn(
                        :class="{'app-more-btn--active': allFiltersOpenedOnMobile}"
                        @click="allFiltersOpenedOnMobile=!allFiltersOpenedOnMobile"
                    ) Фильтр
                    app-button(
                        tag="router-link"
                        to="/users/new"
                        large
                    )
                        add-user-icon

            .row
                .br.row
                app-text-field.users-filter--search(
                    v-model="search"
                    label="Поиск по ФИО / ID"
                )
                app-select.users-filter--MO(
                    v-model="MO"
                    :options="medicalOrganizations"
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    label="Медицинская организация"
                )
                app-select.users-filter--status(
                    v-model="status"
                    :options="statusList"
                    multiple
                    track-by="id"
                    :allowEmpty="true"
                    label="Статус"
                )

            .row
                app-datepicker.users-filter--registerDate(
                    v-model="registerDate"
                    range
                    label="Дата регистрации"
                )
                app-datepicker.users-filter--lastUsageDate(
                    v-model="lastUsageDate"
                    range
                    label="Дата последнего использования"
                )
                .app-choice-group
                    .app-choice-group__label Группа пользователей
                    .app-userhoice-group__listusers
                        app-choice(
                            v-model="showDoctors"
                            type="checkbox"
                            label="Врач"
                        )
                        app-choice(
                            v-model="showDoctorsEditors"
                            type="checkbox"
                            label="Врач-редактор"
                        )
                        app-choice(
                            v-model="showAdministrators"
                            type="checkbox"
                            label="Администратор"
                        )

            .row.users-filters__mobile-links
                app-link.app-link--dark-blue(
                    v-if="!moreFiltersOpened"
                    @click="moreFiltersOpened=true"
                ) Еще фильтры
                app-link.app-link--dark-blue(
                    v-else
                    @click="moreFiltersOpened=false"
                ) Свернуть фильтр
                app-link.app-link--red(@click="reset") Сбросить фильтр

        .users-table.app-table
            app-loader(v-if="loading")
            .app-message-text(v-else-if="isEmpty(users)") Пользователи не найдены
            template(v-else)
                .app-table__fixed
                    .app-table__head
                        .app-table__col
                            a(
                                @click="changeSortBy('id')"
                                :class="getSortByClasses('id')"
                            ) ID
                                arrow-triangle
                        .app-table__col
                            a(
                                @click="changeSortBy('last_name')"
                                :class="getSortByClasses('last_name')"
                            ) Пользователь
                                arrow-triangle
                    .app-table__body
                        .app-table__row(v-for="user in users")
                            .app-table__col.forDesktop
                                b.fw600 {{ user.id }}
                            .app-table__col
                                b.fw800
                                    span.forMobile_or_p {{ user.id }} <br>
                                    | {{ getFIOAbbreviation(user) }}
                .app-table__scrolling
                    .app-table__head
                        .app-table__col Группа пользователей
                        .app-table__col МО
                        .app-table__col
                            a(
                                @click="changeSortBy('status')"
                                :class="getSortByClasses('status')"
                            ) Статус
                                arrow-triangle
                        .app-table__col
                            a(
                                @click="changeSortBy('created_at')"
                                :class="getSortByClasses('created_at')"
                            ) Дата регистрации
                                arrow-triangle
                        .app-table__col
                            a(
                                @click="changeSortBy('last_activity_at')"
                                :class="getSortByClasses('last_activity_at')"
                            )
                                nobr Последняя активность
                                arrow-triangle
                        .app-table__col
                    .app-table__body
                        .app-table__row(v-for="user in users")
                            .app-table__col {{ getUserRole(user) }}
                            .app-table__col {{ getUserMO(user) }}
                            .app-table__col.app-table__col--center(:class="user.status") {{ statuses[user.status] }}
                            .app-table__col {{ formattedDate(user.created_at) }}
                            .app-table__col {{ user.last_activity_at ? formattedDate(user.last_activity_at) : '' }}
                            .app-table__col
                                router-link.app-table__button(
                                    title="Редактирование"
                                    :to="{name: 'EditUser', params: { id: user.id }}"
                                )
                                    edit-icon.app-table__button
                                a.app-table__button.users-table__button--trash(
                                    title="Удалить"
                                    @click="trashUser(user.id)"
                                )
                                    trash-icon.app-table__button
                .users-table__total Всего выбрано: <b>{{ total }}</b>


        .users-bottom.grey-bg
            app-pagination(
                v-model="page"
                :count="pagesCount"
            )
            .users-bottom__sort
                app-link.app-link--dark.app-link--normal(no-pointer-events) Показывать по
                app-select(
                    v-model="perPage"
                    :options="perPageList"
                )
</template>

<script>
import AppPagination from '@/components/app/app-pagination/index';
import addUserIcon from '@/assets/images/icons/add_user.svg';
import AppMoreBtn from '@/components/app/app-more-btn/index';
import ArrowTriangle from '@/assets/images/icons/arrow_triangle.svg';
import editIcon from '@/assets/images/icons/edit.svg';
import trashIcon from '@/assets/images/icons/trash.svg';
import { ROLES, STATUSES } from '@/js/const';
import moment from 'moment';
import getFIOAbbreviation from '@/js/utils/getFIOAbbreviation';
import { isEmpty, debounce } from 'lodash';

export default {
    name: 'users',

    components: {
        AppPagination,
        addUserIcon,
        AppMoreBtn,
        ArrowTriangle,
        editIcon,
        trashIcon,
    },

    data: () => ({
        users: [],
        total: 0,

        moreFiltersOpened: false,
        allFiltersOpenedOnMobile: false,

        search: '',
        MO: [],
        status: [],
        registerDate: null,
        lastUsageDate: null,
        showDoctors: true,
        showDoctorsEditors: true,
        showAdministrators: true,

        statuses: STATUSES,

        sortBy: 'status',
        order: 'ascend', // ascend, descend
        page: 1,
        pagesCount: 0,
        perPage: { name: 50 },
        perPageList: [
            { name: 10 },
            { name: 50 },
            { name: 100 },
        ],

        loading: false,
    }),

    computed: {
        medicalOrganizations() {
            return this.$store.getters.medicalOrganizations;
        },

        statusList() {
            return Object.keys(STATUSES).map(s => ({
                name: STATUSES[s],
                id: s,
            }));
        },

        debouncedFetchUsers() {
            return debounce(() => {
                this.fetchUsers();
            }, 500);
        },
    },

    watch: {
        page() {
            this.fetchUsers();
        },
        perPage() {
            this.page = 1;
            this.fetchUsers();
        },
        search() {
            this.page = 1;
            if (this.search) {
                this.debouncedFetchUsers();
            } else {
                this.fetchUsers();
            }
        },
        MO() {
            this.page = 1;
            this.fetchUsers();
        },
        status() {
            this.page = 1;
            this.fetchUsers();
        },
        registerDate() {
            this.page = 1;
            this.fetchUsers();
        },
        lastUsageDate() {
            this.page = 1;
            this.fetchUsers();
        },
        showDoctors() {
            this.page = 1;
            this.fetchUsers();
        },
        showDoctorsEditors() {
            this.page = 1;
            this.fetchUsers();
        },
        showAdministrators() {
            this.page = 1;
            this.fetchUsers();
        },
    },

    created() {
        this.$store.dispatch('fetchMedicalOrganizations');
        this.fetchUsers();
    },

    methods: {
        async fetchUsers() {
            if (this.loading) return;
            this.loading = true;

            let query = `users?
                order=${this.order}
                &sortBy=${this.sortBy}
                &perPage=${this.perPage.name}
                &page=${this.page}`;

            if (this.search) {
                query += `&q=${this.search}`;
            }

            if (this.MO.length) {
                this.MO.forEach(m => {
                    query += `&medical_organization[]=${m.id}`;
                });
            }

            if (this.status.length) {
                this.status.forEach(s => {
                    query += `&status[]=${s.id}`;
                });
            }

            if (this.registerDate) {
                query += `&created_at_from=${moment(this.registerDate[0]).format('YYYY-MM-DD')}`;
                query += `&created_at_to=${moment(this.registerDate[1]).format('YYYY-MM-DD') + ' 23:59:59'}`;
            }
            if (this.lastUsageDate) {
                query += `&last_activity_at_from=${moment(this.lastUsageDate[0]).format('YYYY-MM-DD')}`;
                query += `&last_activity_at_to=${moment(this.lastUsageDate[1]).format('YYYY-MM-DD') + ' 23:59:59'}`;
            }
            if (this.showDoctors) {
                query += `&role[]=DOCTOR`;
            }
            if (this.showDoctorsEditors) {
                query += `&role[]=DOCTOR_EDITOR`;
            }
            if (this.showAdministrators) {
                query += `&role[]=ADMIN`;
            }
            try {
                const { data } = await this.axios.get(query);
                this.users = data?.data;
                this.pagesCount = data?.last_page;
                this.total = data?.total;
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },

        trashUser(id) {
            this.$confirm({
                message: `Вы действительно хотите удалить пользователя?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: async confirm => {
                    if (confirm) {
                        try {
                            await this.axios.delete(`users/${id}`);
                            await this.fetchUsers();
                        } catch (e) {
                            console.log(e);
                        }
                    }
                },
            });
        },

        reset() {
            this.search = '';
            this.MO = [];
            this.status = [];
            this.registerDate = null;
            this.lastUsageDate = null;
            this.showDoctors = true;
            this.showDoctorsEditors = true;
            this.showAdministrators = true;
        },

        changeSortBy(param) {
            if (this.sortBy !== param) {
                this.order = 'ascend';
            } else {
                this.order = this.order === 'ascend' ? 'descend' : 'ascend';
            }
            this.sortBy = param;
            this.page = 1;
            this.fetchUsers();
        },

        getSortByClasses(param) {
            return {
                active: this.sortBy===param,
                reverse: this.sortBy===param && this.order==='descend',
            };
        },

        getUserRole(user) {
            return ROLES[user.role];
        },

        getUserMO(user) {
            return this.medicalOrganizations.find(m => m.id === user.medical_organization_id)?.name;
        },

        formattedDate(date) {
            return moment(date).format('DD.MM.YYYY');
        },

        getFIOAbbreviation,

        isEmpty,
    },
};
</script>

<style lang="scss">
.users {
    padding-top: rem(16px);

    &-filters {
        margin-bottom: rem(16px);
        padding: rem(16px) 0 0 rem(16px);

        background: $white;
        border-radius: rem(8px);
        box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);

        .users-filter {
            &--MO {
                flex-shrink: 0;

                width: rem(270px);
            }

            &--status {
                flex-shrink: 0;

                width: rem(246px);
            }

            &--registerDate {
                width: rem(250px);
            }

            &--lastUsageDate {
                width: rem(380px);
            }

            &--reset {
                margin-left: auto;
            }
        }
    }

    &-table {
        position: relative;

        min-height: calc(100vh - #{rem(86px) + rem(16px) + rem(88px) + rem(16px) + rem(88px)});

        .app-table__row {
            height: rem(72px);
        }

        .app-table__col {
            &.active {
                color: $green;
            }

            &.disabled {
                color: $red;
            }

            &.waiting_for_verification {
                color: $orange;
            }
        }

        .app-table__fixed .app-table__col{
            flex-shrink: 0;

            &:nth-of-type(1) {
                width: 4rem;
            }
            &:nth-of-type(2) {
                width: 17rem;
            }
            @media screen and (max-width: 1440px) {
                &:nth-of-type(2) {
                    width: 14rem;
                }
            }
        }

        .app-table__scrolling .app-table__col {
            &:nth-of-type(1) {
                width: 22%;
            }
            &:nth-of-type(2) {
                width: 15.7%;
            }
            &:nth-of-type(3) {
                width: 17%;
            }
            &:nth-of-type(4) {
                width: 17.5%;
            }
            &:nth-of-type(5) {
                width: 18%;
            }
            &:nth-of-type(6) {
                flex-shrink: 0;

                width: 8rem;
            }
            @media screen and (max-width: 1440px) {
                &:nth-of-type(1) {
                    width: 20%;
                }
                &:nth-of-type(3) {
                    width: 15%;
                }
            }
        }

        &__total {
            position: absolute;
            top: rem(8px);
            right: 0;

            font-size: rem(12px);
            color: $dark-blue;

            b {
                font-size: rem(16px);
            }
        }

        &__button--trash {
            padding: rem(2px);

            vertical-align: top;
        }
    }

    &-bottom {
        position: sticky;
        bottom: 0;
        z-index: 1;

        display: flex;
        justify-content: space-between;

        padding: rem(16px) 0;

        &__sort {
            .app-link, .app-select {
                margin-bottom: 0;
            }

            .app-select {
                width: rem(96px);
                margin-right: 0;
            }
        }
    }

    &--more-filters-opened &-table {
        min-height: calc(100vh - #{rem(86px) + rem(16px) + rem(160px) + rem(16px) + rem(88px)});
    }

    @include mobile_or_P {
        padding-top: 1px;
        padding-bottom: rem(90px);

        &-filters {
            height: rem(72px);
            padding: 0;
            overflow: hidden;

            border-radius: 0;

            .row {
                padding: 0 1rem;

                &:nth-of-type(3) {
                    height: 0;
                    overflow: hidden;
                }
            }

            &.open-half {
                height: initial;
                overflow: initial;
            }

            &.open-full {
                height: initial;
                overflow: initial;

                .row:nth-of-type(3) {
                    height: initial;
                    overflow: initial;
                }
            }

            .users-filters__mobile-head {
                box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);

                .row {
                    padding: rem(8px) rem(16px);
                }

                .app-button:last-child {
                    margin-left: auto;
                }

                .app-more-btn, .app-button {
                    margin-bottom: 0;
                }

                .app-more-btn {
                    width: rem(184px);
                }
            }

            .users-filters__mobile-links {
                display: flex;
                justify-content: space-between;

                width: 100%;

                .app-link {
                    height: initial;
                    margin-bottom: rem(24px);
                }
            }

            .users-filter {
                &--MO, &--status, &--registerDate, &--lastUsageDate {
                    width: 100%;
                }
            }
        }

        &-table {
            min-height: initial;

            .app-table__fixed .app-table__col {
                &:nth-of-type(1) {
                    width: 2.5rem;
                }
                &:nth-of-type(2) {
                    width: 7.6rem;
                }
            }

            .app-table__scrolling {
                .app-table__body, .app-table__head {
                    width: 50rem;
                }

                .app-table__col {
                    flex-shrink: 0;

                    &:nth-of-type(1) {
                        width: 7.4rem;
                    }
                    &:nth-of-type(2) {
                        width: 9rem;
                    }
                    &:nth-of-type(3) {
                        width: 7rem;
                    }
                    &:nth-of-type(4) {
                        width: 9rem;
                    }
                    &:nth-of-type(5) {
                        width: 10rem;
                    }
                    &:nth-of-type(6) {
                        width: 7rem;
                    }
                }
            }

            &__total {
                display: none;
            }
        }

        &-bottom {
            position: fixed;
            left: 0;

            width: 100%;
            padding: 1rem 0;

            &__sort {
                display: none;
            }
        }

        &--more-filters-opened &-table {
            min-height: initial;
        }

        @include landscape {
            padding-bottom: rem(80px);

            &-bottom {
                padding: rem(10px) 0;
            }
        }
    }
}
</style>
