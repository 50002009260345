<template lang="pug">
    .special-procedures-block(:class="{'special-procedures-block--re-contact': isReContact, 'special-procedures-block--addition-only': additionOnly}")
        .special-procedures-block__row(
            v-for="(item, index) in specialProcedures"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.special-procedures-block--date(
                    v-model="item.date"
                    label="Дата проведения"
                    :error="getFieldError(item, index, 'date')"
                )
                app-select.special-procedures-block--select(
                    v-model="item.procedure"
                    :options="specialProceduresList"
                    searchable
                    label="Исследование"
                    :error="getFieldError(item, index, 'procedure')"
                )
                app-dropper.forDesktop(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropsSpecialProcedure(index)"
                )
            .row
                app-text-field.special-procedures-block--textarea(
                    v-model="item.result"
                    label="Результат"
                    is-textarea
                    :error="getFieldError(item, index, 'result')"
                )
                app-dropper.forMobile_or_p(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropsSpecialProcedure(index)"
                )
            hr.narrow(v-if="index < specialProcedures.length - 1")
        .br
        app-button(
            plus
            @click="addNewSpecialProcedure"
        ) Добавить

</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'special-procedures-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        specialProcedures: [],
    }),

    validations: {
        specialProcedures: {
            $each: {
                date: { required },
                procedure: { required },
                result: { required },
            },
        },
    },

    computed: {
        specialProceduresList() {
            return this.$store.getters.specialProcedures;
        },
    },

    watch: {
        specialProcedures(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.specialProcedures = this.value;
        });
    },

    methods: {
        addNewSpecialProcedure() {
            this.specialProcedures.push({
                id: uniqueId(),
                date: '',
                procedure: '',
                result: '',
            });
            this.$v.$reset();
        },
        dropsSpecialProcedure(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить процедуру?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.specialProcedures = this.specialProcedures.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.specialProcedures.$each.$iter[index].$dirty && !this.$v.specialProcedures.$each.$iter[index][field].required) {
                return 'Заполните поле';
            }
            return null;
        },

        getFieldsForSave() {
            return this.specialProcedures.map(d => {
                return {
                    date_at: moment(d.date).format('YYYY-MM-DD'),
                    special_procedure_id: d.procedure.id,
                    result: d.result,
                };
            });
        },

        getFormattedSavedFields(specialProcedures) {
            let fields = specialProcedures.map(d => ({
                id: d.id,
                date: +moment(d.date_at),
                procedure: d.special_procedure,
                result: d.result,
                old: true,
            }));
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.special-procedures-block {
    .special-procedures-block {
        &--date {
            width: rem(200px);
        }

        &--select {
            width: rem(438px);
        }

        &--textarea {
            max-width: rem(1048px);
        }
    }

    &--re-contact {
        .special-procedures-block__row.old {
            display: none;
        }
    }

    &--addition-only {
        .special-procedures-block__row.old {
            .app-text-field, .app-select, .app-datepicker {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .special-procedures-block {
            &--date,
            &--select {
                width: 100%;
            }
        }
    }
}
</style>
