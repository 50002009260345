import QuestionnaireModal from '@/components/pages/patients/questionnaires/QuestionnaireModal';

export default {
    components: {
        QuestionnaireModal,
    },

    props: {
        value: {
            type: null,
            required: true,
        },

        error: {
            type: null,
            required: true,
            default: false,
        },

        disabled: {
            type: null,
            required: false,
            default: false,
        },
    },

    data: () => ({
        tempResult: null,
        isOpen: false,
    }),

    computed: {
        result: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        openModal() {
            this.$refs.modal.open();
        },

        save() {
            this.result = this.tempResult;
            this.$refs.modal.close();
        },
    },
};
