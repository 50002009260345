<template lang="pug">
    .page.dictionaries
        h2.forMobile_or_p Справочники
        .app-table__head.forDesktop
            .app-table__col.dictionaries__col
                a(
                    @click="changeSortBy('name')"
                    :class="getSortByClasses('name')"
                    ) Справочник
                    arrow-triangle
            .app-table__col.dictionaries__col
                a(
                    @click="changeSortBy('date')"
                    :class="getSortByClasses('date')"
                ) Последнее изменение
                    arrow-triangle

        app-loader(v-if="loading")
        .dictionaries__list(v-else)
            .dictionaries__item(v-for="dictionary in sortedDictionaries")
                .dictionaries__col
                    b {{ dictionary.name }}
                .dictionaries__col
                    b.fw600 {{ getFormattedDate(dictionary.id) }}
                .dictionaries__col
                    router-link.dictionaries__edit-link(
                        title="Редактирование"
                        :to="{name: 'EditDictionary', params: { id: dictionary.id }}"
                    )
                        edit-icon
</template>

<script>
import ArrowTriangle from '@/assets/images/icons/arrow_triangle.svg';
import editIcon from '@/assets/images/icons/edit.svg';
import { DICTIONARIES } from '@/js/const';
import moment from 'moment';
import { sortBy } from 'lodash';

export default {
    name: 'dictionaries',

    components: {
        ArrowTriangle,
        editIcon,
    },

    data: () => ({
        statuses: [],

        order: 'descend', // ascend, descend
        sortBy: 'date', // name, date

        loading: false,
    }),

    computed: {
        sortedDictionaries() {
            let dictionaries = Object.keys(DICTIONARIES).map(id => ({
                id,
                name: DICTIONARIES[id],
            }));
            dictionaries = sortBy(dictionaries, d => {
                if (this.sortBy === 'name') return d.name;
                if (this.sortBy === 'date') {
                    const date = this.statuses.find(s => s.name === d.id.replace(/-/g, '_'))?.updated_at;
                    return date ? String(+moment(date)) : '';
                }
            });
            if (this.order === 'descend') {
                dictionaries = dictionaries.reverse();
            }
            return dictionaries;
        },
    },

    created() {
        this.fetchDictionariesInfo();
    },

    methods: {
        async fetchDictionariesInfo() {
            this.loading = true;
            try {
                const { data } = await this.axios.get(`dictionaries/info`);
                this.statuses = data.statuses;
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },

        getFormattedDate(id) {
            const date = this.statuses.find(s => s.name === id.replace(/-/g, '_'))?.updated_at;
            return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
        },

        changeSortBy(param) {
            if (this.sortBy !== param) {
                this.order = 'ascend';
            } else {
                this.order = this.order === 'ascend' ? 'descend' : 'ascend';
            }
            this.sortBy = param;
        },

        getSortByClasses(param) {
            return {
                active: this.sortBy===param,
                reverse: this.sortBy===param && this.order==='descend',
            };
        },
    },
};
</script>

<style lang="scss">
.dictionaries {
    padding-top: rem(8px);
    padding-bottom: rem(20px);

    &__list {
        overflow: hidden;

        background: $white;
        border-radius: rem(8px);
        box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);
    }

    &__item {
        display: flex;
        align-items: center;

        height: rem(72px);

        &:not(:last-child) {
            border-bottom: 1px solid $grey;
        }

        @media (hover: hover) {
            &:hover {
                background-color: #f4f6fa;
            }
        }
    }

    &__edit-link {
        display: inline-flex;
        align-items: flex-start;

        width: rem(24px);

        svg {
            width: 100%;

            transition: fill .3s;

            fill: $blue;
        }

        @media (hover: hover) {
            &:hover svg {
                fill: $dark;
            }
        }
    }

    &__col {
        padding-left: 1.5rem;

        &:nth-child(1) {
            width: 77%;
        }

        &:nth-child(2) {
            width: 18%;
        }
    }

    @include mobile_or_P {
        padding-top: 0;

        &__list {
            margin: 0 1rem 0;

            border-radius: 0;
        }

        &__item {
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: flex-start;

            height: initial;
            padding: 1.5rem 0;
        }

        &__col {
            &:nth-of-type(1) {
                margin-bottom: rem(4px);

                b {
                    font-weight: 800;
                }
            }

            &:nth-of-type(2) {
                order: 3;

                width: 100%;

                font-size: rem(12px);
            }
        }
    }
}
</style>
