<template lang="pug">
    .target-therapy-block(:class="{'target-therapy-block--re-contact': isReContact}")
        .target-therapy-block__rows
            .target-therapy-block__row(
                v-for="(item, index) in targetTherapy"
                v-if="!isReContact || !item.isFullField"
                :key="item.id"
                :class="{old: item.old, 'receives-same-volume': item.receivesSameVolume}"
            )
                app-choice(
                    v-if="isReContact && item.old"
                    v-model="item.receivesSameVolume"
                    @input="changeReceivesSameVolume(item)"
                    type="checkbox"
                    label="Получает<br> в прежнем объеме"
                )
                .row
                    app-select.target-therapy-block--name(
                        v-model="item.name"
                        :options="getFilteredDrugNames()"
                        searchable
                        label="Биологический препарат"
                        @input="item.dose=item.measurement=item.multiplicity=item.period=''"
                        :error="getFieldError(item, index, 'name')"
                        :disabled="additionOnly && item.old"
                    )

                    // Доза
                    app-select.target-therapy-block--dose(
                        v-if="!isEmpty(getFilteredDrugDoses(item))"
                        v-model="item.dose"
                        :options="getFilteredDrugDoses(item)"
                        searchable
                        label="Доза"
                        :disabled="!item.name || additionOnly && item.old"
                        @input="item.measurement=item.multiplicity=item.period=''"
                        :error="getFieldError(item, index, 'dose')"
                    )
                    app-text-field.target-therapy-block--dose(
                        v-else-if="isObject(item.dose)"
                        v-model="item.dose.name"
                        label="Доза"
                        :disabled="!item.name || additionOnly && item.old"
                        :error="getFieldError(item, index, 'dose')"
                    )
                    app-text-field.target-therapy-block--dose(
                        v-else
                        v-model="item.dose"
                        label="Доза"
                        :disabled="!item.name || additionOnly && item.old"
                        :error="getFieldError(item, index, 'dose')"
                    )
                    // END Доза

                    app-select.target-therapy-block--measurement(
                        v-model="item.measurement"
                        :options="!isEmpty(getFilteredDrugMeasurements(item)) ? getFilteredDrugMeasurements(item) : measurements"
                        searchable
                        :disabled="!item.dose || additionOnly && item.old"
                        @input="item.multiplicity=item.period=''"
                        :error="getFieldError(item, index, 'measurement')"
                    )

                    // Кратность
                    app-select.target-therapy-block--multiplicity(
                        v-if="!isEmpty(getFilteredDrugMultiplicities(item))"
                        v-model="item.multiplicity"
                        :options="getFilteredDrugMultiplicities(item)"
                        searchable
                        label="Кратность"
                        :disabled="!item.measurement || additionOnly && item.old"
                        @input="item.period=''"
                        :error="getFieldError(item, index, 'multiplicity')"
                    )
                    app-text-field.target-therapy-block--multiplicity(
                        v-else-if="isObject(item.multiplicity)"
                        v-model="item.multiplicity.name"
                        label="Кратность"
                        :disabled="!item.measurement || additionOnly && item.old"
                        :error="getFieldError(item, index, 'multiplicity')"
                    )
                    app-text-field.target-therapy-block--multiplicity(
                        v-else
                        v-model="item.multiplicity"
                        label="Кратность"
                        :disabled="!item.measurement || additionOnly && item.old"
                        :error="getFieldError(item, index, 'multiplicity')"
                    )
                    app-link.app-link--dark.app-link--with-mobile-margin(no-pointer-events) раз (-а)
                    // END Кратность

                    app-select.target-therapy-block--period(
                        v-model="item.period"
                        :options="!isEmpty(getFilteredDrugPeriods(item)) ? getFilteredDrugPeriods(item) : periods"
                        searchable
                        label="Период"
                        :disabled="!item.multiplicity || additionOnly && item.old"
                        :error="getFieldError(item, index, 'period')"
                    )
                    app-text-field.target-therapy-block--periodCustom(
                        v-if="item.period && item.period.name.toLowerCase() === 'другая'"
                        v-model="item.periodCustom"
                        :disabled="additionOnly && item.old"
                        :error="getFieldError(item, index, 'periodCustom')"
                    )
                    app-dropper.forDesktop(
                        :class="{hidden: (isReContact || additionOnly) && item.old}"
                        @click="dropTargetTherapy(index)"
                    )
                .row
                    app-datepicker.target-therapy-block--therapyStart(
                        v-model="item.therapyStart"
                        label="Инициация терапии"
                        :max-date="item.therapyEnd ? item.therapyEnd - 1 : null"
                        :disabled="additionOnly && item.old"
                        :error="getFieldError(item, index, 'therapyStart')"
                    )
                    app-select.target-therapy-block--appointedBy(
                        v-model="item.appointedBy"
                        :options="userList"
                        :disabled="additionOnly && item.old"
                        label="Назначил (-а)"
                    )
                    template(v-if="!isReContact || item.old")
                        app-datepicker.target-therapy-block--therapyEnd(
                            v-model="item.therapyEnd"
                            clearable
                            label="Окончание терапии"
                            :min-date="item.therapyStart ? item.therapyStart + 1 : null"
                            :disabled="additionOnly && item.old && item.old_values.therapyEnd"
                            :error="getFieldError(item, index, 'therapyEnd')"
                        )
                        app-select.target-therapy-block--cancellationReason(
                            v-model="item.cancellationReason"
                            :options="cancellationReasons"
                            clearable
                            allow-empty
                            label="Причины отмены"
                            :disabled="additionOnly && item.old && item.old_values.cancellationReason"
                            :error="getFieldError(item, index, 'cancellationReason')"
                        )
                        app-select.target-therapy-block--cancellationBy(
                            v-model="item.cancellationBy"
                            :options="userList"
                            clearable
                            allow-empty
                            label="Отменил"
                            :disabled="additionOnly && item.old && item.old_values.cancellationBy"
                            :error="getFieldError(item, index, 'cancellationBy')"
                        )
                    app-dropper.forMobile_or_p(
                        :class="{hidden: (isReContact || additionOnly) && item.old}"
                        @click="dropTargetTherapy(index)"
                    )
                hr
        .br
        app-button(
            plus
            @click="addNewTargetTherapy"
        ) Добавить препарат
</template>

<script>
import { mapGetters } from 'vuex';
import { uniqueId, isEmpty, isObject } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import getFIOAbbreviation from '@/js/utils/getFIOAbbreviation';
import moment from 'moment';

export default {
    name: 'target-therapy-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        targetTherapy: [],
    }),

    validations: {
        targetTherapy: {
            $each: {
                name: {
                    required,
                    uniqual(value, item) {
                        return !this.targetTherapy.some(a => a.id !== item.id
                            && a.name.name === item.name.name
                            && item.therapyStart
                            && a.therapyStart === item.therapyStart);
                    },
                },
                dose: { required },
                measurement: { required },
                multiplicity: { required },
                period: { required },
                periodCustom: {
                    required(value, item) {
                        return value || item.period?.name.toLowerCase() !== 'другая';
                    },
                },

                therapyStart: {
                    required,
                    valid(value, item) {
                        // Дата начала терапии с тем же препаратом
                        // не может быть раньше даты окончания какой-либо другой
                        const itemsWithEqualDrugs = this.targetTherapy.filter(t => t.id !== item.id && t.name.id === item.name.id);
                        if (itemsWithEqualDrugs.some(i => item.therapyStart > i.therapyStart
                            && item.therapyStart < i.therapyEnd)) {
                            return false;
                        }
                        return true;
                    },
                },
                therapyEnd: {
                    required(value, item) {
                        if (value) return true;
                        if (this.isReContact && item.old && !item.receivesSameVolume) return false;

                        // Если 2 одинаковых препарата, то должны быть даты окончания терапии
                        const equalTherapy = this.targetTherapy.filter(t => t.name.id === item.name.id);
                        if (equalTherapy.length - equalTherapy.filter(t => t.therapyEnd).length > 1) return false;

                        if (!item.cancellationReason && !item.cancellationBy) return true;
                        return false;
                    },
                },
                cancellationReason: {
                    required(value, item) {
                        if (value) return true;
                        if (this.isReContact && item.old && !item.receivesSameVolume) return false;
                        if (!item.therapyEnd && !item.cancellationBy) return true;
                        return false;
                    },
                    revision(value, item) { // должна быть еще одна начатая терапия
                        if (value?.name === 'Пересмотр терапии'
                            && item.therapyEnd
                            && !this.targetTherapy.some(t => t.therapyStart === item.therapyEnd)) {
                            return false;
                        }
                        return true;
                    },
                },
                cancellationBy: {
                    required(value, item) {
                        if (value) return true;
                        if (this.isReContact && item.old && !item.receivesSameVolume) return false;
                        if (!item.therapyEnd && !item.cancellationReason) return true;
                        return false;
                    },
                },
            },
        },
    },

    computed: {
        ...mapGetters([
            'user',
            'drugs',
            'measurements',
            'periods',
            'cancellationReasons',
            'userList',
        ]),
    },

    watch: {
        targetTherapy(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.targetTherapy = this.value;
        });
    },

    methods: {
        addNewTargetTherapy() {
            this.targetTherapy.push({
                id: uniqueId(),
                name: '',
                dose: '',
                measurement: '',
                multiplicity: '',
                period: '',
                periodCustom: '',

                therapyStart: '',
                appointedBy: {
                    name: getFIOAbbreviation(this.user),
                    id: this.user.id,
                },
                therapyEnd: '',
                cancellationReason: '',
                cancellationBy: '',
            });
            this.$v.$reset();
        },

        dropTargetTherapy(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить терапию?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.targetTherapy = this.targetTherapy.filter((d, i) => i !== index);
                    }
                },
            });
        },

        changeReceivesSameVolume(item){
            if (!item.receivesSameVolume) {
                item.therapyEnd = +moment();
                item.cancellationBy = {
                    name: getFIOAbbreviation(this.user),
                    id: this.user.id,
                };
            } else {
                item.therapyEnd = '';
                item.cancellationReason = '';
                item.cancellationBy = '';
            }
        },

        getFilteredDrugNames() {
            return this.drugs?.reduce((arr, d) => {
                if (!arr.some(n => n.name === d.name)) {
                    arr.push({
                        name: d.name,
                        id: d.id,
                    });
                }
                return arr;
            }, []);
        },

        getFilteredDrugDoses(item) {
            return this.drugs
                .filter(d => d.dose
                    && d.name === item.name.name)
                .reduce((arr, d) => {
                    if (!arr.some(n => n.name === d.dose)) {
                        arr.push({
                            name: d.dose,
                        });
                    }
                    return arr;
                }, []);
        },

        getFilteredDrugMeasurements(item) {
            return this.drugs
                .filter(d => d.measurement_id
                    && d.name === item.name.name && d.dose === item.dose.name)
                .reduce((arr, d) => {
                    const measurementName = this.measurements.find(m => m.id === d.measurement_id)?.name;
                    if (!arr.some(n => n.name === measurementName)) {
                        arr.push({
                            name: measurementName,
                            id: d.measurement_id,
                        });
                    }
                    return arr;
                }, []);
        },

        getFilteredDrugMultiplicities(item) {
            return this.drugs
                .filter(d => d.period_value
                    && d.name === item.name.name
                    && d.dose === item.dose.name
                    && this.measurements.find(m => m.id === d.measurement_id)?.name === item.measurement.name)
                .reduce((arr, d) => {
                    if (!arr.some(n => n.name === d.period_value)) {
                        arr.push({
                            name: d.period_value,
                        });
                    }
                    return arr;
                }, []);
        },

        getFilteredDrugPeriods(item) {
            return this.drugs
                .filter(d =>
                    d.period_id
                    && d.name === item.name.name
                    && d.dose === item.dose.name
                    && this.measurements.find(m => m.id === d.measurement_id)?.name === item.measurement.name
                    && d.period_value === item.multiplicity.name)
                .reduce((arr, d) => {
                    const periodName = this.periods.find(p => p.id === d.period_id)?.name;
                    if (!arr.some(n => n.name === periodName)) {
                        arr.push({
                            name: periodName,
                            id: d.period_id,
                        });
                    }
                    return arr;
                }, []);
        },

        getFieldError(item, index, field) {
            if (this.$v.targetTherapy.$each.$iter[index].$dirty) {
                if (!this.$v.targetTherapy.$each.$iter[index][field].required) {
                    return 'Заполните поле';
                } else if ((field === 'name' || field === 'therapyStart')
                    && !this.$v.targetTherapy.$each.$iter[index].name.uniqual) {
                    return 'Препарат дублируется';
                } else if (field === 'cancellationReason'
                    && !this.$v.targetTherapy.$each.$iter[index].cancellationReason.revision) {
                    return 'Укажите новый назначенный препарат';
                } else if (field === 'therapyStart'
                    && !this.$v.targetTherapy.$each.$iter[index].therapyStart.valid) {
                    return 'Недопустимая дата инициации';
                }
            }
            return null;
        },

        getFieldsForSave() {
            return this.targetTherapy.map(t => {
                const obj = {
                    drug_id: t.name.id,
                    dose: isObject(t.dose) ? t.dose.name : t.dose,
                    measurement_id: t.measurement.id,
                    multiplicity: isObject(t.multiplicity) ? t.multiplicity.name : t.multiplicity,
                    period_id: t.period.id,
                    period_custom: t.periodCustom,

                    start_at: moment(t.therapyStart).format('YYYY-MM-DD'),
                    doctor_at_start_id: t.appointedBy.id,
                };
                if (t.therapyEnd) {
                    obj.end_at = moment(t.therapyEnd).format('YYYY-MM-DD');
                    obj.doctor_at_end_id = t.cancellationBy.id;
                    obj.cancellation_reason_id = t.cancellationReason.id;
                }
                return obj;
            });
        },

        getFormattedSavedFields(targetTherapy) {
            let fields = targetTherapy.map(t => {
                const obj = {
                    id: t.id,
                    name: {
                        name: t.drug.name,
                        id: t.drug.id,
                    },
                    dose: {
                        name: t.dose,
                    },
                    measurement: t.measurement,
                    multiplicity: {
                        name: t.multiplicity,
                    },
                    period: t.period,
                    periodCustom: t.period_custom || '',

                    therapyStart: +moment(t.start_at),
                    appointedBy: {
                        name: getFIOAbbreviation(t.doctor_at_start),
                        id: t.doctor_at_start?.id,
                    },
                    therapyEnd: t.end_at ? +moment(t.end_at) : '',
                    cancellationReason: t.cancellation_reason || '',
                    cancellationBy: t.doctor_at_end ? {
                        name: getFIOAbbreviation(t.doctor_at_end),
                        id: t.doctor_at_end?.id,
                    } : '',
                    old: true,
                    isFullField: t.end_at, // Полностью заполнено и не надо показывать при повторном контакте
                    receivesSameVolume: true,
                };
                return {
                    ...obj,
                    old_values: { ...obj },
                };
            });
            fields.sort((f1, f2) => f1.therapyStart - f2.therapyStart);
            return fields;
        },

        isEmpty,
        isObject,
    },
};
</script>

<style lang="scss">
.target-therapy-block {
    &__row:last-of-type hr {
        display: none;
    }

    .target-therapy-block {
        &--name {
            width: rem(270px);
        }

        &--dose {
            width: rem(102px);
        }

        &--measurement, &--period {
            width: rem(145px);
        }

        &--multiplicity {
            width: rem(140px);
        }

        &--periodCustom {
            width: rem(250px);
        }

        &--therapyStart, &--therapyEnd {
            width: rem(215px);
        }

        &--appointedBy, &--cancellationBy {
            width: rem(203px);
        }

        &--cancellationReason {
            width: rem(300px);
        }
    }

    &--re-contact {
        .target-therapy-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 2rem 1rem 0;

            hr {
                margin-bottom: 0;
            }

            &:first-child {
                padding-top: 1rem;
            }

            &.old {
                background: $light-blue;

                .target-therapy-block {
                    &--name,
                    &--dose,
                    &--measurement,
                    &--multiplicity,
                    &--period,
                    &--therapyStart,
                    &--appointedBy {
                        opacity: 0.5;

                        pointer-events: none;
                    }
                }
            }

            &.receives-same-volume .target-therapy-block {
                &--therapyEnd,
                &--cancellationReason,
                &--cancellationBy {
                    opacity: 0.5;

                    pointer-events: none;
                }
            }
        }
    }

    @include mobile_or_P {
        .target-therapy-block {
            &--name,
            &--therapyStart, &--therapyEnd,
            &--appointedBy,
            &--periodCustom,
            &--cancellationReason,
            &--cancellationBy {
                width: 100%;
            }

            &--dose, &--measurement, &--multiplicity, &--period {
                width: rem(153px);
            }

            &--dose, &--multiplicity {
                margin-right: rem(20px);
            }
        }
    }
}
</style>
