<template lang="pug">
    .re-contact-patient-data
        div
            h6 {{ patientName }}
            small ID {{ lastData.id }}
        contact-block(ref="contact")

</template>

<script>

import moment from 'moment';
import ContactBlock from '@/components/pages/patients/re-contact/blocks/ContactBlock';

export default {
    name: 're-contact-patient-data',

    components: {
        ContactBlock,
    },

    props: {
        lastData: {
            type: Object,
            required: true,
        },
    },

    computed: {
        patientName() {
            return `${this.lastData.last_name} ${this.lastData.first_name} ${this.lastData.middle_name || ''}`;
        },
    },

    methods: {
        getFormFields() {
            const obj = {
                first_name: this.lastData.first_name,
                last_name: this.lastData.last_name,
                middle_name: this.lastData.middle_name || '',
                birth_date: moment(this.lastData.birth_date).format('YYYY-MM-DD'),
                age_at_start: this.lastData.age_at_start,
                sex: this.lastData.sex,
                race_id: this.lastData.race_id,

                living_address: this.lastData.living_address,
                phone: this.lastData.phone,
                email: this.lastData.email || '',
                oms: this.lastData.oms,
                insurance_company: this.lastData.insurance_company || '',
                sent_from: this.lastData.sent_from || '',

                is_active: this.lastData.is_active,

                contacts: [
                    ...this.lastData.contacts.map(c => ({
                        date_at: moment(c.date_at).format('YYYY-MM-DD'),
                        contact_type: c.contact_type,
                        doctor_name: c.doctor_name,
                        serum_taken: c.serum_taken,
                        number: c.number || '',
                    })),
                    this.$refs.contact.getFieldsForSave(),
                ],
            };
            if (this.lastData.block_reason) {
                obj.block_reason = this.lastData.block_reason;
            }
            return obj;
        },

        checkValid() {
            if (this.$refs.contact.$v.$invalid) {
                this.$refs.contact.$v.$touch();
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss">
.re-contact-patient-data{
    display: flex;
    justify-content: space-between;
    align-self: center;

    margin-bottom: rem(24px);
    padding: 1rem 1rem 0.5rem;

    background: #fff;
    border-radius: rem(8px);
    box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);

    h6 {
        margin: 0 0 rem(4px);

        font-size: rem(24px);
        line-height: rem(29px);
        font-weight: bold;
        color: $dark-blue;
    }

    small {
        color: $dark-grey;
    }

    @include mobile_or_P {
        flex-direction: column;

        small {
            display: block;

            margin-bottom: 1rem;
        }
    }
}
</style>
