let VueScrollTo = require('vue-scrollto');

export default () => {
    const invalidField = document.querySelector('.app-text-field--invalid, .app-select--invalid, .app-datepicker--invalid, .app-choice--invalid');
    if (invalidField) {
        const offset = window.isMobileOrTablet ? -75 : -150;
        VueScrollTo.scrollTo(invalidField, 500, {
            offset: offset,
        });
    }
};
