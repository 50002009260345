<template lang="pug">
    .vas-block(:class="{'vas-block--re-contact': isReContact, 'vas-block--addition-only': additionOnly}")
        .vas-block__row(
            v-for="(item, index) in vas"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-text-field.vas-block--result(
                    v-model="item.result"
                    is-number
                    :max="100"
                    label="Результат"
                    :error="getFieldError(item, index, 'result')"
                )
                //app-link(:disabled="true") Рассчитать
                app-datepicker.vas-block--date(
                    v-model="item.date"
                    label="Дата"
                    :disabled-dates="disabledDates"
                    :error="getFieldError(item, index, 'date')"
                )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropVas(index)"
                )
            hr.narrow(v-if="index < vas.length - 1")
        .br
        app-button(
            plus
            @click="addNewVas()"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'vas-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        vas: [],
    }),

    validations: {
        vas: {
            $each: {
                result: { required },
                date: { required },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.vas.map(e => e.date);
        },
    },

    watch: {
        vas(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.vas = this.value;
        });
    },

    methods: {
        addNewVas(date = '') {
            this.vas.push({
                id: uniqueId(),
                result: '',
                date: date,
            });
            this.$v.$reset();
        },

        dropVas(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.vas = this.vas.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.vas.$each.$iter[index].$dirty && !this.$v.vas.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.vas.map(d => {
                return {
                    result: d.result,
                    date_at: moment(d.date).format('YYYY-MM-DD'),
                };
            });
        },

        getFormattedSavedFields(vas) {
            return vas.map(d => ({
                id: d.id,
                result: d.result,
                date: +moment(d.date_at),
                old: true,
            }));
        },
    },
};
</script>

<style lang="scss">
.vas-block {
    .vas-block {
        &--result {
            width: rem(105px);
        }

        &--date {
            width: rem(168px);
        }
    }

    &--re-contact {
        .vas-block__row.old {
            display: none;
        }
    }

    &--addition-only {
        .vas-block__row.old {
            .app-text-field, .app-datepicker {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .vas-block {
            &--result {
                width: rem(200px);
                margin-right: 1.5rem;
            }

            &--date {
                width: 100%;
            }
        }
    }
}
</style>
