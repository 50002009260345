<template lang="pug">
    .page-404 404
</template>

<script>
export default {
    name: 'page-404',
};
</script>

<style lang="scss">

</style>
