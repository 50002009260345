<template lang="pug">
    .page.edit-dictionary
        h2 Справочник
        .edit-dictionary__container
            medical-organizations-dictionary(v-if="dictionaryId === 'medical-organizations'")
            administration-methods-dictionary(v-else-if="dictionaryId === 'administration-methods'")
            cancellation-reasons-dictionary(v-else-if="dictionaryId === 'cancellation-reasons'")
            causes-exacerbations-dictionary(v-else-if="dictionaryId === 'causes-of-exacerbations'")
            relatives-dictionary(v-else-if="dictionaryId === 'relatives'")
            injuries-dictionary(v-else-if="dictionaryId === 'injuries'")
            measurements-dictionary(v-else-if="dictionaryId === 'measurements'")
            specific-examinations-dictionary(v-else-if="dictionaryId === 'specific-examinations'")
            functional-tests-dictionary(v-else-if="dictionaryId === 'functional-tests'")
            laboratory-indicators-dictionary(v-else-if="dictionaryId === 'laboratory-indicators'")
            provocative-tests-dictionary(v-else-if="dictionaryId === 'provocative-tests'")
            allergens-dictionary(v-else-if="dictionaryId === 'allergens'")
            special-procedures-dictionary(v-else-if="dictionaryId === 'special-procedures'")
            periods-dictionary(v-else-if="dictionaryId === 'periods'")
            drugs-dictionary(v-else-if="dictionaryId === 'drugs'")
</template>

<script>
import MedicalOrganizationsDictionary from '@/components/pages/dictionaries/MedicalOrganizationsDictionary';
import AdministrationMethodsDictionary from '@/components/pages/dictionaries/AdministrationMethodsDictionary';
import CancellationReasonsDictionary from '@/components/pages/dictionaries/CancellationReasonsDictionary';
import CausesExacerbationsDictionary from '@/components/pages/dictionaries/CausesExacerbationsDictionary';
import RelativesDictionary from '@/components/pages/dictionaries/RelativesDictionary';
import InjuriesDictionary from '@/components/pages/dictionaries/InjuriesDictionary';
import MeasurementsDictionary from '@/components/pages/dictionaries/MeasurementsDictionary';
import SpecificExaminationsDictionary from '@/components/pages/dictionaries/SpecificExaminationsDictionary';
import FunctionalTestsDictionary from '@/components/pages/dictionaries/FunctionalTestsDictionary';
import LaboratoryIndicatorsDictionary from '@/components/pages/dictionaries/LaboratoryIndicatorsDictionary';
import ProvocativeTestsDictionary from '@/components/pages/dictionaries/ProvocativeTestsDictionary';
import AllergensDictionary from '@/components/pages/dictionaries/AllergensDictionary';
import SpecialProceduresDictionary from '@/components/pages/dictionaries/SpecialProceduresDictionary';
import PeriodsDictionary from '@/components/pages/dictionaries/PeriodsDictionary';
import DrugsDictionary from '@/components/pages/dictionaries/DrugsDictionary';
import { DICTIONARIES } from '@/js/const';

export default {
    name: 'edit-dictionary',

    components: {
        MedicalOrganizationsDictionary,
        AdministrationMethodsDictionary,
        CancellationReasonsDictionary,
        CausesExacerbationsDictionary,
        RelativesDictionary,
        InjuriesDictionary,
        MeasurementsDictionary,
        SpecificExaminationsDictionary,
        FunctionalTestsDictionary,
        LaboratoryIndicatorsDictionary,
        ProvocativeTestsDictionary,
        AllergensDictionary,
        SpecialProceduresDictionary,
        PeriodsDictionary,
        DrugsDictionary,
    },

    beforeRouteEnter(to, from, next) {
        if (!DICTIONARIES[to.params.id]) {
            next({ name: '404' });
        }
        next();
    },

    computed: {
        dictionaryId() {
            return this.$route.params.id;
        },
    },
};
</script>

<style lang="scss">
.edit-dictionary {
    &__top, &__form, &__bottom {
        background: $white;
        border-radius: rem(8px);
        box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);
    }

    &__top, &__form {
        margin-bottom: rem(32px);
    }

    &__form, &__bottom {
        padding: rem(16px) rem(16px) 0;
    }

    &__top {
        padding: rem(16px);
    }

    &__name {
        margin-bottom: rem(8px);

        font-size: rem(24px);
        font-weight: 700;
        color: $dark-blue;
    }

    @include mobile_or_P {
        &__top, &__form {
            margin-bottom: rem(24px);
        }

        &__form, &__bottom {
            max-width: 100vmin;
            margin-right: auto;
            margin-left: auto;
        }

        .app-button {
            justify-content: center;

            width: 100%;
            height: rem(56px);
        }

        .row {
            margin-bottom: 2rem;
        }
    }
}
</style>
