<template lang="pug">
    .questionnaire-ae-qol
        app-text-field.result-field(
            v-model="result"
            label="AE-Qol"
            is-number
            :max="68"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет AE-QoL

            .block(v-for="(block, blockIndex) in blocks")
                .block__name {{ block.name }}
                .block-choices.app-choice-group(v-for="(question, id) in block.questions")
                    .app-choice-group__label {{id}}. {{ question.name }}
                    .app-choice-group__list
                        app-choice(
                            v-for="variant in variants"
                            v-model="question.value"
                            type="radio"
                            :label="variant.name"
                            :radio-value="variant.value"
                            :error="$v.$dirty && $v.blocks.$each.$iter[blockIndex].questions.$each.$iter[id].value.$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") AE-QoL = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-ae-qol',

    mixins: [questionnaireMixin],

    data: () => ({
        blocks: [
            {
                name: 'Как часто в течение последних 4-х недель пациент испытывал ограничения в приведенных ниже сферах повседневной жизни из-за появления отеков. (Даже если за этот период отеков у не было):',
                questions: {
                    1: { name: 'Работа' },
                    2: { name: 'Физическая активность' },
                    3: { name: 'Свободное активность' },
                    4: { name: 'Социальная активность' },
                    5: { name: 'Питание' },
                },
            },
            {
                name: 'Трудности и проблемы, которые могут быть связаны с повторяющимися отеками:',
                questions: {
                    6: { name: 'Вы трудно засыпаете?' },
                    7: { name: 'Вы просыпаетесь?' },
                    8: { name: 'Чувствуете ли Вы себя усталым в течение дня, потому что плохо спали ночью?' },
                    9: { name: 'Трудно ли Вам сосредоточиться?' },
                    10: { name: 'Чувствуете ли Вы себя подавленным?' },
                    11: { name: 'Вынуждены ли Вы ограничивать себя в выборе напитков и еды?' },
                    12: { name: 'Угнетают/мешают ли Вам случаи отеков?' },
                    13: { name: 'Испытываете ли Вы страх ожидания внезапного появления отека?' },
                    14: { name: 'Боитесь ли Вы того, что отеки могут появляться чаще?' },
                    15: { name: 'Стесняетесь ли Вы появляться в общественных местах из-за периодически появляющихся отеков?' },
                    16: { name: 'Ощущаете ли Вы смущение или робость в связи с периодически возникающими отеками?' },
                    17: { name: 'Опасаетесь ли Вы того, что лечение повторяющихся отеков может в долгосрочной перспективе иметь для Вас негативные последствия?' },
                },
            },
        ],

        variants: [
            { name: 'Никогда', value: 0 },
            { name: 'Редко', value: 1 },
            { name: 'Иногда', value: 2 },
            { name: 'Часто', value: 3 },
            { name: 'Очень часто', value: 4 },
        ],
    }),

    validations: {
        blocks: {
            $each: {
                questions: {
                    $each: {
                        value: { required },
                    },
                },
            },
        },
    },

    created() {
        this.blocks.forEach(block => {
            for (let id in block.questions) {
                this.$set(block.questions[id], 'value', null);
            }
        });
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = this.blocks.reduce((sum, block) => {
                return sum + Object.values(block.questions).reduce((sumB, question) => sumB + question.value, 0);
            }, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-ae-qol {
    .result-field {
        width: rem(82px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }

        .block-choices {
            width: 100%;

            .app-choice {
                margin-right: 0;
            }
        }
    }
}
</style>
