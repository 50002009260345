<template lang="pug">
    .questionnaires-asthma(:class="{'questionnaires-asthma--re-contact': isReContact}")
        .questionnaires-asthma__row(
            v-for="(item, index) in questionnaires"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.questionnaires-asthma--date(
                    v-model="item.date"
                    label="Дата"
                    :disabled-dates="disabledDates"
                    :error="getFieldError(item, index, 'date')"
                    :disabled="additionOnly && item.old"
                )

                questionnaire-act(
                    v-model="item.ACT"
                    :error="getFieldError(item, index, 'ACT')"
                    :disabled="additionOnly && item.old && item.old_values.ACT"
                )

                questionnaire-acq5(
                    v-model="item.ACQ5"
                    :error="getFieldError(item, index, 'ACQ5')"
                    :disabled="additionOnly && item.old && item.old_values.ACQ5"
                )

                questionnaire-aqlq(
                    v-model="item.AQLQ"
                    :error="getFieldError(item, index, 'AQLQ')"
                    :disabled="additionOnly && item.old && item.old_values.AQLQ"
                )

                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropQuestionnaires(index)"
                )
            hr.narrow(v-if="index < questionnaires.length - 1")
        .br
        app-button(
            plus
            @click="addNewQuestionnaires()"
        ) Добавить
</template>

<script>
import QuestionnaireAcq5 from '@/components/pages/patients/questionnaires/modals/ACQ5';
import QuestionnaireAct from '@/components/pages/patients/questionnaires/modals/ACT';
import QuestionnaireAqlq from '@/components/pages/patients/questionnaires/modals/AQLQ';

import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
const requiredField = (value, item) => {
    return item.ACT || item.ACQ5 || item.AQLQ;
};

export default {
    name: 'questionnaires-asthma',

    components: {
        QuestionnaireAcq5,
        QuestionnaireAct,
        QuestionnaireAqlq,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        questionnaires: [],
    }),

    validations: {
        questionnaires: {
            $each: {
                date: { required },
                ACT: {
                    required: requiredField,
                    possibleValue(value) {
                        return !value || +value >= 5;
                    },
                },
                ACQ5: { required: requiredField },
                AQLQ: {
                    required: requiredField,
                    possibleValue(value) {
                        return !value || +value >= 31;
                    },
                },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.questionnaires.map(e => e.date);
        },
    },

    watch: {
        questionnaires(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.value.length) {
                this.questionnaires = this.value;
            }
        });
    },

    methods: {
        addNewQuestionnaires(date = '') {
            this.questionnaires.push({
                id: uniqueId(),
                date: date,
                ACT: '',
                ACQ5: '',
                AQLQ: '',
            });
            this.$v.$reset();
        },

        dropQuestionnaires(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить опросник?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.questionnaires = this.questionnaires.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.questionnaires.$each.$iter[index].$dirty) {
                if (!this.$v.questionnaires.$each.$iter[index][field].required) {
                    return 'Заполните поле';
                }
                if (field === 'ACT' || field === 'AQLQ') {
                    if (!this.$v.questionnaires.$each.$iter[index][field].possibleValue) {
                        return 'Неверное значение';
                    }
                }
            }
            return null;
        },

        getFieldsForSave() {
            return this.questionnaires.map(d => ({
                date_at: moment(d.date).format('YYYY-MM-DD'),
                data: {
                    ACT: d.ACT,
                    ACQ5: d.ACQ5,
                    AQLQ: d.AQLQ,
                },
            }));
        },

        getFormattedSavedFields(questionnaires) {
            let fields = questionnaires.map(d => {
                const obj = {
                    id: d.id,
                    date: +moment(d.date_at),
                    ACT: d.data.ACT || '',
                    ACQ5: d.data.ACQ5 || '',
                    AQLQ: d.data.AQLQ || '',
                    old: true,
                };
                return {
                    ...obj,
                    old_values: { ...obj },
                };
            });
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.questionnaires-asthma {
    .questionnaires-asthma--date {
        width: rem(168px);
    }

    &--re-contact {
        .questionnaires-asthma__row.old {
            display: none;
        }
    }

    @include mobile_or_P {
        .questionnaires-asthma--date {
            width: 100%;
        }
    }
}
</style>
