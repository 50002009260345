<template lang="pug">
    .tnss-block(:class="{'tnss-block--re-contact': isReContact, 'tnss-block--addition-only': additionOnly}")
        .tnss-block__row(
            v-for="(item, index) in tnss"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                questionnaire-tnss(
                    v-model="item.result"
                    :error="getFieldError(item, index, 'result')"
                )

                app-datepicker.tnss-block--date(
                    v-model="item.date"
                    label="Дата"
                    :disabled-dates="disabledDates"
                    :error="getFieldError(item, index, 'date')"
                )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropTnss(index)"
                )
            hr.narrow(v-if="index < tnss.length - 1")
        .br
        app-button(
            plus
            @click="addNewTnss()"
        ) Добавить
</template>

<script>
import QuestionnaireTnss from '@/components/pages/patients/questionnaires/modals/TNSS';

import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'tnss-block',

    components: {
        QuestionnaireTnss,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        tnss: [],
    }),

    validations: {
        tnss: {
            $each: {
                result: { required },
                date: { required },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.tnss.map(e => e.date);
        },
    },

    watch: {
        tnss(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.tnss = this.value;
        });
    },

    methods: {
        addNewTnss(date = '') {
            this.tnss.push({
                id: uniqueId(),
                result: '',
                date: date,
            });
            this.$v.$reset();
        },

        dropTnss(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.tnss = this.tnss.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.tnss.$each.$iter[index].$dirty && !this.$v.tnss.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.tnss.map(d => {
                return {
                    result: d.result,
                    date_at: moment(d.date).format('YYYY-MM-DD'),
                };
            });
        },

        getFormattedSavedFields(tnss) {
            return tnss.map(d => ({
                id: d.id,
                result: d.result,
                date: +moment(d.date_at),
                old: true,
            }));
        },
    },
};
</script>

<style lang="scss">
.tnss-block {
    .tnss-block--date {
        width: rem(168px);
    }

    &--re-contact {
        .tnss-block__row.old {
            display: none;
        }
    }

    &--addition-only {
        .tnss-block__row.old {
            .app-text-field, .app-datepicker {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .tnss-block--date {
            width: 100%;
        }
    }
}
</style>
