<template lang="pug">
    .questionnaire-uas7
        app-text-field.result-field(
            v-model="result"
            label="UAS 7"
            is-number
            :max="42"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет UAS 7

            .block(v-for="(day, number) in days")
                .block__name День {{ number}}
                .block-question(v-for="(question, questionId) in questions")
                    .block-question__name {{ question.name }}
                    .app-choice-group
                        app-choice(
                            v-for="answer in question.answers"
                            v-model="day['q'+questionId]"
                            type="radio"
                            :label="answer.name"
                            :radio-value="answer.value"
                            :error="$v.$dirty && $v.days.$each.$iter[number]['q'+questionId].$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") UAS 7 = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-uas7',

    mixins: [questionnaireMixin],

    data: () => ({
        questions: {
            1: {
                name: 'Волдыри (выраженность)',
                answers: [
                    { name: 'Нет', value: 0 },
                    { name: 'Легкая (&lt;20 волдырей/24 ч)', value: 1 },
                    { name: 'Средняя (20–50 волдырей/24 ч)', value: 2 },
                    { name: 'Интенсивная (&gt;50&nbsp;волдырей/24&nbsp;ч или большие сливающиеся волдыри)', value: 3 },
                ],
            },
            2: {
                name: 'Зуд (выраженность)',
                answers: [
                    { name: 'Нет', value: 0 },
                    { name: 'Легкая (присутствует, но&nbsp;не&nbsp;причиняет беспокойства)', value: 1 },
                    { name: 'Средняя (беспокоит, но&nbsp;не&nbsp;влияет на&nbsp;дневную активность и&nbsp;сон)', value: 2 },
                    { name: 'Интенсивная (тяжелый зуд, достаточно беспокоящий, нарушающий дневную активность и&nbsp;сон)', value: 3 },
                ],
            },
        },

        days: {
            1: { q1: null, q2: null },
            2: { q1: null, q2: null },
            3: { q1: null, q2: null },
            4: { q1: null, q2: null },
            5: { q1: null, q2: null },
            6: { q1: null, q2: null },
            7: { q1: null, q2: null },
        },
    }),

    validations: {
        days: {
            $each: {
                q1: { required },
                q2: { required },
            },
        },
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = Object.values(this.days).reduce((sum, day) => sum + day.q1 + day.q2, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-uas7 {
    .result-field {
        width: rem(75px);
    }

    .block__name {
        font-weight: 700;
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
