export default {
    data: () => ({
        medicinesList: [],
    }),

    methods: {
        async fetchMedicines(value) {
            try {
                const { data } = await this.axios.get(`dictionaries/medicines?value=${value}&sortBy=id&perPage=30&order=ascend`);
                this.medicinesList = data.data.map(d => ({
                    ...d,
                    name: d.chemistry_name,
                }));
            } catch (e) {
                console.log(e);
            }
        },
    },
};
