<template lang="pug">
    .questionnaire-aqlq
        app-text-field.result-field(
            v-model="result"
            label="AQLQ"
            is-number
            :max="224"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет AQLQ

            .block(v-for="(block, blockIndex) in blocks")
                .block__name(
                    v-if="block.name"
                    v-html="block.name"
                )
                .block-question(v-for="(question, id) in block.questions")
                    .block-question__name(v-html="id + '. ' + question.name")
                    .app-choice-group
                        app-choice(
                            v-for="variant in block.variants"
                            v-model="question.value"
                            type="radio"
                            :label="variant.name"
                            :radio-value="variant.value"
                            :error="$v.$dirty && $v.blocks.$each.$iter[blockIndex].questions.$each.$iter[id].value.$invalid"
                        )
                .block__footnote(
                    v-if="block.footnote"
                    v-html="block.footnote"
                )

            .bottom
                .result(v-if="tempResult !== null") AQLQ = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-aqlq',

    mixins: [questionnaireMixin],

    data: () => ({
        blocks: [
            {
                name: 'В&nbsp;какой степени из-за астмы вы&nbsp;были ограничены в&nbsp;перечисленных ниже видах деятельности в&nbsp;течение последних 2&nbsp;недель?',
                questions: {
                    1: { name: 'Напряжённая деятельность (например, быстрая ходьба, выполнение упражнений, взбегание по&nbsp;лестнице, занятия спортом)' },
                    2: { name: 'Спокойная деятельность (например, прогулки, домашняя работа, работа в саду, покупки, подъём по лестнице)' },
                    3: { name: 'Общение (например, разговоры, игры с домашними животными и детьми, посещение друзей и родственников)' },
                    4: { name: 'Деятельность, связанная с работой/учебой* (выполнение служебных/учебных заданий)' },
                    5: { name: 'Сон' },
                },
                variants: [
                    { name: 'Полностью ограничен(а)', value: 1 },
                    { name: 'Чрезвычайно ограничен(а)', value: 2 },
                    { name: 'Очень ограничен(а)', value: 3 },
                    { name: 'Умеренно ограничен(а)', value: 4 },
                    { name: 'Немного ограничен(а)', value: 5 },
                    { name: 'Чуть-чуть ограничен(а)', value: 6 },
                    { name: 'Совсем не ограничен(а)', value: 7 },
                ],
                footnote: '*Если Вы не работаете, то выполнение Ваших повседневных обязанностей.',
            },
            {
                name: 'Насколько сильные дискомфорт или огорчение вы&nbsp;испытывали за&nbsp;последние 2&nbsp;недели?',
                questions: {
                    6: { name: 'Насколько сильные дискомфорт или огорчение вы&nbsp;испытывали за&nbsp;последние 2&nbsp;недели из-за скованности в&nbsp;груди?' },
                },
                variants: [
                    { name: 'Чрезвычайно сильные', value: 1 },
                    { name: 'Очень сильные', value: 2 },
                    { name: 'Сильные', value: 3 },
                    { name: 'Умеренные', value: 4 },
                    { name: 'Слабые', value: 5 },
                    { name: 'Очень слабые', value: 6 },
                    { name: 'Не испытывал(а)', value: 7 },
                ],
            },
            {
                name: 'В&nbsp;целом, как часто за&nbsp;последние 2&nbsp;недели&nbsp;вы:',
                questions: {
                    7: { name: 'Чувствовали озабоченность по поводу того, что у вас астма?' },
                    8: { name: 'Чувствовали одышку из-за астмы?' },
                    9: { name: 'Испытывали симптомы астмы из-за воздействия табачного дыма?' },
                    10: { name: 'Чувствовали хрипы в груди?' },
                    11: { name: 'Чувствовали необходимость избегать ситуаций или обстановки из-за табачного дыма?' },
                },
                variants: [
                    { name: 'Всё время', value: 1 },
                    { name: 'Почти всё время', value: 2 },
                    { name: 'Часто', value: 3 },
                    { name: 'Иногда', value: 4 },
                    { name: 'Редко', value: 5 },
                    { name: 'Почти никогда', value: 6 },
                    { name: 'Никогда', value: 7 },
                ],
            },
            {
                name: 'Насколько сильные дискомфорт или огорчение вы&nbsp;испытывали за&nbsp;последние 2&nbsp;недели?',
                questions: {
                    12: { name: 'Насколько сильные дискомфорт или огорчение вы&nbsp;испытывали за&nbsp;последние 2&nbsp;недели из-за кашля?' },
                },
                variants: [
                    { name: 'Чрезвычайно сильные', value: 1 },
                    { name: 'Очень сильные', value: 2 },
                    { name: 'Сильные', value: 3 },
                    { name: 'Умеренные', value: 4 },
                    { name: 'Слабые', value: 5 },
                    { name: 'Очень слабые', value: 6 },
                    { name: 'Не испытывал(а)', value: 7 },
                ],
            },
            {
                name: 'В&nbsp;целом, как часто за&nbsp;последние 2&nbsp;недели&nbsp;вы:',
                questions: {
                    13: { name: 'Чувствовали себя расстроенным (ой) или огорчённым (ой) из-за астмы?' },
                    14: { name: 'Испытывали чувство тяжести в груди?' },
                    15: { name: 'Чувствовали озабоченность в связи с необходимостью использовать лекарства от астмы?' },
                    16: { name: 'Испытывали потребность откашляться?' },
                    17: { name: 'Испытывали симптомы астмы из-за воздействия пыли?' },
                    18: { name: 'Испытывали затруднённый выдох из-за астмы?' },
                    19: { name: 'Чувствовали необходимость избегать ситуаций или обстановки из-за пыли?' },
                    20: { name: 'Просыпались утром с симптомами астмы?' },
                    21: { name: 'Испытывали страх, что у вас нет под рукой лекарств для лечения астмы?' },
                    22: { name: 'Испытывали затруднённое дыхание?' },
                    23: { name: 'Испытывали симптомы астмы из-за влияния погоды или загрязнённого воздуха?' },
                    24: { name: 'Просыпались ночью из-за астмы?' },
                    25: { name: 'Избегали или ограничивали выход на улицу из-за погоды или загрязнённого воздуха?' },
                    26: { name: 'Испытывали симптомы астмы из-за воздействия резких запахов или парфюмерии?' },
                    27: { name: 'Боялись удушья?' },
                    28: { name: 'Чувствовали необходимость избегать ситуаций или обстановки из-за резких запахов или парфюмерии?' },
                    29: { name: 'Чувствовали, что астма мешает вам хорошо спать ночью?' },
                    30: { name: 'Чувствовали, что дыхание требует больших усилий?' },
                },
                variants: [
                    { name: 'Всё время', value: 1 },
                    { name: 'Почти всё время', value: 2 },
                    { name: 'Часто', value: 3 },
                    { name: 'Иногда', value: 4 },
                    { name: 'Редко', value: 5 },
                    { name: 'Почти никогда', value: 6 },
                    { name: 'Никогда', value: 7 },
                ],
            },
            {
                name: 'Насколько вы&nbsp;были ограничены в&nbsp;течение 2&nbsp;последних недель?',
                questions: {
                    31: { name: 'Вспомните обо всех делах, которыми вам хотелось&nbsp;бы заниматься в&nbsp;течение 2&nbsp;последних недель. Какое количество из&nbsp;них вы&nbsp;не&nbsp;смогли сделать из-за астмы?' },
                },
                variants: [
                    { name: 'Чрезвычайно ограничен(а) – не занимался(ась) большинством видов деятельности', value: 1 },
                    { name: 'Очень ограничен(а)', value: 2 },
                    { name: 'Умеренно ограничен(а) – не занимался(ась) некоторыми видами деятельности', value: 3 },
                    { name: 'Ограничен(а) в незначительной степени', value: 4 },
                    { name: 'Ограничен(а) в очень незначительной степени – не занимался(ась) очень небольшим количеством видов деятельности', value: 5 },
                    { name: 'Почти совсем не ограничен(а)', value: 6 },
                    { name: 'Вовсе не ограничен(а) – занимался(ась) всеми видами деятельности, которыми хотел(а)', value: 7 },
                ],
            },
            {
                questions: {
                    32: { name: 'В&nbsp;целом, из&nbsp;всего, что вы&nbsp;делали на&nbsp;протяжении последних двух недель, насколько вы&nbsp;были ограничены из-за астмы?' },
                },
                variants: [
                    { name: 'Полностью ограничен(а)', value: 1 },
                    { name: 'Чрезвычайно ограничен(а)', value: 2 },
                    { name: 'Очень ограничен(а)', value: 3 },
                    { name: 'Умеренно ограничен(а)', value: 4 },
                    { name: 'Немного ограничен(а)', value: 5 },
                    { name: 'Чуть-чуть ограничен(а)', value: 6 },
                    { name: 'Совсем не ограничен(а)', value: 7 },
                ],
            },
        ],
    }),

    validations: {
        blocks: {
            $each: {
                questions: {
                    $each: {
                        value: { required },
                    },
                },
            },
        },
    },

    created() {
        this.blocks.forEach(block => {
            for (let id in block.questions) {
                this.$set(block.questions[id], 'value', null);
            }
        });
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = this.blocks.reduce((sum, block) => {
                return sum + Object.values(block.questions).reduce((sumB, question) => sumB + question.value, 0);
            }, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-aqlq {
    .result-field {
        width: rem(75px);
    }

    .block__name {
        font-weight: 700;
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
