<template lang="pug">
    .questionnaire-acq5
        app-text-field.result-field(
            v-model="result"
            label="ACQ5"
            is-float-number
            :max="6"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет ACQ-5

            .block
                .block-question(v-for="(question, id) in questions")
                    .block-question__name {{ question.name }}
                    .app-choice-group
                        app-choice(
                            v-for="answer in question.answers"
                            v-model="question.value"
                            type="radio"
                            :label="answer.name"
                            :radio-value="answer.value"
                            :error="$v.$dirty && $v.questions.$each.$iter[id].value.$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") ACQ-5 = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import toFixed from '@/js/utils/toFixed';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-acq5',

    mixins: [questionnaireMixin],

    data: () => ({
        questions: {
            1: {
                name: 'В среднем, как часто за последнюю неделю Вы просыпались ночью из-за астмы?',
                answers: [
                    { name: 'Никогда', value: 0 },
                    { name: 'Очень редко', value: 1 },
                    { name: 'Редко', value: 2 },
                    { name: 'Несколько раз', value: 3 },
                    { name: 'Много раз', value: 4 },
                    { name: 'Очень много раз', value: 5 },
                    { name: 'Не мог(-ла) спать из-за астмы', value: 6 },
                ],
                value: null,
            },
            2: {
                name: 'В среднем, насколько сильные были симптомы астмы, когда Вы просыпались утром в течение последней недели?',
                answers: [
                    { name: 'Симптомов не было', value: 0 },
                    { name: 'Очень слабые симптомы', value: 1 },
                    { name: 'Слабые симптомы', value: 2 },
                    { name: 'Умеренные симптомы', value: 3 },
                    { name: 'Довольно сильные симптомы', value: 4 },
                    { name: 'Сильные симптомы', value: 5 },
                    { name: 'Очень сильные симптомы', value: 6 },
                ],
                value: null,
            },
            3: {
                name: 'В целом, насколько Вы были ограничены в своих профессиональных и повседневных занятиях из-за астмы в течение последней недели?',
                answers: [
                    { name: 'Совсем не ограничен (-а)', value: 0 },
                    { name: 'Чуть-чуть ограничен (-а)', value: 1 },
                    { name: 'Немного ограничен (-а)', value: 2 },
                    { name: 'Умеренно ограничен (-а)', value: 3 },
                    { name: 'Очень ограничен (-а)', value: 4 },
                    { name: 'Чрезвычайно ограничен (-а)', value: 5 },
                    { name: 'Полностью ограничен (-а)', value: 6 },
                ],
                value: null,
            },
            4: {
                name: 'В целом, была ли у Вас одышка из-за астмы в течение последней недели?',
                answers: [
                    { name: 'Одышки не было', value: 0 },
                    { name: 'Очень небольшая', value: 1 },
                    { name: 'Небольшая', value: 2 },
                    { name: 'Умеренная', value: 3 },
                    { name: 'Довольно сильная', value: 4 },
                    { name: 'Сильная', value: 5 },
                    { name: 'Очень сильная', value: 6 },
                ],
                value: null,
            },
            5: {
                name: 'В целом, какую часть времени в течение последней недели у Вас были хрипы в груди?',
                answers: [
                    { name: 'Никогда', value: 0 },
                    { name: 'Очень редко', value: 1 },
                    { name: 'Редко', value: 2 },
                    { name: 'Иногда', value: 3 },
                    { name: 'Значительную часть времени', value: 4 },
                    { name: 'Подавляющую часть времени', value: 5 },
                    { name: 'Все время', value: 6 },
                ],
                value: null,
            },
        },
    }),

    validations: {
        questions: {
            $each: {
                value: { required },
            },
        },
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = toFixed(Object.values(this.questions).reduce((sum, question) => sum + question.value, 0) / 5, 1);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-acq5 {
    .result-field {
        width: rem(80px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
