<template lang="pug">
    .localization-edema-block(:class="{'localization-edema-block--re-contact': isReContact, 'localization-edema-block--addition-only': additionOnly}")
        .localization-edema-block__row(
            v-for="(item, index) in localizations"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.localization-edema-block__date(
                    v-model="item.date"
                    label="Дата"
                    :disabled-dates="disabledDates"
                    :error="getFieldError(item, index, 'date')"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-for="checkboxItem in item.list"
                        v-model="checkboxItem.value"
                        :label="checkboxItem.name"
                        type="checkbox"
                        :error="getFieldError(item, index, 'list')"
                    )
                .for-large-screens
                    app-text-field.localization-edema-block__other(
                        v-if="item.list.other.value"
                        v-model="item.other"
                        label="Другое"
                        :error="getFieldError(item, index, 'other')"
                    )
                app-dropper.forDesktop(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropLocalization(index)"
                )
            .row
                .for-small-screens
                    app-text-field.localization-edema-block__other(
                        v-if="item.list.other.value"
                        v-model="item.other"
                        label="Другое"
                        :error="getFieldError(item, index, 'other')"
                    )
                app-text-field.localization-edema-block__edema-count(
                    v-model="item.edemaCount"
                    label="Количество отеков в месяц"
                    is-natural-number
                )
                app-text-field.localization-edema-block__edema-duration(
                    v-model="item.edemaDuration"
                    label="Длительность отеков"
                )
                app-dropper.forMobile_or_p(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropLocalization(index)"
                )
            hr.narrow(v-if="index < localizations.length - 1")
        .br
        app-button(
            plus
            @click="addNewLocalization"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'localization-edema-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        localizations: [],
    }),

    validations: {
        localizations: {
            $each: {
                date: { required },
                list: {
                    required(value) {
                        return Object.values(value).some(l => l.value);
                    },
                },
                other: {
                    required(value, item) {
                        return value || !item.list.other.value;
                    },
                },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.localizations.map(e => e.date);
        },
    },

    watch: {
        localizations(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.localizations = this.value;
        });
    },

    methods: {
        addNewLocalization() {
            this.localizations.push({
                id: uniqueId(),
                date: '',
                list: {
                    lips: {
                        name: 'Губы',
                        value: false,
                    },
                    tongue: {
                        name: 'Язык',
                        value: false,
                    },
                    pharynx: {
                        name: 'Глотка',
                        value: false,
                    },
                    larynx: {
                        name: 'Гортань',
                        value: false,
                    },
                    face_area: {
                        name: 'Область лица',
                        value: false,
                    },
                    genitals: {
                        name: 'Половые органы',
                        value: false,
                    },
                    hands_and_feets: {
                        name: 'Кисти рук / стопы',
                        value: false,
                    },
                    other: {
                        name: 'Другое',
                        value: false,
                    },
                },
                other: '',
                edemaCount: '',
                edemaDuration: '',
            });
            this.$v.$reset();
        },

        dropLocalization(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.localizations = this.localizations.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.localizations.$each.$iter[index].$dirty && !this.$v.localizations.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.localizations.map(d => ({
                date_at: moment(d.date).format('YYYY-MM-DD'),
                lips: +d.list.lips.value,
                tongue: +d.list.tongue.value,
                pharynx: +d.list.pharynx.value,
                larynx: +d.list.larynx.value,
                face_area: +d.list.face_area.value,
                genitals: +d.list.genitals.value,
                hands_and_feets: +d.list.hands_and_feets.value,
                other: d.list.other.value ? d.other : '',
                edema_count: d.edemaCount,
                edema_duration: d.edemaDuration,
            }));
        },

        getFormattedSavedFields(localizations) {
            let fields = localizations.map(d => ({
                id: d.id,
                date: +moment(d.date_at),
                list: {
                    lips: {
                        name: 'Губы',
                        value: d.lips,
                    },
                    tongue: {
                        name: 'Язык',
                        value: d.tongue,
                    },
                    pharynx: {
                        name: 'Глотка',
                        value: d.pharynx,
                    },
                    larynx: {
                        name: 'Гортань',
                        value: d.larynx,
                    },
                    face_area: {
                        name: 'Область лица',
                        value: d.face_area,
                    },
                    genitals: {
                        name: 'Половые органы',
                        value: d.genitals,
                    },
                    hands_and_feets: {
                        name: 'Кисти рук / стопы',
                        value: d.hands_and_feets,
                    },
                    other: {
                        name: 'Другое',
                        value: d.other,
                    },
                },
                other: d.other || '',
                edemaCount: d.edema_count || '',
                edemaDuration: d.edema_duration || '',
                old: true,
            }));
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },

};
</script>

<style lang="scss">
.localization-edema-block {
    .localization-edema-block {
        &__date {
            width: rem(168px);
        }

        &__other,
        &__edema-duration {
            width: rem(228px);
        }

        &__edema-count {
            width: rem(240px);
        }
    }

    .app-choice-group {
        margin-right: 0;
    }

    &--re-contact {
        .localization-edema-block__row.old {
            display: none;
        }
    }

    &--addition-only {
        .localization-edema-block__row.old {
            .app-datepicker, .app-choice, .app-text-field {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    .for-small-screens {
        display: none;
    }

    @media all and (max-width: 1440px) {
        .for-large-screens {
            display: none;
        }

        .for-small-screens {
            display: block;
        }
    }

    @include mobile_or_P {
        .localization-edema-block {
            &__date,
            &__other,
            &__edema-count,
            &__edema-duration {
                width: 100%;
            }
        }

        .app-choice-group {
            flex-direction: column;
            align-items: flex-start;

            width: 100%;

            .app-choice {
                margin-bottom: 1.5rem;
            }
        }
    }
}
</style>
