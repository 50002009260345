<template lang="pug">
    component(
        :is="tag"
        class="app-button"
        :class="classes"
        v-bind="attributes"
        v-on="listeners"
    )
        plus-icon.app-button__plus
        slot
        loader.app-button__loader
</template>

<script>
import plusIcon from '@/assets/images/icons/plus.svg';
import loader from '@/assets/images/loader.svg';

export default {
    name: 'app-button',

    components: {
        loader,
        plusIcon,
    },

    props: {
        tag: {
            type: String,
            default: 'button',
        },

        loading: {
            type: Boolean,
            required: false,
            default: false,
        },

        disabled: {
            type: null,
            required: false,
            default: false,
        },

        large: {
            type: Boolean,
            required: false,
            default: false,
        },

        color: {
            type: String,
            required: false,
            default: '',
        },

        plus: {
            type: Boolean,
            required: false,
            default: false,
        },

        center: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'app-button--loading': this.loading,
                'app-button--disabled': this.disabled,
                'app-button--large': this.large,
                'app-button--white': this.color === 'white',
                'app-button--red': this.color === 'red',
                'app-button--plus': this.plus,
                'app-button--center': this.center,
            };
        },

        attributes() {
            return this.$attrs;
        },

        listeners() {
            return Object.assign(
                {},
                this.$listeners,
            );
        },
    },
};
</script>
