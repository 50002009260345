<template lang="pug">
    .exacerbations-block(:class="classes")
        .exacerbations-block__rows
            .exacerbations-block__row(
                v-for="(item, index) in exacerbations"
                :key="item.id"
                :class="{old: item.old}"
            )
                .row
                    app-select.exacerbations-block--name(
                        v-if="withIllness"
                        v-model="item.illness"
                        :options="illnessesList"
                        searchable
                        :internal-search="false"
                        @search-change="fetchIllnesses"
                        noOptionsText="Начните вводить значение"
                        label="Заболевание (код или название)"
                        :error="getFieldError(item, index, 'illness')"
                    )
                    app-datepicker.exacerbations-block--date(
                        v-model="item.date"
                        :inaccurate="item.dateNotDetermined"
                        label="Дата обострения"
                        :disabled-dates="disabledDates"
                        :error="getFieldError(item, index, 'date')"
                    )
                    .app-choice-group.app-choice-group--center
                        app-choice(
                            v-model="item.dateNotDetermined"
                            type="checkbox"
                            label="Неточная дата"
                        )
                    .app-choice-group
                        .app-choice-group__label Госпитализация
                        .app-choice-group__list
                            app-choice(
                                v-model="item.hospitalization"
                                type="radio"
                                label="Нет"
                                radio-value="HOSPITALIZATION_NO"
                            )
                            app-choice(
                                v-model="item.hospitalization"
                                type="radio"
                                label="Да"
                                radio-value="HOSPITALIZATION_YES"
                            )
                            app-choice(
                                v-model="item.hospitalization"
                                type="radio"
                                label="Да, в ОРИТ"
                                radio-value="HOSPITALIZATION_YES_ORIT"
                            )
                    template(v-if="!withIllness")
                        app-text-field.exacerbations-block--hospitalizationDays(
                            v-if="item.hospitalization !== 'HOSPITALIZATION_NO'"
                            v-model="item.hospitalizationDays"
                            label="Дней госпитализации"
                            is-number
                            :error="getFieldError(item, index, 'hospitalizationDays')"
                        )
                        app-text-field.exacerbations-block--oritDays(
                            v-if="item.hospitalization === 'HOSPITALIZATION_YES_ORIT'"
                            v-model="item.oritDays"
                            label="Дней в ОРИТ"
                            is-number
                            :error="getFieldError(item, index, 'oritDays')"
                        )
                        app-text-field.exacerbations-block--lpu(
                            v-model="item.lpu"
                            label="ЛПУ"
                        )
                    app-dropper.forDesktop(
                        :class="{hidden: (isReContact || additionOnly) && item.old}"
                        @click="dropExacerbations(index)"
                    )
                .row.wrap
                    template(v-if="withIllness")
                        app-text-field.exacerbations-block--hospitalizationDays(
                            v-if="item.hospitalization !== 'HOSPITALIZATION_NO'"
                            v-model="item.hospitalizationDays"
                            label="Дней госпитализации"
                            is-number
                            :error="getFieldError(item, index, 'hospitalizationDays')"
                        )
                        app-text-field.exacerbations-block--oritDays(
                            v-if="item.hospitalization === 'HOSPITALIZATION_YES_ORIT'"
                            v-model="item.oritDays"
                            label="Дней в ОРИТ"
                            is-number
                            :error="getFieldError(item, index, 'oritDays')"
                        )
                        app-text-field.exacerbations-block--lpu(
                            v-model="item.lpu"
                            label="ЛПУ"
                        )
                    .app-choice-group
                        .app-choice-group__label Применение СГКС
                        .app-choice-group__list
                            app-choice(
                                v-model="item.sgks"
                                type="radio"
                                label="Нет"
                                radio-value="0"
                            )
                            app-choice(
                                v-model="item.sgks"
                                type="radio"
                                label="Да"
                                radio-value="1"
                            )
                    app-text-field.exacerbations-block--sgksDays(
                        v-if="+item.sgks"
                        v-model="item.sgksDays"
                        label="Дней применения СГКС"
                        is-number
                        :error="getFieldError(item, index, 'sgksDays')"
                    )
                    template(v-if="nebulizer")
                        .app-choice-group.exacerbations-block--nebulizerTherapy
                            .app-choice-group__label Небулайзерная терапия
                            .app-choice-group__list
                                app-choice(
                                    v-model="item.nebulizerTherapy"
                                    type="radio"
                                    label="Нет"
                                    radio-value="0"
                                )
                                app-choice(
                                    v-model="item.nebulizerTherapy"
                                    type="radio"
                                    label="Да"
                                    radio-value="1"
                                )
                        app-text-field.exacerbations-block--nebulizerTherapyDays(
                            v-if="+item.nebulizerTherapy"
                            v-model="item.nebulizerTherapyDays"
                            label="Дней небулайзерной терапии"
                            is-number
                            :error="getFieldError(item, index, 'nebulizerTherapyDays')"
                        )
                    template(v-if="withCause")
                        app-select.exacerbations-block--cause(
                            v-model="item.cause"
                            :options="causesExacerbations"
                            label="Причина обострения"
                            :error="getFieldError(item, index, 'cause')"
                        )
                        app-text-field.exacerbations-block--causeCustom(
                            v-if="item.cause && item.cause.name.toLowerCase() === 'другая'"
                            v-model="item.causeCustom"
                            :error="getFieldError(item, index, 'causeCustom')"
                        )
                    app-dropper.forMobile_or_p(
                        :class="{hidden: (isReContact || additionOnly) && item.old}"
                        @click="dropExacerbations(index)"
                    )
                hr
        .br
        app-button(
            plus
            @click="addNewExacerbations"
        ) Добавить обострение
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { uniqueId } from 'lodash';
import moment from 'moment';
import illnessesList from '@/mixins/illnessesList';

export default {
    name: 'exacerbations-block',

    mixins: [illnessesList],

    props: {
        value: {
            type: Array,
            required: true,
        },

        nebulizer: {
            type: Boolean,
            default: false,
        },

        withIllness: {
            type: Boolean,
            default: false,
        },

        withCause: {
            type: Boolean,
            default: false,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        exacerbations: [],
    }),

    validations: {
        exacerbations: {
            $each: {
                illness: {
                    required(value) {
                        return value || !this.withIllness;
                    },
                },
                cause: {
                    required(value) {
                        return value || !this.withCause;
                    },
                },
                causeCustom: {
                    required(value, item) {
                        return value || !this.withCause || item.cause?.name.toLowerCase() !== 'другая';
                    },
                },
                date: { required },
                hospitalizationDays: {
                    required(value, item) {
                        return value || item.hospitalization === 'HOSPITALIZATION_NO';
                    },
                },
                oritDays: {
                    required(value, item) {
                        return value || item.hospitalization !== 'HOSPITALIZATION_YES_ORIT';
                    },
                },

                sgksDays: {
                    required(value, item) {
                        return value || !+item.sgks;
                    },
                },
                nebulizerTherapyDays: {
                    required(value, item) {
                        return value || !+item.nebulizerTherapy || !this.nebulizer;
                    },
                },
            },
        },
    },

    computed: {
        ...mapGetters([
            'causesExacerbations',
        ]),

        disabledDates() {
            return this.exacerbations.map(e => e.dateNotDetermined ? 0 : e.date);
        },

        classes() {
            return {
                'exacerbations-block--re-contact': this.isReContact,
                'exacerbations-block--addition-only': this.additionOnly,
            };
        },
    },

    watch: {
        exacerbations(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.exacerbations = this.value;
        });
    },

    methods: {
        addNewExacerbations() {
            this.exacerbations.push({
                id: uniqueId(),
                illness: '',
                cause: '',
                causeCustom: '',
                date: '',
                dateNotDetermined: false,
                hospitalization: 'HOSPITALIZATION_NO',
                hospitalizationDays: '',
                oritDays: '',
                lpu: '',

                sgks: '0',
                sgksDays: '',
                nebulizerTherapy: '0',
                nebulizerTherapyDays: '',
            });
            this.$v.$reset();
        },

        dropExacerbations(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить обострение?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.exacerbations = this.exacerbations.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.exacerbations.$each.$iter[index].$dirty && !this.$v.exacerbations.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.exacerbations.map(e => {
                const obj = {
                    start_at: moment(e.date).format('YYYY-MM-DD'),
                    start_at_inaccurate: +e.dateNotDetermined,
                    hospitalization: e.hospitalization,

                    sgks: e.sgks,
                };
                if (e.hospitalization !== 'HOSPITALIZATION_NO') {
                    obj.hospitalization_days = e.hospitalizationDays;
                }
                if (e.hospitalization === 'HOSPITALIZATION_YES_ORIT') {
                    obj.orit_days = e.oritDays;
                }
                if (e.lpu) {
                    obj.lpu = e.lpu;
                }
                if (+e.sgks) {
                    obj.sgks_days = e.sgksDays;
                }
                if (+e.nebulizerTherapy && this.nebulizer) {
                    obj.nebulizer_therapy = e.nebulizerTherapy;
                    obj.nebulizer_therapy_days = e.nebulizerTherapyDays;
                }
                if (this.withIllness) {
                    obj.illnesses_id = e.illness.id;
                }
                if (this.withCause) {
                    obj.cause_of_exacerbation_id = e.cause.id;
                    obj.cause_of_exacerbation_custom = e.causeCustom;
                }
                return obj;
            });
        },

        getFormattedSavedFields(exacerbations) {
            let fields = exacerbations.map(e => {
                const obj = {
                    id: e.id,
                    date: +moment(e.start_at),
                    dateNotDetermined: e.start_at_inaccurate,
                    hospitalization: e.hospitalization,
                    hospitalizationDays: e.hospitalization_days,
                    oritDays: e.orit_days,
                    lpu: e.lpu || '',

                    sgks: e.sgks,
                    sgksDays: e.sgks_days,

                    old: true,
                };
                if (this.nebulizer) {
                    obj.nebulizerTherapy = e.nebulizer_therapy;
                    obj.nebulizerTherapyDays = e.nebulizer_therapy_days;
                }
                if (this.withIllness) {
                    obj.illness = {
                        ...e.illnesses,
                        name: `${e.illnesses.code} - ${e.illnesses.name}`,
                    };
                }
                if (this.withCause) {
                    obj.cause = e.cause_of_exacerbation;
                    obj.causeCustom = e.cause_of_exacerbation_custom;
                }
                return obj;
            });
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.exacerbations-block {

    &__row:last-of-type hr {
        display: none;
    }

    .exacerbations-block {
        &--name {
            width: rem(467px);
        }

        &--cause {
            width: rem(240px);
        }

        &--causeCustom {
            width: rem(250px);
        }

        &--date {
            width: rem(215px);
        }

        &--hospitalizationDays {
            width: rem(192px);
        }

        &--oritDays {
            width: rem(128px);
        }

        &--lpu {
            width: rem(231px);
        }

        &--sgksDays {
            width: rem(209px);
        }

        &--nebulizerTherapyDays {
            width: rem(253px);
        }
    }

    &--re-contact {
        .exacerbations-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 2rem 1rem 0;

            hr {
                margin-bottom: 0;
            }

            &:first-child {
                padding-top: 1rem;
            }

            &.old {
                background: $light-blue;

                .app-select, .app-text-field, .app-datepicker, .app-choice {
                    opacity: 0.5;

                    pointer-events: none;
                }
            }
        }
    }

    &--addition-only {
        .exacerbations-block__row.old {
            .app-select, .app-text-field, .app-datepicker, .app-choice {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .exacerbations-block {
            &--name,
            &--date,
            &--cause,
            &--causeCustom,
            &--hospitalizationDays,
            &--sgksDays,
            &--nebulizerTherapy,
            &--nebulizerTherapyDays {
                width: 100%;
            }

            &--oritDays, &--lpu {
                width: rem(153px);
                margin-right: rem(20px);
            }

            &--oritDays ~ .exacerbations-block--lpu {
                margin-right: 0;
            }
        }
    }
}
</style>
