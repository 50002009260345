<template lang="pug">
    .hives
        h4 Диагноз
        diagnoses-block(
            v-model="diagnoses"
            ref="diagnoses"
            required
            :addition-only="additionOnly"
        )

        .br
        .row
            .app-choice-group
                .app-choice-group__label Вид хронической крапивницы
                .app-choice-group__list
                    app-choice(
                        v-for="item in hivesTypes"
                        v-model="hivesType"
                        type="radio"
                        unchecketable
                        :label="item.name"
                        :radio-value="item"
                        :error="$v.$dirty && !$v.hivesType.required"
                    )
                    app-choice(
                        v-model="angiooteki.openBlock"
                        type="checkbox"
                        label="Ангиоотеки"
                        :error="$v.$dirty && !$v.hivesType.required"
                    )

        div(v-show="angiooteki.openBlock")
            .row
                app-datepicker.hives--angiootekiDate(
                    v-model="angiooteki.debut"
                    :inaccurate="angiooteki.debutInaccurate"
                    label="Дебют ангиоотеков"
                    :disabled="additionOnly"
                    :error="$v.$dirty && !$v.angiooteki.debut.required ? 'Заполните поле' : ''"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="angiooteki.debutInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="additionOnly"
                    )
                app-datepicker.hives--angiootekiDate(
                    v-model="angiooteki.lastEdema"
                    label="Последний отек"
                    :disabled="additionOnly"
                    :error="$v.$dirty && !$v.angiooteki.lastEdema.required ? 'Заполните поле' : ''"
                )
            br

        hives-induced-types-block(
            v-model="hivesInducedTypes"
            ref="hivesInducedTypes"
            :hives-type="hivesType"
            :addition-only="additionOnly"
        )

        div(v-show="angiooteki.openBlock")
            h4 Локализация отеков
            localization-edema-block(
                v-model="angiooteki.localizations"
                ref="angiootekiLocalizations"
                :addition-only="additionOnly"
            )

            h4 Опросники (Ангиоотеки)
            questionnaires-angiooteki(
                v-model="angiooteki.questionnaires"
                ref="angiootekiQuestionnaires"
                :addition-only="additionOnly"
            )

        h4 Таргентная терапия
        target-therapy-block(
            v-model="targetTherapy"
            ref="targetTherapy"
            :addition-only="additionOnly"
        )

        h4 Базисная терапия
        therapy-block(
            v-model="basicTherapy"
            ref="basicTherapy"
            :addition-only="additionOnly"
        )

        h4 Обострения
        exacerbations-block(
            v-model="exacerbations"
            ref="exacerbations"
            with-cause
            :addition-only="additionOnly"
        )

        h4 Нежелательные эффекты
        unwanted-effects-block(
            v-model="unwantedEffects"
            ref="unwantedEffects"
            :addition-only="additionOnly"
        )

        h4 Опросники
        questionnaires-hives(
            v-model="questionnairesHives"
            ref="questionnairesHives"
            :addition-only="additionOnly"
            :has-hives="enableHives"
        )

        .row.sticky-buttons-block
            app-button(
                large
                :loading="saveAndContinueLoading"
                @click="saveAndContinue"
            ) Сохранить и продолжить заполнение
            app-button(
                :loading="saveLoading"
                large
                color="white"
                @click="saveAndClose"
            ) Сохранить и закончить заполнение
            app-link.app-link--red.app-link--left-margin(@click="close") Закрыть без сохранения
</template>

<script>
import TherapyBlock from '@/components/pages/patients/blocks/TherapyBlock';
import TargetTherapyBlock from '@/components/pages/patients/blocks/TargetTherapyBlock';
import DiagnosesBlock from '@/components/pages/patients/blocks/DiagnosesBlock';
import ExacerbationsBlock from '@/components/pages/patients/blocks/ExacerbationsBlock';
import UnwantedEffectsBlock from '@/components/pages/patients/blocks/UnwantedEffectsBlock';
import QuestionnairesHives from '@/components/pages/patients/questionnaires/QuestionnairesHives';
import HivesInducedTypesBlock from '@/components/pages/patients/blocks/anamnesis/HivesInducedTypesBlock';
import LocalizationEdemaBlock from '@/components/pages/patients/blocks/anamnesis/LocalizationEdemaBlock';
import QuestionnairesAngiooteki from '@/components/pages/patients/questionnaires/QuestionnairesAngiooteki';

import saving from '@/mixins/saving';
import { HIVES_TYPES } from '@/js/const';
import moment from 'moment';

export default {
    name: 'hives',

    components: {
        TherapyBlock,
        TargetTherapyBlock,
        DiagnosesBlock,
        ExacerbationsBlock,
        UnwantedEffectsBlock,
        QuestionnairesHives,
        HivesInducedTypesBlock,
        LocalizationEdemaBlock,
        QuestionnairesAngiooteki,
    },

    mixins: [saving],

    props: {
        additionOnly: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        diagnoses: [],

        hivesType: null,
        hivesTypes: HIVES_TYPES,
        hivesInducedTypes: null,

        targetTherapy: [],
        basicTherapy: [],
        exacerbations: [],
        unwantedEffects: [],
        questionnairesHives: [],

        angiooteki: {
            openBlock: false,
            debut: '',
            debutInaccurate: false,
            lastEdema: '',
            localizations: [],
            questionnaires: [],
        },
    }),

    validations: {
        hivesType: {
            required() {
                return this.hivesType || this.angiooteki.openBlock;
            },
        },

        angiooteki: {
            debut: { required(value) { return value || value === 0 || !this.angiooteki.openBlock;} },
            lastEdema: { required(value) { return value || value === 0 || !this.angiooteki.openBlock;} },
        },
    },

    mounted() {
        if (this.lastData && !this.hasDataFromLocalStorage) {
            const hives = this.lastData.sicknesses.find(s => s.type === 'URTICARIA_MAIN');
            if (hives) {
                this.diagnoses = this.$refs.diagnoses.getFormattedSavedFields(hives.diagnoses);
                this.hivesType = this.hivesTypes.find(h => h.id === hives.urticaria_option.type) || null;
                this.hivesInducedTypes = this.$refs.hivesInducedTypes.getFormattedSavedFields(hives.urticaria_option);
                this.targetTherapy = this.$refs.targetTherapy.getFormattedSavedFields(hives.target_therapies);
                this.basicTherapy = this.$refs.basicTherapy.getFormattedSavedFields(hives.basis_therapies);
                this.exacerbations = this.$refs.exacerbations.getFormattedSavedFields(hives.exacerbations);
                this.unwantedEffects = this.$refs.unwantedEffects.getFormattedSavedFields(hives.unwanted_effects);
                this.questionnairesHives = this.$refs.questionnairesHives.getFormattedSavedFields(hives.questionnaires);
            }

            const angiooteki = this.lastData.sicknesses.find(s => s.type === 'ANGIOOTEK_URTICARIA_MAIN');
            if (angiooteki) {
                this.angiooteki.openBlock = true;
                this.angiooteki.debut = +moment(angiooteki.diagnoses[0]?.diagnosis_date);
                this.angiooteki.debutInaccurate = angiooteki.diagnoses[0]?.diagnosis_date_inaccurate;
                this.angiooteki.lastEdema = +moment(angiooteki.angiootek_option.last_edema_at);
                this.angiooteki.localizations = this.$refs.angiootekiLocalizations.getFormattedSavedFields(angiooteki.edema_localizations);
                this.angiooteki.questionnaires = this.$refs.angiootekiQuestionnaires.getFormattedSavedFields(angiooteki.questionnaires);
            }
        }
    },

    methods: {
        getFormFields() {
            if (!this.enableHives) {
                return {};
            }
            const obj = {
                urticaria_main: {
                    diagnoses: this.$refs.diagnoses.getFieldsForSave(),
                    ...this.$refs.hivesInducedTypes.getFieldsForSave(),
                    target_therapies: this.$refs.targetTherapy.getFieldsForSave(),
                    basis_therapies: this.$refs.basicTherapy.getFieldsForSave(),
                    exacerbations: this.$refs.exacerbations.getFieldsForSave(),
                    unwanted_effects: this.$refs.unwantedEffects.getFieldsForSave(),
                    questionnaires: this.$refs.questionnairesHives.getFieldsForSave(),
                },
            };
            if (this.hivesType) {
                obj.urticaria_main.type = this.hivesType.id;
            }

            if (this.angiooteki.openBlock) {
                obj.angiootek_urticaria_main = {
                    diagnosis_date: moment(this.angiooteki.debut).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +this.angiooteki.debutInaccurate,
                    last_edema_at: moment(this.angiooteki.lastEdema).format('YYYY-MM-DD'),
                    edema_localizations: this.$refs.angiootekiLocalizations.getFieldsForSave(),
                    questionnaires: this.$refs.angiootekiQuestionnaires.getFieldsForSave(),
                };
            }
            return obj;
        },

        checkValid() {
            if (!this.enableHives) {
                return true;
            }
            if (this.$v.$invalid
                || this.$refs.diagnoses.$v.$invalid
                || this.$refs.hivesInducedTypes.$v.$invalid
                || this.$refs.targetTherapy.$v.$invalid
                || this.$refs.basicTherapy.$v.$invalid
                || this.$refs.exacerbations.$v.$invalid
                || this.$refs.unwantedEffects.$v.$invalid
                || this.$refs.questionnairesHives.$v.$invalid) {
                this.$v.$touch();
                this.$refs.diagnoses.$v.$touch();
                this.$refs.hivesInducedTypes.$v.$touch();
                this.$refs.targetTherapy.$v.$touch();
                this.$refs.basicTherapy.$v.$touch();
                this.$refs.exacerbations.$v.$touch();
                this.$refs.unwantedEffects.$v.$touch();
                this.$refs.questionnairesHives.$v.$touch();
                return false;
            }
            if (this.angiooteki.openBlock) {
                if (this.$refs.angiootekiLocalizations.$v.$invalid || this.$refs.angiootekiQuestionnaires.$v.$invalid) {
                    this.$refs.angiootekiLocalizations.$v.$touch();
                    this.$refs.angiootekiQuestionnaires.$v.$touch();
                    return false;
                }
            }
            return true;
        },
    },
};
</script>

<style lang="scss">
.hives {
    .hives {
        &--angiootekiDate {
            width: rem(220px);
        }
    }

    @include mobile_or_P {
        max-width: 100vmin;
        margin: 0 auto;

        h4 {
            margin-bottom: 1rem;
        }

        .hives {
            &--angiootekiDate {
                width: 100%;
            }
        }
    }
}
</style>
