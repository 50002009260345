<template lang="pug">
    .specific-examinations-dictionary
        .edit-dictionary__top
            .edit-dictionary__name Специфические обследования
            p Данный справочник служит для указания препаратов таргетной терапии. Доза, единицы измерения и кратность могут быть указаны только в соответствии с приведенными в справочнике значениями. Если поле оставлено пустым (например, не указано значение дозы), то пи заполнении карты пациента можно будет указать любое значение.
        dictionary-default-template(
            action-name="fetchSpecificExaminations"
            getter-name="specificExaminations"
            url-part="specific-examinations"
        )
</template>

<script>
import DictionaryDefaultTemplate from '@/components/pages/dictionaries/DictionaryDefaultTemplate';

export default {
    name: 'specific-examinations-dictionary',

    components: {
        DictionaryDefaultTemplate,
    },
};
</script>
