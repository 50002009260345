<template lang="pug">
    .app-datepicker(:class="classes")

        label {{ label }}

        date-picker(
            v-model="state"
            :lang="lang"
            :type="type"
            :format="format"
            v-bind="attributes"
            :clearable="false"
            :disabled-date="disabledDate"
            value-type="timestamp"
            :append-to-body="false"
            :range="range"
            @open="inFocus=true"
            @close="inFocus=false"
            :disabled="disabled"
        )

        calendar-icon.app-datepicker__icon

        .app-datepicker__clear(
            v-if="clearable && stateIsNotEmpty"
            @click="clearAll"
        ) &#10006;

        .app-datepicker__error(v-if="error") {{ error }}
</template>

<script>
import CalendarIcon from '@/assets/images/icons/calendar.svg';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import moment from 'moment';

export default {
    name: 'app-datepicker',

    components: {
        CalendarIcon,
        DatePicker,
    },

    props: {
        value: {
            validator: value => {
                return (
                    typeof value === 'number' ||
                    value === undefined ||
                    value === null ||
                    value === '' ||
                    Array.isArray(value)
                );
            },
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        error: {
            type: [String, Boolean],
            required: false,
            default: '',
        },

        inaccurate: {
            validator: () => true,
            required: false,
            default: false,
        },

        disabled: {
            validator: () => true,
            required: false,
            default: false,
        },

        clearable: {
            type: Boolean,
            default: false,
        },

        range: {
            type: Boolean,
            required: false,
            default: false,
        },

        minDate: {
            type: [Number, String],
            default: null,
        },

        maxDate: {
            type: [Number, String],
            default: null,
        },

        disabledDates: {
            type: Array,
            default: () => [],
        },
    },

    data: () => ({
        inFocus: false,
        months: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        monthsShort: [
            'Январь',
            'Февраль',
            'Март',
            'Апрель',
            'Май',
            'Июнь',
            'Июль',
            'Август',
            'Сентябрь',
            'Октябрь',
            'Ноябрь',
            'Декабрь',
        ],
        weekdays: [
            'Воскресенье',
            'Понедельник',
            'Вторник',
            'Среда',
            'Четверг',
            'Пятница',
            'Суббота',
        ],
        weekdaysShort: ['Вос', 'Пон', 'Втор', 'Сред', 'Чет', 'Пят', 'Суб'],
        weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    }),

    computed: {
        lang() {
            return {
                formatLocale: {
                    months: this.months,
                    monthsShort: this.monthsShort,
                    weekdays: this.weekdays,
                    weekdaysShort: this.weekdaysShort,
                    weekdaysMin: this.weekdaysMin,
                },
                monthFormat: 'MMMM',
            };
        },

        type() {
            return this.inaccurate ? 'month' : 'date';
        },

        format() {
            return this.type === 'month' ? 'MM.YYYY' : 'DD.MM.YYYY';
        },

        state: {
            get() {
                return this.value;
            },

            set(value) {
                if (value && value[0] === null && value[1] === null) {
                    value = null;
                }
                this.$emit('input', value);
            },
        },

        stateIsNotEmpty() {
            return this.state || this.state === 0;
        },

        classes() {
            return {
                'app-datepicker--focus': this.inFocus,
                'app-datepicker--disabled': this.disabled,
                'app-datepicker--no-empty': this.stateIsNotEmpty,
                'app-datepicker--invalid': this.error,
            };
        },

        attributes() {
            return this.$attrs;
        },
    },

    methods: {
        disabledDate(date) {
            if (this.minDate) {
                return moment(date) < moment(this.minDate);
            } else if (this.maxDate) {
                return moment(date) > moment(this.maxDate);
            } else if (this.disabledDates.length) {
                return this.disabledDates.some(
                    d => moment(d).format('DD.MM.YYYY') === moment(date).format('DD.MM.YYYY'),
                );
            }
            return false;
        },

        clearAll() {
            this.state = '';
        },
    },
};
</script>
