<template lang="pug">
    .app-link(:class="classes")
        component(
            :is="tag"
            class="app-link__self"
            v-bind="attributes"
            v-on="listeners"
        )
            slot
</template>

<script>

export default {
    name: 'app-link',

    props: {
        tag: {
            type: String,
            default: 'a',
        },

        disabled: {
            validator: () => true,
            required: false,
            default: false,
        },

        noPointerEvents: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'app-link--disabled': this.disabled,
                'app-link--no-pointer-events': this.noPointerEvents,
            };
        },

        attributes() {
            return this.$attrs;
        },

        listeners() {
            return Object.assign(
                {},
                this.$listeners,
            );
        },
    },
};
</script>
