<template lang="pug">
    .allergyanamnesis
        .row
            .app-choice-group
                .app-choice-group__label Наследственность по аллергопатологии
                .app-choice-group__list
                    app-choice(
                        v-model="inheritanceAllergic"
                        type="radio"
                        label="Нет"
                        :radio-value="0"
                        :disabled="additionOnly"
                    )
                    app-choice(
                        v-model="inheritanceAllergic"
                        type="radio"
                        label="Да"
                        :radio-value="1"
                        :disabled="additionOnly"
                    )
        div(v-show="inheritanceAllergic")
            h4 Родственники, которые страдают <br class="forMobile_or_p">аллергическими заболеваниями
            sick-relatives-block(
                v-model="sickRelativesAllergic"
                ref="sickRelativesAllergic"
                type="TYPE_DEFAULT"
                required
                :addition-only="additionOnly"
            )

        .row
            .app-choice-group
                .app-choice-group__label Наследственность по аутоиммунным заболеваниям
                .app-choice-group__list
                    app-choice(
                        v-model="inheritanceAutoimmune"
                        type="radio"
                        label="Нет"
                        :radio-value="0"
                        :disabled="additionOnly"
                    )
                    app-choice(
                        v-model="inheritanceAutoimmune"
                        type="radio"
                        label="Да"
                        :radio-value="1"
                        :disabled="additionOnly"
                    )
        div(v-show="inheritanceAutoimmune")
            h4 Родственники, которые страдают <br class="forMobile_or_p">аутоиммунными заболеваниями
            sick-relatives-block(
                v-model="sickRelativesAutoimmune"
                ref="sickRelativesAutoimmune"
                type="TYPE_AUTOIMMUNE"
                required
                :addition-only="additionOnly"
            )


        h4 Сенсибилизация
        .row.column
            app-choice.allergyanamnesis--sencibility(
                v-for="(item, id) in sensitization"
                :key="id"
                v-model="item.value"
                type="checkbox"
                :label="item.name"
                :disabled="additionOnly"
            )

        h4 Аллергены и проявления
        allergens-block(
            v-model="allergens"
            ref="allergens"
            :addition-only="additionOnly"
        )

        h4 НПВС и проявления
        npvs-block(
            v-model="npvs"
            ref="npvs"
            :addition-only="additionOnly"
        )

        h4 Лекарственные препараты и проявления
        medicines-block(
            v-model="medicines"
            ref="medicines"
            :addition-only="additionOnly"
        )

        h4 Пищевая аллергия
        food-allergies-block(
            v-model="foodAllergies"
            ref="foodAllergies"
            :addition-only="additionOnly"
        )

        h4 Другое
        .row
            app-text-field(
                v-model="other"
                label="Другое"
                is-textarea
            )

        .row.sticky-buttons-block
            app-button(
                large
                :loading="saveAndContinueLoading"
                @click="saveAndContinue"
            ) Сохранить и продолжить заполнение
            app-button(
                :loading="saveLoading"
                large
                color="white"
                @click="saveAndClose"
            ) Сохранить и закончить заполнение
            app-link.app-link--red.app-link--left-margin(@click="close") Закрыть без сохранения
</template>

<script>
import SickRelativesBlock from '@/components/pages/patients/blocks/allergyanamnesis/SickRelativesBlock';
import AllergensBlock from '@/components/pages/patients/blocks/allergyanamnesis/AllergensBlock';
import NpvsBlock from '@/components/pages/patients/blocks/allergyanamnesis/NpvsBlock';
import MedicinesBlock from '@/components/pages/patients/blocks/allergyanamnesis/MedicinesBlock';
import FoodAllergiesBlock from '@/components/pages/patients/blocks/allergyanamnesis/FoodAllergiesBlock';
import saving from '@/mixins/saving';

export default {
    name: 'allergyanamnesis',

    components: {
        SickRelativesBlock,
        AllergensBlock,
        NpvsBlock,
        MedicinesBlock,
        FoodAllergiesBlock,
    },

    mixins: [saving],

    props: {
        additionOnly: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        inheritanceAllergic: 0,
        sickRelativesAllergic: [],
        inheritanceAutoimmune: 0,
        sickRelativesAutoimmune: [],

        sensitization: {
            sensitiveEveryday: {
                name: 'Сенсибилизация к бытовым&nbsp;аллергенам',
                value: false,
            },
            sensitiveEpiderm: {
                name: 'Сенсибилизация к эпидермальным аллергенам',
                value: false,
            },
            sensitivePollen: {
                name: 'Сенсибилизация к пыльцевым&nbsp;аллергенам',
                value: false,
            },
            sensitiveMushrooms: {
                name: 'Сенсибилизация к аллергенам непатогенных плесневых грибов',
                value: false,
            },
            sensitiveFood: {
                name: 'Сенсибилизация к пищевым&nbsp;аллергенам',
                value: false,
            },
        },
        allergens: [],
        npvs: [],
        medicines: [],
        foodAllergies: [],
        other: '',
    }),

    mounted() {
        if (this.lastData && !this.hasDataFromLocalStorage) {
            const allergoanamnez = this.lastData.allergoanamnez;
            if (allergoanamnez) {
                this.inheritanceAllergic = +allergoanamnez.sick_relatives.some(r => r.type === 'TYPE_DEFAULT');
                this.sickRelativesAllergic = this.$refs.sickRelativesAllergic.getFormattedSavedFields(allergoanamnez.sick_relatives.filter(r => r.type === 'TYPE_DEFAULT'));
                this.inheritanceAutoimmune = +allergoanamnez.sick_relatives.some(r => r.type === 'TYPE_AUTOIMMUNE');
                this.sickRelativesAutoimmune = this.$refs.sickRelativesAutoimmune.getFormattedSavedFields(allergoanamnez.sick_relatives.filter(r => r.type === 'TYPE_AUTOIMMUNE'));

                this.sensitization.sensitiveEveryday.value = allergoanamnez.sensitive_everyday;
                this.sensitization.sensitiveEveryday.old = Boolean(allergoanamnez.sensitive_everyday);
                this.sensitization.sensitiveEveryday.value = allergoanamnez.sensitive_everyday;
                this.sensitization.sensitiveEveryday.old = Boolean(allergoanamnez.sensitive_everyday);
                this.sensitization.sensitiveEpiderm.value = allergoanamnez.sensitive_epiderm;
                this.sensitization.sensitiveEpiderm.old = Boolean(allergoanamnez.sensitive_epiderm);
                this.sensitization.sensitivePollen.value = allergoanamnez.sensitive_pollen;
                this.sensitization.sensitivePollen.old = Boolean(allergoanamnez.sensitive_pollen);
                this.sensitization.sensitiveMushrooms.value = allergoanamnez.sensitive_mushrooms;
                this.sensitization.sensitiveMushrooms.old = Boolean(allergoanamnez.sensitive_mushrooms);
                this.sensitization.sensitiveFood.value = allergoanamnez.sensitive_food;
                this.sensitization.sensitiveFood.old = Boolean(allergoanamnez.sensitive_food);

                this.other = allergoanamnez.other || '';

                this.allergens = this.$refs.allergens.getFormattedSavedFields(allergoanamnez.allergens);

                this.npvs = this.$refs.npvs.getFormattedSavedFields(allergoanamnez.npvs);

                this.medicines = this.$refs.medicines.getFormattedSavedFields(allergoanamnez.medicines);

                this.foodAllergies = this.$refs.foodAllergies.getFormattedSavedFields(allergoanamnez.food_allergies);
            }
        }
    },

    methods: {
        getFormFields() {
            const obj = {
                allergoanamnez: {
                    sick_relatives: [],

                    sensitive_everyday: +this.sensitization.sensitiveEveryday.value,
                    sensitive_epiderm: +this.sensitization.sensitiveEpiderm.value,
                    sensitive_pollen: +this.sensitization.sensitivePollen.value,
                    sensitive_mushrooms: +this.sensitization.sensitiveMushrooms.value,
                    sensitive_food: +this.sensitization.sensitiveFood.value,

                    allergens: this.$refs.allergens.getFieldsForSave(),
                    npvs: this.$refs.npvs.getFieldsForSave(),
                    medicines: this.$refs.medicines.getFieldsForSave(),
                    food_allergies: this.$refs.foodAllergies.getFieldsForSave(),
                    other: this.other,
                },
            };

            if (this.inheritanceAllergic) {
                obj.allergoanamnez.sick_relatives = [
                    ...obj.allergoanamnez.sick_relatives,
                    ...this.$refs.sickRelativesAllergic.getFieldsForSave(),
                ];
            }
            if (this.inheritanceAutoimmune) {
                obj.allergoanamnez.sick_relatives = [
                    ...obj.allergoanamnez.sick_relatives,
                    ...this.$refs.sickRelativesAutoimmune.getFieldsForSave(),
                ];
            }
            return obj;
        },

        checkValid() {
            if (this.inheritanceAllergic && this.$refs.sickRelativesAllergic.$v.$invalid) {
                this.$refs.sickRelativesAllergic.$v.$touch();
                return false;
            }
            if (this.inheritanceAutoimmune && this.$refs.sickRelativesAutoimmune.$v.$invalid) {
                this.$refs.sickRelativesAutoimmune.$v.$touch();
                return false;
            }
            if (this.$refs.allergens.$v.$invalid
                || this.$refs.npvs.$v.$invalid
                || this.$refs.medicines.$v.$invalid
                || this.$refs.foodAllergies.$v.$invalid) {
                this.$refs.allergens.$v.$touch();
                this.$refs.npvs.$v.$touch();
                this.$refs.medicines.$v.$touch();
                this.$refs.foodAllergies.$v.$touch();
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss">
.allergyanamnesis {
    @include mobile_or_P {
        max-width: 100vmin;
        margin: 0 auto;

        h4 {
            margin-bottom: 1rem;
        }

        .allergyanamnesis {
            &--sencibility {
                max-width: 85%;

                label {
                    white-space: initial;
                }
            }
        }
    }
}
</style>
