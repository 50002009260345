import scrollToInvalidField from '@/js/utils/scrollToInvalidField';

export default {
    data: () => ({
        saveErrors: {},
    }),

    methods: {
        setSaveErrors(response) {
            if (response?.status === 409) {
                this.$notify({
                    title: 'Пациента уже отредактировали, пожалуйста, обновите страницу',
                    type: 'error',
                    duration: 2000,
                });
                return;
            }
            let errors = response?.data?.errors;
            let message = response?.data?.message;
            if (errors) {
                for (let key in errors) {
                    errors[key] = errors[key][0];
                }

                if (errors?.oms) {
                    errors.oms = `<a href="/patients/${response?.data?.patient?.id}" target="_blank" style="text-decoration: underline;">ОМС</a> уже используется`;
                }

                this.saveErrors = errors;

                if (errors?.phone || errors?.email || errors?.oms) {
                    this.activeTabId = 0;
                    setTimeout(scrollToInvalidField);
                } else {
                    this.$notify({
                        title: `Ошибка сервера!<br>${message}`,
                        type: 'error',
                        duration: 2000,
                    });
                }
            }
        },

        clearSaveError(field) {
            delete this.saveErrors[field];
        },
    },
};
