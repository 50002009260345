<template lang="pug">
    #app
        component(:is="layout")
            router-view
        notifications(position="bottom right")
        vue-confirm-dialog.confirm-dialog
</template>

<script>
export default {
    computed: {
        layout() {
            return this.$route.meta.layout || 'default-layout';
        },
    },
};
</script>
