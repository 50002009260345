export default {
    data: () => ({
        illnessesList: [],
    }),

    methods: {
        async fetchIllnesses(value) {
            try {
                const { data } = await this.axios.get(`dictionaries/illnesses?value=${value}&sortBy=id&perPage=30&order=ascend`);
                this.illnessesList = data.data.map(i => ({
                    ...i,
                    name: `${i.code} - ${i.name}`,
                }));
            } catch (e) {
                console.log(e);
            }
        },
    },
};
