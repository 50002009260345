<template lang="pug">
    .questionnaire-scorad
        app-text-field.result-field(
            v-model="result"
            label="SCORAD"
            is-float-number
            :max="103"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет SCORAD
            .block.bsa-block
                .block__name Площадь поражения (A)
                app-text-field(
                    v-model="bsa"
                    is-float-number
                    center
                    :max="100"
                    :error="$v.$dirty && !$v.bsa.required ? 'Заполните поле' : ''"
                )

            .block
                .block__name Интенсивность клинических проявлений (B)
                .block-row
                    .block-row__item.block-choices.app-choice-group(v-for="item in B")
                        .app-choice-group__label {{ item.name }}
                        .app-choice-group__list
                            app-choice(
                                v-model="item.value"
                                type="radio"
                                label="Отсутствует"
                                radio-value="0"
                            )
                            app-choice(
                                v-model="item.value"
                                type="radio"
                                label="Легко"
                                radio-value="1"
                            )
                            app-choice(
                                v-model="item.value"
                                type="radio"
                                label="Умеренно"
                                radio-value="2"
                            )
                            app-choice(
                                v-model="item.value"
                                type="radio"
                                label="Тяжело"
                                radio-value="3"
                            )

            .block
                .block__name Выраженность субъективных симптомов (С)
                .block-range.app-choice-group(v-for="item in C")
                    .app-choice-group__label {{ item.name }}
                    .app-choice-group__list
                        app-choice(
                            v-for="n in 11"
                            v-model="item.value"
                            type="radio"
                            :label="n-1"
                            :radio-value="n-1"
                        )
                    .block-range__extremes
                        span {{ item.extremeLow }}
                        span {{ item.extremeHigh }}

            .bottom
                .result(v-if="tempResult !== null") SCORAD = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import toFixed from '@/js/utils/toFixed';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-scorad',

    mixins: [questionnaireMixin],

    props: {
        defaultBsa: {
            type: [String, Number],
            required: false,
            default: 0,
        },
    },

    data: () => ({
        bsa: 0,

        B: [
            { name: 'Эритема', value: 0 },
            { name: 'Мокнутье / корки', value: 0 },
            { name: 'Отек или папулезность', value: 0 },
            { name: 'Лихенификация', value: 0 },
            { name: 'Расчесы', value: 0 },
            { name: 'Сухость', value: 0 },
        ],

        C: [
            { name: 'Зуд', extremeLow: 'Отсутствие зуда', extremeHigh: 'Невыносимый зуд', value: 0 },
            { name: 'Нарушение сна', extremeLow: 'отсутствие нарушений', extremeHigh: 'Сильная бессоница', value: 0 },
        ],
    }),

    validations: {
        bsa: { required },
    },

    methods: {
        openModal() {
            this.bsa = this.defaultBsa;

            this.$refs.modal.open();
        },

        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            const sumB = this.B.reduce((sum, item) => sum + Number(item.value), 0);
            const sumC = this.C.reduce((sum, item) => sum + Number(item.value), 0);
            this.tempResult = toFixed(this.bsa/5 + 7*sumB/2 + sumC, 1);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-scorad {
    .bsa-block {
        display: flex;
        align-items: center;

        .block__name {
            margin-right: rem(24px);
        }

        .app-text-field {
            width: rem(75px);
        }
    }

    .result-field {
        width: rem(96px);
    }

    @include mobile_or_P {
        .bsa-block {
            justify-content: space-between;

            .block__name {
                margin-bottom: rem(16px);
            }
        }

        .result-field {
            width: rem(200px);
            margin-right: rem(24px);
        }
    }
}
</style>
