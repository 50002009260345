<template lang="pug">
    .empty-layout
        vue100vh.empty-layout__wrapper
            slot
</template>

<script>
import Vue100vh from 'vue-div-100vh';

export default {
    name: 'empty-layout',

    components: { Vue100vh },
};
</script>
