<template lang="pug">
    .app-loader
        loader-icon
</template>

<script>
import LoaderIcon from '@/assets/images/icons/loader.svg';

export default {
    name: 'app-loader',

    components: { LoaderIcon },
};
</script>

<style lang="scss">
.app-loader {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    padding: 2rem 0;

    svg {
        width: 3rem;
    }
}
</style>
