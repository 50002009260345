<template lang="pug">
    .food-allergies-block(:class="{'food-allergies-block--re-contact': isReContact}")
        .food-allergies-block__row(
            v-for="(item, index) in foodAllergies"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-for="(type, index) in foodAllergiesTypes"
                        :key="index"
                        v-model="item.type"
                        type="radio"
                        :label="type.name"
                        :radio-value="type.id"
                    )
                app-text-field.food-allergies-block--manifestations(
                    v-model="item.manifestations"
                    label="Проявления"
                    :disabled="additionOnly && item.old"
                    :error="getFieldError(item, index, 'manifestations')"
                )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropFoodAllergy(index)"
                )
            hr.narrow(v-if="index < foodAllergies.length - 1")
        .br
        app-button(
            plus
            @click="addNewFoodAllergy"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { FOOD_ALLERGIES_TYPES } from '@/js/const';

export default {
    name: 'food-allergies-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        foodAllergies: [],
        foodAllergiesTypes: FOOD_ALLERGIES_TYPES,
    }),

    validations: {
        foodAllergies: {
            $each: {
                manifestations: { required },
            },
        },
    },

    watch: {
        foodAllergies(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.foodAllergies = this.value;
        });
    },

    methods: {
        addNewFoodAllergy() {
            this.foodAllergies.push({
                id: uniqueId(),
                type: 'TRUE',
                manifestations: '',
            });
            this.$v.$reset();
        },

        dropFoodAllergy(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.foodAllergies = this.foodAllergies.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.foodAllergies.$each.$iter[index].$dirty && !this.$v.foodAllergies.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.foodAllergies.map(d => {
                return {
                    type: d.type,
                    manifestations: d.manifestations,
                };
            });
        },

        getFormattedSavedFields(foodAllergies) {
            return foodAllergies.map(f => ({
                id: f.id,
                type: f.type,
                manifestations: f.manifestations,
                old: true,
            }));
        },
    },
};
</script>

<style lang="scss">
.food-allergies-block {
    .food-allergies-block {
        &--manifestations {
            width: rem(438px);
        }
    }

    &--re-contact {
        .food-allergies-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 1rem 1rem 0;

            &:first-child {
                margin-top: -1rem;
            }

            hr {
                margin-bottom: 0;
            }

            &.old {
                background: $light-blue;

                .app-text-field, .app-choice {
                    opacity: 0.5;

                    pointer-events: none;
                }
            }
        }
    }

    @include mobile_or_P {
        .food-allergies-block {
            &--manifestations {
                width: 100%;
            }
        }

        &--re-contact {
            margin-top: 0;
        }
    }
}
</style>
