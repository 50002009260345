import { render, staticRenderFns } from "./QuestionnairesAngiooteki.vue?vue&type=template&id=2c1d2b2e&lang=pug&"
import script from "./QuestionnairesAngiooteki.vue?vue&type=script&lang=js&"
export * from "./QuestionnairesAngiooteki.vue?vue&type=script&lang=js&"
import style0 from "./QuestionnairesAngiooteki.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports