<template lang="pug">
    .app-text-field(
        :class="classes"
        ref="app-text-field"
    )

        label {{ label }}

        input(
            v-if="!isTextarea"
            :id="uid"
            v-model="inputValue"
            :type="type"
            autocomplete="off"
            @focus="focusHandler"
            :disabled="disabled || unchangeable"
        )

        textarea-autosize(
            v-else
            :id="uid"
            v-model="inputValue"
            :max-height="1000"
            autocomplete="off"
            @focus.native="focusHandler"
            :disabled="disabled || unchangeable"
        )

        .app-text-field__list
            .app-text-field__list-wrapper(ref="app-text-field__list-wrapper")
                .app-text-field__list-items
                    .app-text-field__list-item(
                        v-for="option in filteredOptions"
                        @click="optionClickHandler(option)"
                        :class="getOptionClasses(option)"
                    ) {{ option.name }}

        .app-text-field__error(v-if="error" v-html="error")
</template>

<script>
import { uniqueId, isEmpty } from 'lodash';

export default {
    name: 'app-text-field',

    props: {
        value: {
            validator: () => true,
            required: true,
        },

        label: {
            type: String,
            required: false,
            default: '',
        },

        type: {
            type: String,
            required: false,
            default: 'text',
        },

        disabled: {
            validator: () => true,
            required: false,
            default: false,
        },

        noPointerEvents: {
            type: Boolean,
            required: false,
            default: false,
        },

        center: {
            type: Boolean,
            required: false,
            default: false,
        },

        unchangeable: {
            type: Boolean,
            required: false,
            default: false,
        },

        isTextarea: {
            type: Boolean,
            required: false,
            default: false,
        },

        isNumber: {
            type: Boolean,
            required: false,
            default: false,
        },

        isNaturalNumber: {
            type: Boolean,
            required: false,
            default: false,
        },

        isFloatNumber: {
            type: Boolean,
            required: false,
            default: false,
        },

        isNumberWithSlash: { // 123/
            type: Boolean,
            required: false,
            default: false,
        },

        isFloatNumberWithSlash: { // 1.23/
            type: Boolean,
            required: false,
            default: false,
        },

        isSpecificNumber: { // 123.+-/
            type: Boolean,
            required: false,
            default: false,
        },

        max: {
            type: Number,
            required: false,
            default: null,
        },

        isSelect: {
            type: Boolean,
            required: false,
            default: false,
        },

        options: {
            type: Array,
            default: () => [],
        },

        allowCustomValue: {
            type: Boolean,
            default: false,
        },

        error: {
            type: [String, Boolean],
            required: false,
            default: '',
        },
    },

    data: () => ({
        uid: null,
        inFocus: false,
        listPosition: 'bottom',

        tempInputValue: '',
        inputValue: '',
    }),

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        classes() {
            return {
                'app-text-field--focus': this.inFocus,
                'app-text-field--disabled': this.disabled,
                'app-text-field--no-pointer-events': this.noPointerEvents,
                'app-text-field--center': this.center,
                'app-text-field--no-empty': String(this.inputValue).length > 0,
                'app-text-field--input': !this.isTextarea,
                'app-text-field--textarea': this.isTextarea,
                'app-text-field--password': this.type === 'password',
                'app-text-field--select': this.isSelect,
                'app-text-field--invalid': this.error,
                'app-text-field--with-label': this.label,
                'app-text-field--list-open': this.openedSelectList,
                'app-text-field--list-top': this.isSelect && this.inFocus && this.listPosition === 'top',
                'app-text-field--list-bottom': this.isSelect && this.inFocus && this.listPosition === 'bottom',
            };
        },

        openedSelectList() {
            return this.isSelect && this.inFocus && !isEmpty(this.filteredOptions)  && this.inputValue.length >= 0;
        },

        filteredOptions() {
            if (!this.inFocus) return this.options;
            return this.options.filter(o => String(o.name).toLowerCase().indexOf(String(this.inputValue).toLowerCase()) >= 0);
        },
    },

    watch: {
        inputValue() {
            this.$emit('changeValue', this.inputValue);
            if (this.isSelect && !this.allowCustomValue) return;

            if (this.isFloatNumber) {
                this.inputValue = String(this.inputValue)
                    .replace(/,/g, '.')
                    .replace(/[^\d.]/g, '')
                    .replace(/^\./, '')
                    .replace(/^0+/, '0')
                    .replace(/^0([^.])/g, '$1') // избавляемся от 032
                    .replace( /^([^.]*\.)|\./g, '$1' ); // Удаляем все точки, кроме первой;
            }

            if (this.isSpecificNumber) {
                this.inputValue = String(this.inputValue)
                    .replace(/,/g, '.')
                    .replace(/[^\d.+-/]/g, '')
                    .replace(/^\./, '')
                    .replace(/^0+/, '0')
                    .replace(/^0([^.])/g, '$1') // избавляемся от 032
                    .replace( /^([^.]*\.)|\./g, '$1' ); // Удаляем все точки, кроме первой;
            }

            if (this.isNumber || this.isNaturalNumber) {
                this.inputValue = String(this.inputValue)
                    .replace(/\D/g, '')
                    .replace(/^0+/, '0')
                    .replace(/^0(.)/, '$1');
            }

            if (this.isNaturalNumber) {
                this.inputValue = String(this.inputValue)
                    .replace(/^0/, '');
            }

            if (this.isNumberWithSlash) {
                this.inputValue = String(this.inputValue)
                    .replace(/[^\d/]/g, '')
                    .replace(/^0+/, '0')
                    .replace(/^0(.)/, '$1');
            }

            if (this.isFloatNumberWithSlash) {
                this.inputValue = String(this.inputValue)
                    .replace(/,/g, '.')
                    .replace(/[^\d./]/g, '')
                    .replace(/^\./, '')
                    .replace(/^0+/, '0')
                    .replace(/^0(.)/, '$1')
                    .replace( /^([^.]*\.)|\./g, '$1' ); // Удаляем все точки, кроме первой;
            }

            if (this.max !== null) {
                if (Number(this.inputValue) > this.max) {
                    this.inputValue = String(this.inputValue).slice(0, -1);
                }
            }
            this.state = this.inputValue;
        },

        state(value) {
            this.inputValue = value;
        },
    },

    created() {
        this.inputValue = typeof this.value === 'object' ? this.value?.name : this.value;
    },

    mounted() {
        this.uid = 'text-field-' + uniqueId();

        if (this.isSelect) {
            window.Scrollbar.init(this.$refs['app-text-field__list-wrapper'], {
                alwaysShowTracks: true,
                plugins:{ horizontalScroll: false },
            });
        }
    },

    methods: {
        focusHandler() {
            if (this.isSelect) {
                this.setListPosition();
            }

            this.inFocus = true;
            document.addEventListener('click', this.blurHandler);
            document.querySelector(`#${this.uid}`).addEventListener('keydown', this.blurHandler);

            if (this.isSelect && !this.allowCustomValue) {
                this.tempInputValue = this.inputValue;
                this.inputValue = '';
            }
        },

        blurHandler(e) {
            if (e.key === 'Tab' || !e.target.closest(`#${this.uid}`)) {
                this.inFocus = false;
                if (this.isSelect && !this.allowCustomValue && this.tempInputValue) {
                    this.inputValue = this.tempInputValue;
                    this.tempInputValue = '';
                }
                document.removeEventListener('click', this.blurHandler);
                document.querySelector(`#${this.uid}`).removeEventListener('keydown', this.blurHandler);
            }
        },

        getOptionClasses(option) {
            return {
                selected: this.inputValue === option?.name || this.state === option?.name,
            };
        },

        optionClickHandler(option) {
            this.inputValue = this.state = option?.name;
            this.tempInputValue = '';
        },

        setListPosition() {
            const field = this.$refs['app-text-field'];
            const list = this.$refs['app-text-field__list-wrapper'];
            if (field.getBoundingClientRect().bottom + list.offsetHeight > window.innerHeight) {
                this.listPosition = 'top';
            } else {
                this.listPosition = 'bottom';
            }
        },

        isEmpty,
    },
};
</script>
