<template lang="pug">
    .contacts-dates-block(:class="{'contacts-dates-block--addition-only': additionOnly}")
        .contacts-dates-block__row(
            v-for="(item, index) in contacts"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.contacts-dates-block--contactDate(
                    v-model="item.date"
                    label="Дата"
                    :error="getFieldError(item, index, 'date')"
                )
                app-select.contacts-dates-block--contactType(
                    v-model="item.contactType"
                    :options="contactTypeList"
                    label="Вид контакта"
                    :error="getFieldError(item, index, 'contactType')"
                )
                app-text-field.contacts-dates-block--doctorName(
                    v-model="item.doctorName"
                    label="Фамилия врача"
                    :error="getFieldError(item, index, 'doctorName')"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="item.serumTaken"
                        type="checkbox"
                        label="Сыворотка взята"
                    )
                app-text-field.contacts-dates-block--contactNumber(
                    v-model="item.number"
                    label="Номер"
                    :disabled="!item.serumTaken"
                )
                app-dropper(
                    :class="{hidden: required && contacts.length === 1 || additionOnly && item.old}"
                    @click="dropContact(index)"
                )
            hr.narrow(v-if="index < contacts.length - 1")
        .br
        app-button(
            plus
            @click="addNewContact()"
        ) Добавить контакт
        .br
        .row
            .contacts-dates-block--parse-block
                app-text-field(
                    v-model="contactListForParse"
                    @input="parsListError=false"
                    label="Убедитесь, что все даты приведены в формате дд.мм.гггг и каждая дата начинается с новой строки."
                    is-textarea
                    :error="parsListError ? 'Одно или несколько значений имеют ошибочный формат' : ''"
                )
                app-button(@click="parseContactList")
                    arrow-right-icon
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import { CONTACT_TYPES } from '@/js/const';
import moment from 'moment';
import ArrowRightIcon from '@/assets/images/icons/arrow_right.svg';

export default {
    name: 'contacts-dates-block',

    components: {
        ArrowRightIcon,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },

        required: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        contacts: [],
        contactListForParse: '',
        parsListError: false,
        contactTypeList: CONTACT_TYPES,
    }),

    validations: {
        contacts: {
            $each: {
                date: { required },
                contactType: { required },
                doctorName: { required },
            },
        },
    },

    watch: {
        contacts(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.value.length) {
                this.contacts = this.value;
            } else if (this.required) {
                this.addNewContact();
            }
        });
    },


    methods: {
        addNewContact(date=null, contactType='') {
            this.contacts.push({
                id: uniqueId(),
                date: date,
                contactType: contactType,
                doctorName: '',
                serumTaken: false,
                number: '',
            });
            this.$v.$reset();
        },

        dropContact(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить контакт?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.contacts = this.contacts.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.contacts.$each.$iter[index].$dirty && !this.$v.contacts.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        parseContactList() {
            this.parsListError = false;
            let dateList = this.contactListForParse.split(/\n/).map(d => d.trim()).filter(d => d);
            let wrongDates = [];
            dateList.forEach(str => {
                const date = +moment(str, 'DD.MM.YYYY');
                if (/^\d{1,2}\.\d{1,2}\.\d{4}$/.test(str) && date) {
                    this.addNewContact(date, this.contactTypeList[0]);
                } else {
                    wrongDates.push(str);
                }
            });
            this.contactListForParse = wrongDates.join('\n');
            if (wrongDates.length) {
                this.parsListError = true;
            }
        },

        getFieldsForSave() {
            return this.contacts.map(d => ({
                date_at: moment(d.date).format('YYYY-MM-DD'),
                contact_type: d.contactType.id,
                doctor_name: d.doctorName,
                serum_taken: +d.serumTaken,
                number: d.number,
            }));
        },

        getFormattedSavedFields(contacts) {
            let fields = contacts.map(d => ({
                date: +moment(d.date_at),
                contactType: {
                    name: this.contactTypeList.find(c => c.id === d.contact_type)?.name,
                    id: d.contact_type,
                },
                doctorName: d.doctor_name,
                serumTaken: d.serum_taken,
                number: d.number || '',
                old: true,
            }));
            fields.sort((d1, d2) => d1.date - d2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.contacts-dates-block {
    .contacts-dates-block {
        &--contactDate, &--contactType, &--contactNumber, &--doctorName {
            width: rem(203px);
        }

        &--parse-block {
            position: relative;

            width: rem(866px);

            .app-text-field {
                width: 100%;
                margin: 0;
            }

            .app-button {
                position: absolute;
                right: rem(22px);
                bottom: rem(16px);

                margin: 0;
                padding:  0 rem(8px);
            }
        }
    }

    &--addition-only {
        .contacts-dates-block__row.old {
            .app-text-field, .app-select, .app-datepicker, .app-choice {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .contacts-dates-block {
            &--contactDate, &--contactType, &--contactNumber, &--doctorName {
                width: 100%;
            }
        }
    }
}
</style>
