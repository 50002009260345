
export default (obj) => {
    const fd = new FormData;
    for ( let key in obj ) {
        if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
            for ( let key2 in obj[key] ) {
                if (Object.prototype.toString.call(obj[key][key2]) === '[object Object]') {
                    for ( let key3 in obj[key][key2] ) {
                        // dermatitis[data][data][data]
                        fd.append(`${key}[${key2}][${key3}]`, obj[key][key2][key3]);
                    }
                } else if (Array.isArray(obj[key][key2])) {
                    obj[key][key2].forEach((o, index) => {
                        for ( let oKey in o ) {
                            if (Object.prototype.toString.call(o[oKey]) === '[object Object]') {
                                for ( let key3 in o[oKey] ) {
                                    // dermatitis[data][0][data][data]
                                    fd.append(`${key}[${key2}][${index}][${oKey}][${key3}]`, o[oKey][key3]);
                                }
                            } else if (Array.isArray(o[oKey])) {
                                o[oKey].forEach((o, oIndex) => {
                                    // dermatitis[data][0][data][0]
                                    fd.append(`${key}[${key2}][${index}][${oKey}][${oIndex}]`, o);
                                });
                            } else {
                                // dermatitis[data][0][data]
                                fd.append(`${key}[${key2}][${index}][${oKey}]`, o[oKey]);
                            }
                        }
                    });
                } else {
                    // dermatitis[data][data]
                    fd.append(`${key}[${key2}]`, obj[key][key2]);
                }
            }
        } else if (Array.isArray(obj[key])) {
            obj[key].forEach((o, index) => {
                for ( let oKey in o ) {
                    // dermatitis[0][data]
                    fd.append(`${key}[${index}][${oKey}]`, o[oKey]);
                }
            });
        } else {
            // data
            fd.append(key, obj[key]);
        }
    }
    return fd;
};
