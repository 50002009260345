import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import TextareaAutosize from 'vue-textarea-autosize';
Vue.use(TextareaAutosize);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import Notifications from 'vue-notification';
Vue.use(Notifications);

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

import { VTooltip, VPopover } from 'v-tooltip';
Vue.directive('tooltip', VTooltip);
Vue.component('v-popover', VPopover);

import { mask } from 'vue-the-mask';
Vue.directive('mask', mask);

import VueConfirmDialog from 'vue-confirm-dialog';
Vue.use(VueConfirmDialog);
Vue.component('vue-confirm-dialog', VueConfirmDialog.default);

// layouts
import DefaultLayout from '@/layouts/Default';
Vue.component('default-layout', DefaultLayout);
import EmptyLayout from '@/layouts/Empty';
Vue.component('empty-layout', EmptyLayout);

// app
import AppTextField from '@/components/app/app-text-field';
Vue.component('app-text-field', AppTextField);
import AppSelect from '@/components/app/app-select';
Vue.component('app-select', AppSelect);
import AppDatepicker from '@/components/app/app-datepicker';
Vue.component('app-datepicker', AppDatepicker);
import AppChoice from '@/components/app/app-choice';
Vue.component('app-choice', AppChoice);
import AppButton from '@/components/app/app-button';
Vue.component('app-button', AppButton);
import AppLink from '@/components/app/app-link';
Vue.component('app-link', AppLink);
import AppDropper from '@/components/app/app-dropper';
Vue.component('app-dropper', AppDropper);
import AppPlus from '@/components/app/app-plus';
Vue.component('app-plus', AppPlus);
import AppToggler from '@/components/app/app-toggler';
Vue.component('app-toggler', AppToggler);
import AppLoader from '@/components/app/app-loader';
Vue.component('app-loader', AppLoader);

import Scrollbar from 'smooth-scrollbar';
class HorizontalScrollPlugin extends Scrollbar.ScrollbarPlugin {
    static pluginName = 'horizontalScroll';
    transformDelta(delta, fromEvent) {
        if (!/wheel/.test(fromEvent.type)) {
            return delta;
        }
        const {x, y} = delta;
        return {
            y: 0,
            x: Math.abs(x) > Math.abs(y) ? x : y,
        };
    }
}
Scrollbar.use(HorizontalScrollPlugin);
window.Scrollbar = Scrollbar;


import '@/assets/scss/main.scss';
import '@/plugins/disableScroll.js';
import '@/plugins/deviceDetecter.js';
import '@/plugins/axios.js';

Vue.prototype.$eventBus = new Vue();

Vue.config.productionTip = false;

var VueCookie = require('vue-cookie');
Vue.use(VueCookie);

export const bus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
