<template lang="pug">
    .allergens-dictionary
        .edit-dictionary__top
            .edit-dictionary__name Аллергокомпоненты
            p Данный справочник служит для указания препаратов таргетной терапии. Доза, единицы измерения и кратность могут быть указаны только в соответствии с приведенными в справочнике значениями. Если поле оставлено пустым (например, не указано значение дозы), то пи заполнении карты пациента можно будет указать любое значение.
        app-loader(v-if="pageLoading")
        template(v-else)
            form.edit-dictionary__form(@submit.prevent="submit")
                app-button(tag="div" plus @click="addNewItem('before')") Добавить
                .br
                .row(
                    v-for="(item, index) in dictionary"
                    v-show="!item.deleted"
                )
                    app-text-field.allergens-dictionary--name(
                        v-model="item.name"
                        label="Аллергокомпонент"
                        :error="getFieldError(item, index, 'name')"
                    )
                    app-text-field.allergens-dictionary--base-name(
                        v-model="item.base_name"
                        label="Аллерген"
                        :error="getFieldError(item, index, 'base_name')"
                    )
                    app-text-field.allergens-dictionary--component(
                        v-model="item.component"
                        label="Для вывода в интерфейсе"
                        :error="getFieldError(item, index, 'component')"
                    )
                    app-dropper(@click="dropItem(item)")
                .br
                app-button(tag="div" plus @click="addNewItem") Добавить

            .edit-dictionary__bottom
                app-button(
                    :loading="loading"
                    large
                    @click="submit"
                ) Сохранить изменения
                app-button(
                    tag="router-link"
                    :to="{name: 'Dictionaries'}"
                    color="white"
                    large
                ) Закрыть без сохранения
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'allergens-dictionary',

    validations: {
        dictionary: {
            $each: {
                name: { required },
                base_name: { required },
                component: { required },
            },
        },
    },

    data: () => ({
        dictionary: [],

        pageLoading: false,
        loading: false,
    }),

    computed: {
        dictionaryList() {
            return this.$store.getters.allergens;
        },
    },

    async created() {
        this.pageLoading = true;
        await this.$store.dispatch('fetchAllergens');
        this.dictionary = this.dictionaryList.map(m => ({
            ...m,
            deleted: false,
            oldName: m.name,
            oldBaseName: m.base_name,
            oldComponent: m.component,
            error: null,
        }));
        this.pageLoading = false;
    },

    methods: {
        addNewItem(position) {
            const row = {
                name: '',
                base_name: '',
                component: '',
                deleted: false,
                isNew: true,
                error: null,
            };
            if (position === 'before') {
                this.dictionary.unshift(row);
            } else {
                this.dictionary.push(row);
            }
        },

        dropItem(item) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        item.deleted = true;
                        this.dictionary = this.dictionary.filter(d => !d.isNew || !d.deleted);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.dictionary.$each.$iter[index].$dirty && !this.$v.dictionary.$each.$iter[index][field].required) {
                return 'Поле обязательно для заполнения';
            } else if (field === 'name') {
                return item.error;
            } else {
                return null;
            }
        },

        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            this.loading = true;
            try {
                await Promise.all(this.dictionary.map((d, ind) => {
                    d.error = null;
                    const fd = new FormData;
                    fd.append('name', d.name);
                    fd.append('base_name', d.base_name);
                    fd.append('component', d.component);
                    if (d.isNew && !d.deleted) {
                        return this.axios.post(`dictionaries/allergens/create`, fd)
                            .then(data => {
                                this.dictionary[ind] = {
                                    ...data.data,
                                    deleted: false,
                                    oldName: data.data.name,
                                    oldBaseName: data.data.base_name,
                                    oldComponent: data.data.component,
                                    error: null,
                                };
                            })
                            .catch(e=> {
                                d.error = e.response?.data?.error;
                            });
                    } else if (!d.isNew && d.deleted) {
                        return this.axios.delete(`dictionaries/allergens/${d.id}`)
                            .catch(e=> {
                                d.deleted = false;
                                d.error = e.response?.data?.error;
                            });
                    } else if (d.name !== d.oldName || d.base_name !== d.oldBaseName || d.component !== d.oldComponent) {
                        return this.axios.post(`dictionaries/allergens/${d.id}`, fd)
                            .catch(e=> {
                                d.error = e.response?.data?.error;
                            });
                    }
                }));
                this.$store.dispatch('fetchAllergens', true);
                if (!this.dictionary.find(d => d.error)) {
                    this.$router.push({ name: 'Dictionaries' });
                }
            } catch (e) {
                console.log(e.response);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.allergens-dictionary {
    padding-bottom: rem(20px);

    &--name {
        max-width: rem(200px);
    }
    &--base-name {
        max-width: rem(400px);
    }
    &--component {
        max-width: rem(400px);
    }

    @include mobile_or_P {
        &--name, &--base-name, &--component {
            max-width: 100%;
        }
    }
}
</style>
