<template lang="pug">
    .app-more-btn(v-on="listeners")
        filters-icon
        slot
        arrow-select
</template>

<script>
import filtersIcon from '@/assets/images/icons/filters.svg';
import ArrowSelect from '@/assets/images/icons/arrow_select.svg';

export default {
    name: 'app-more-btn',

    components: {
        filtersIcon,
        ArrowSelect,
    },

    computed: {
        listeners() {
            return Object.assign(
                {},
                this.$listeners,
            );
        },
    },
};
</script>
