<template lang="pug">
    .questionnaire-poem
        app-text-field.result-field(
            v-model="result"
            label="POEM"
            is-number
            :max="28"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет POEM
            .block
                .block-question(v-for="(question, id) in questions")
                    .block-question__name(v-html="question.name")
                    .app-choice-group
                        app-choice(
                            v-for="answer in question.answers"
                            v-model="question.value"
                            type="radio"
                            :label="answer.name"
                            :radio-value="answer.value"
                            :error="$v.$dirty && $v.questions.$each.$iter[id].value.$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") POEM = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-poem',

    mixins: [questionnaireMixin],

    data: () => ({
        questions: {
            1: {
                name: 'Сколько дней за последнюю неделю у Вас чесалась кожа из-за экземы?',
                answers: [
                    { name: 'Ни разу', value: 0 },
                    { name: '1-2 дня', value: 1 },
                    { name: '3-4 дня', value: 2 },
                    { name: '5-6 дней', value: 3 },
                    { name: 'Каждый день', value: 4 },
                ],
                value: null,
            },
            2: {
                name: 'Сколько ночей за последнюю неделю Вы плохо спали из-за экземы?',
                answers: [
                    { name: 'Ни разу', value: 0 },
                    { name: '1-2 дня', value: 1 },
                    { name: '3-4 дня', value: 2 },
                    { name: '5-6 дней', value: 3 },
                    { name: 'Каждый день', value: 4 },
                ],
                value: null,
            },
            3: {
                name: 'Сколько дней за последнюю неделю у Вас кровоточила кожа из-за экземы?',
                answers: [
                    { name: 'Ни разу', value: 0 },
                    { name: '1-2 дня', value: 1 },
                    { name: '3-4 дня', value: 2 },
                    { name: '5-6 дней', value: 3 },
                    { name: 'Каждый день', value: 4 },
                ],
                value: null,
            },
            4: {
                name: 'Сколько дней за последнюю неделю у Вас наблюдались выделения прозрачной жидкости на коже из-за экземы?',
                answers: [
                    { name: 'Ни разу', value: 0 },
                    { name: '1-2 дня', value: 1 },
                    { name: '3-4 дня', value: 2 },
                    { name: '5-6 дней', value: 3 },
                    { name: 'Каждый день', value: 4 },
                ],
                value: null,
            },
            5: {
                name: 'Сколько дней за последнюю неделю у Вас трескалась кожа из-за экземы?',
                answers: [
                    { name: 'Ни разу', value: 0 },
                    { name: '1-2 дня', value: 1 },
                    { name: '3-4 дня', value: 2 },
                    { name: '5-6 дней', value: 3 },
                    { name: 'Каждый день', value: 4 },
                ],
                value: null,
            },
            6: {
                name: 'Сколько дней за последнюю неделю у Вас шелушилась кожа из-за экземы?',
                answers: [
                    { name: 'Ни разу', value: 0 },
                    { name: '1-2 дня', value: 1 },
                    { name: '3-4 дня', value: 2 },
                    { name: '5-6 дней', value: 3 },
                    { name: 'Каждый день', value: 4 },
                ],
                value: null,
            },
            7: {
                name: 'Сколько дней за последнюю неделю Ваша кожа была сухой или грубой на ощупь из-за экземы?',
                answers: [
                    { name: 'Ни разу', value: 0 },
                    { name: '1-2 дня', value: 1 },
                    { name: '3-4 дня', value: 2 },
                    { name: '5-6 дней', value: 3 },
                    { name: 'Каждый день', value: 4 },
                ],
                value: null,
            },
        },
    }),

    validations: {
        questions: {
            $each: {
                value: { required },
            },
        },
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = Object.values(this.questions).reduce((sum, question) => sum + question.value, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-poem {
    .result-field {
        width: rem(74px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
