<template lang="pug">
    .weights-block(:class="{'weights-block--re-contact': isReContact, 'weights-block--addition-only': additionOnly}")
        .weights-block__row(
            v-for="(item, index) in weights"
            v-show="!isReContact || !item.old || item.id === lastOldWeight.id"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-text-field.weights-block--field(
                    v-model="item.weight"
                    is-natural-number
                    label="Вес, кг"
                    :error="getFieldError(item, index, 'weight')"
                    @input="calculateObesity(item)"
                )
                app-select.weights-block--field(
                    v-model="item.obesity"
                    :options="obesityTypes"
                    label="Ожирение"
                    :error="getFieldError(item, index, 'obesity')"
                )
                app-text-field.weights-block--field(
                    :value="calculateIMT(item)"
                    no-pointer-events
                    label="ИМТ"
                )
                app-datepicker.weights-block--field(
                    v-model="item.date"
                    :disabled-dates="disabledDates"
                    label="Дата"
                    :error="getFieldError(item, index, 'date')"
                )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropWeight(index)"
                )
            hr.narrow(v-if="index < weights.length - 1")
        .br
        app-button(
            plus
            @click="addNewWeight"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'weights-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        height: {
            type: [String, Number],
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        weights: [],

        obesityTypes: [
            {
                name: 'Нет',
                id: 0,
                minIMT: 0,
                maxIMT: 30,
            },
            {
                name: '1 степени',
                id: 1,
                minIMT: 30,
                maxIMT: 35,
            },
            {
                name: '2 степени',
                id: 2,
                minIMT: 35,
                maxIMT: 40,
            },
            {
                name: '3 степени',
                id: 3,
                minIMT: 40,
                maxIMT: 10000,
            },
        ],
    }),

    validations: {
        weights: {
            $each: {
                weight: { required },
                obesity: { required },
                date: { required },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.weights.map(e => e.date);
        },

        lastOldWeight() {
            return this.weights.reduce((lastD, d) => {
                if (d.old && (!lastD || d.date > lastD.date)) {
                    lastD = d;
                }
                return lastD;
            }, null);
        },
    },

    watch: {
        height() {
            this.weights.forEach(item => {
                this.calculateObesity(item);
            });
        },

        weights(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.weights = this.value;
        });
    },

    methods: {
        addNewWeight() {
            this.weights.push({
                id: uniqueId(),
                weight: '',
                obesity: '',
                date: '',
            });
            this.$v.$reset();
        },

        dropWeight(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.weights = this.weights.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.weights.$each.$iter[index].$dirty && !this.$v.weights.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        calculateObesity(item) {
            if (!this.height || !item.weight) return;
            const imt = this.calculateIMT(item);
            item.obesity = this.obesityTypes.find(t => imt >= t.minIMT && imt < t.maxIMT);
        },

        calculateIMT(item) {
            if (!this.height || !item.weight) return '';
            let IMT = item.weight / ((this.height / 100)**2);
            return IMT ? IMT.toFixed(5) : '';
        },

        getFieldsForSave() {
            return this.weights.map(d => ({
                weight: d.weight,
                obesity: d.obesity.id,
                body_mass_index: this.calculateIMT(d),
                date_at: moment(d.date).format('YYYY-MM-DD'),
            }));
        },

        getFormattedSavedFields(weights) {
            let fields = weights.map(d => ({
                id: d.id,
                weight: d.weight,
                obesity: this.obesityTypes.find(o => o.id === d.obesity) || '',
                date: +moment(d.date_at),
                old: true,
            }));
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.weights-block {
    .weights-block {
        &--field {
            width: rem(192px);
        }
    }

    &--re-contact {
        .weights-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 1rem 1rem 0;

            &:first-child {
                margin-top: -1rem;
            }

            hr {
                margin-bottom: 0;
            }

            &.old {
                background: $light-blue;

                .weights-block {
                    &--field {
                        opacity: 0.5;

                        pointer-events: none;
                    }
                }
            }
        }
    }

    &--addition-only {
        .weights-block__row.old {
            .app-select, .app-text-field, .app-datepicker {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .weights-block {
            &--field {
                width: 100%;
            }
        }
    }
}
</style>
