<template lang="pug">
    .app-pagination
        paginate(
            v-model="state"
            container-class="pagination"
            :page-count="count"
            prev-link-class="prev"
            next-link-class="next"
        )
        app-link.app-link--dark.app-link--normal(no-pointer-events) Перейти к странице
        app-text-field(
            v-model.number="goToNumber"
            is-number
            center
            :max="count"
        )
</template>

<script>
import Paginate from 'vuejs-paginate';
import { debounce } from 'lodash';

export default {
    name: 'app-pagination',

    components: {
        Paginate,
    },

    props: {
        value: {
            type: Number,
            required: true,
        },

        count: {
            type: Number,
            required: true,
        },
    },

    data: () => ({
        goToNumber: '',
    }),

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        debouncedChangeNumber() {
            return debounce(() => {
                this.state = this.goToNumber || 1;
            }, 750);
        },
    },

    watch: {
        goToNumber() {
            this.debouncedChangeNumber();
        },
    },
};
</script>
