<template lang="pug">
    .page.edit-user
        h2 Редактирование данных пользователя
        app-loader(v-if="pageLoading")
        .container.white-bg.border-r.shadow(v-else )
            form(@submit.prevent="submit")
                .row
                    app-text-field(
                        v-model="lastName"
                        label="Фамилия"
                        :error="$v.$dirty && !$v.lastName.required ? 'Поле обязательно для заполнения' : ''"
                    )
                    app-text-field(
                        v-model="firstName"
                        label="Имя"
                        :error="$v.$dirty && !$v.firstName.required ? 'Поле обязательно для заполнения' : ''"
                    )
                    app-text-field(
                        v-model="middleName"
                        label="Отчество"
                        :error="$v.$dirty && !$v.middleName.required ? 'Поле обязательно для заполнения' : ''"
                    )
                .row
                    app-text-field(
                        v-model="email"
                        label="Email"
                        :error="emailError"
                    )
                    app-select(
                        v-model="MO"
                        :options="medicalOrganizations"
                        label="МО"
                        :error="$v.$dirty && !$v.MO.required ? 'Поле обязательно для заполнения' : ''"
                    )
                    app-select(
                        v-model="role"
                        :options="roleList"
                        label="Группа пользователей"
                        :error="$v.$dirty && !$v.role.required ? 'Поле обязательно для заполнения' : ''"
                    )
                .row
                    .app-choice-group.app-choice-group--center
                        app-choice(
                            v-model="isActive"
                            type="checkbox"
                            label="Активный"
                        )
                .row.edit-user--buttons
                    app-button(
                        type="submit"
                        :loading="loading"
                        large
                    ) Сохранить
                    app-button(
                        @click.prevent="sendVerification"
                        :loading="verification.loading"
                        large
                        color="white"
                    ) Отправить пароль еще раз
                    app-button(
                        @click.prevent="resetPassword"
                        :loading="reset.loading"
                        color="white"
                        large
                    ) Сбросить пароль
                    app-link.app-link--red(tag="router-link" to="/users") Закрыть

                .app-message-text.success(v-if="verification.success") Пароль отправлен еще раз на Email адрес
                .app-message-text.warning(v-if="verification.error" v-html="verification.error")

                .app-message-text.success(v-if="reset.success") Ссылка для восстановления пароля отправлена на Email адрес
                .app-message-text.warning(v-if="reset.error" v-html="reset.error")
</template>

<script>
import { email, required } from 'vuelidate/lib/validators';
import { ROLES } from '@/js/const';

export default {
    name: 'edit-user',

    data: () => ({
        user: null,

        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        MO: '',
        role: '',
        isActive: false,

        apiErrors: null,

        pageLoading: true,
        loading: false,

        verification: {
            loading: false,
            success: false,
            error: null,
        },

        reset: {
            loading: false,
            success: false,
            error: null,
        },
    }),

    validations: {
        firstName: { required },
        lastName: { required },
        middleName: { required },
        email: { required, email },
        MO: { required },
        role: { required },
    },

    computed: {
        medicalOrganizations() {
            return this.$store.getters.medicalOrganizations;
        },

        roleList() {
            return Object.keys(ROLES).map(id => ({
                name: ROLES[id],
                id,
            }));
        },

        emailError() {
            if (this.$v.$dirty) {
                if (!this.$v.email.required) {
                    return 'Поле обязательно для заполнения';
                } else if (!this.$v.email.email) {
                    return 'Некорректный формат email';
                }
            }
            return this.apiErrors?.email?.[0];
        },
    },

    created() {
        this.$store.dispatch('fetchMedicalOrganizations');
        this.fetchUser();
    },

    methods: {
        async submit() {
            this.apiErrors = null;

            if (this.loading) return;

            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            const fd = new FormData();
            fd.append('first_name', this.firstName);
            fd.append('last_name', this.lastName);
            fd.append('middle_name', this.middleName);
            fd.append('email', this.email);
            fd.append('role', this.role.id);
            fd.append('medical_organization_id', this.MO.id);
            fd.append('status', this.isActive ? 'active' : 'disabled');

            this.loading = true;

            try {
                await this.axios.post(`users/${this.$route.params.id}`, fd);
                await this.$router.push({ name: 'Users' });
            } catch (e) {
                this.apiErrors = e?.response?.data?.errors;
            } finally {
                this.loading = false;
            }
        },

        async fetchUser() {
            try {
                const response = await this.axios.get(`users/${this.$route.params.id}`);
                const user = response.data?.user;
                this.user = user;
                this.firstName = user.first_name;
                this.lastName = user.last_name;
                this.middleName = user.middle_name;
                this.email = user.email;
                this.MO = this.medicalOrganizations.find(m => m.id === user.medical_organization_id);
                this.role = this.roleList.find(r => r.id === user.role);
                this.isActive = user.status === 'active';
            } catch (e) {
                this.$router.replace('/not-found');
            } finally {
                this.pageLoading = false;
            }
        },

        async sendVerification() {
            if (this.verification.loading) return;

            this.verification.success = this.verification.error = false;
            this.reset.success = this.reset.error = false;

            this.verification.loading = true;

            try {
                await this.axios.post(`users/${this.user.id}/send-verification`);
                this.verification.success = true;
            } catch (e) {
                if (e?.response?.status === 422) {
                    this.verification.error = 'Пользователь уже подтвердил email';
                } else if (e?.response?.status === 404) {
                    this.verification.error = 'Пользователь с таким email не существует';
                } else {
                    this.verification.error = 'Что-то пошло не так, повторите попытку позже';
                }
            } finally {
                this.verification.loading = false;
            }
        },

        async resetPassword() {
            if (this.reset.loading) return;

            this.verification.success = this.verification.error = false;
            this.reset.success = this.reset.error = false;

            const fd = new FormData();
            fd.append('id', this.user.id);

            this.reset.loading = true;

            try {
                await this.axios.post(`users/reset-password-request`, fd);
                this.reset.success = true;
            } catch (e) {
                if (e?.response?.data?.message) {
                    this.reset.error = e.response.data.message;
                } else {
                    this.reset.error = 'Что-то пошло не так, повторите попытку позже';
                }
            } finally {
                this.reset.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.edit-user {
    @include mobile_or_P {
        form {
            display: flex;
            flex-direction: column;

            max-width: 100vmin;
            margin: 0 auto;
        }

        &--buttons {
            order: 2;
        }

        .app-button {
            justify-content: center;

            width: 100%;
        }

        .app-link {
            margin-right: auto;
            margin-left: auto;
        }
    }
}
</style>
