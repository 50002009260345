<template lang="pug">
    .dictionary-default-template
        app-loader(v-if="pageLoading")
        template(v-else)
            form.edit-dictionary__form(@submit.prevent="submit")
                app-button(tag="div" plus @click="addNewItem('before')") Добавить
                .br
                .row(
                    v-for="(item, index) in dictionary"
                    v-show="!item.deleted"
                )
                    app-text-field(
                        v-model="item.name"
                        :error="getFieldError(item, index)"
                    )
                    app-dropper(@click="dropItem(item)")
                .br
                app-button(tag="div" plus @click="addNewItem") Добавить

            .edit-dictionary__bottom
                app-button(
                    :loading="loading"
                    large
                    @click="submit"
                ) Сохранить изменения
                app-button(
                    tag="router-link"
                    :to="{name: 'Dictionaries'}"
                    color="white"
                    large
                ) Закрыть без сохранения
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'dictionary-default-template',

    props: {
        actionName: {
            type: String,
            required: true,
        },

        getterName: {
            type: String,
            required: true,
        },

        urlPart: {
            type: String,
            required: true,
        },
    },

    validations: {
        dictionary: {
            $each: {
                name: {
                    required,
                },
            },
        },
    },

    data: () => ({
        dictionary: [],

        pageLoading: false,
        loading: false,
    }),

    computed: {
        dictionaryList() {
            return this.$store.getters[this.getterName];
        },
    },

    async created() {
        this.pageLoading = true;
        await this.$store.dispatch(this.actionName);
        this.dictionary = this.dictionaryList.map(m => ({ ...m, deleted: false, oldName: m.name, error: null }));
        this.pageLoading = false;
    },

    methods: {
        addNewItem(position) {
            const row = {
                name: '',
                deleted: false,
                isNew: true,
                error: null,
            };
            if (position === 'before') {
                this.dictionary.unshift(row);
            } else {
                this.dictionary.push(row);
            }
        },

        dropItem(item) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        item.deleted = true;
                        this.dictionary = this.dictionary.filter(d => !d.isNew || !d.deleted);
                    }
                },
            });
        },

        getFieldError(item, index) {
            if (this.$v.dictionary.$each.$iter[index].$dirty && !this.$v.dictionary.$each.$iter[index].name.required) {
                return 'Поле обязательно для заполнения';
            } else {
                return item.error;
            }
        },

        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            this.loading = true;
            try {
                await Promise.all(this.dictionary.map((d, ind) => {
                    d.error = null;
                    const fd = new FormData;
                    fd.append('name', d.name);
                    if (d.isNew && !d.deleted) {
                        return this.axios.post(`dictionaries/${this.urlPart}/create`, fd)
                            .then(data => {
                                this.dictionary[ind] = { ...data.data, deleted: false, oldName: data.data.name, error: null };
                            })
                            .catch(e=> {
                                d.error = e.response?.data?.error;
                            });
                    } else if (!d.isNew && d.deleted) {
                        return this.axios.delete(`dictionaries/${this.urlPart}/${d.id}`).catch(e=> {
                            d.deleted = false;
                            d.error = e.response?.data?.error;
                        });
                    } else if (d.name !== d.oldName) {
                        return this.axios.post(`dictionaries/${this.urlPart}/${d.id}`, fd).catch(e=> {
                            d.error = e.response?.data?.error;
                        });
                    }
                }));
                this.$store.dispatch(this.actionName, true);
                if (!this.dictionary.find(d => d.error)) {
                    this.$router.push({ name: 'Dictionaries' });
                }
            } catch (e) {
                console.log(e.response);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.dictionary-default-template {
    padding-bottom: rem(20px);

    .row {
        max-width: 50%;
    }

    .app-text-field {
        max-width: rem(400px);
    }

    @include mobile_or_P {
        .row {
            max-width: 100%;
        }

        .app-dropper {
            height: initial;
        }
    }
}
</style>
