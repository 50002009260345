<template lang="pug">
    .asthma
        h4 Диагноз
        diagnoses-block(
            v-model="diagnoses"
            ref="diagnoses"
            required
            with-asthma-form
            :addition-only="additionOnly"
        )

        h4 Таргентная терапия
        target-therapy-block(
            v-model="targetTherapy"
            ref="targetTherapy"
            :addition-only="additionOnly"
        )

        h4 Базисная терапия
        therapy-block(
            v-model="basicTherapy"
            ref="basicTherapy"
            :addition-only="additionOnly"
        )

        h4 Обострения
        exacerbations-block(
            v-model="exacerbations"
            ref="exacerbations"
            with-cause
            nebulizer
            :addition-only="additionOnly"
        )

        h4 Нежелательные эффекты
        unwanted-effects-block(
            v-model="unwantedEffects"
            ref="unwantedEffects"
            :addition-only="additionOnly"
        )

        h4 Опросники
        questionnaires-asthma(
            v-model="questionnairesAsthma"
            ref="questionnairesAsthma"
            :addition-only="additionOnly"
        )

        .row.sticky-buttons-block
            app-button(
                large
                :loading="saveAndContinueLoading"
                @click="saveAndContinue"
            ) Сохранить и продолжить заполнение
            app-button(
                :loading="saveLoading"
                large
                color="white"
                @click="saveAndClose"
            ) Сохранить и закончить заполнение
            app-link.app-link--red.app-link--left-margin(@click="close") Закрыть без сохранения
</template>

<script>
import TherapyBlock from '@/components/pages/patients/blocks/TherapyBlock';
import TargetTherapyBlock from '@/components/pages/patients/blocks/TargetTherapyBlock';
import DiagnosesBlock from '@/components/pages/patients/blocks/DiagnosesBlock';
import ExacerbationsBlock from '@/components/pages/patients/blocks/ExacerbationsBlock';
import QuestionnairesAsthma from '@/components/pages/patients/questionnaires/QuestionnairesAsthma';
import saving from '@/mixins/saving';
import UnwantedEffectsBlock from '@/components/pages/patients/blocks/UnwantedEffectsBlock';

export default {
    name: 'asthma',

    components: {
        TherapyBlock,
        TargetTherapyBlock,
        DiagnosesBlock,
        ExacerbationsBlock,
        UnwantedEffectsBlock,
        QuestionnairesAsthma,
    },

    mixins: [saving],

    props: {
        additionOnly: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        diagnoses: [],
        targetTherapy: [],
        basicTherapy: [],
        exacerbations: [],
        unwantedEffects: [],
        questionnairesAsthma: [],
    }),

    mounted() {
        if (this.lastData && !this.hasDataFromLocalStorage) {
            const asthma = this.lastData.sicknesses.find(s => s.type === 'ASTHMA');
            if (asthma) {
                this.diagnoses = this.$refs.diagnoses.getFormattedSavedFields(asthma.diagnoses);

                this.targetTherapy = this.$refs.targetTherapy.getFormattedSavedFields(asthma.target_therapies);

                this.basicTherapy = this.$refs.basicTherapy.getFormattedSavedFields(asthma.basis_therapies);

                this.exacerbations = this.$refs.exacerbations.getFormattedSavedFields(asthma.exacerbations);

                this.unwantedEffects = this.$refs.unwantedEffects.getFormattedSavedFields(asthma.unwanted_effects);

                this.questionnairesAsthma = this.$refs.questionnairesAsthma.getFormattedSavedFields(asthma.questionnaires);
            }
        }
    },

    methods: {
        getFormFields() {
            if (!this.enableBronchialAsthma) {
                return {};
            }
            return {
                asthma: {
                    diagnoses: this.$refs.diagnoses.getFieldsForSave(),
                    target_therapies: this.$refs.targetTherapy.getFieldsForSave(),
                    basis_therapies: this.$refs.basicTherapy.getFieldsForSave(),
                    exacerbations: this.$refs.exacerbations.getFieldsForSave(),
                    unwanted_effects: this.$refs.unwantedEffects.getFieldsForSave(),
                    questionnaires: this.$refs.questionnairesAsthma.getFieldsForSave(),
                },
            };
        },

        checkValid() {
            if (!this.enableBronchialAsthma) {
                return true;
            }
            if (this.$refs.diagnoses.$v.$invalid
                || this.$refs.targetTherapy.$v.$invalid
                || this.$refs.basicTherapy.$v.$invalid
                || this.$refs.exacerbations.$v.$invalid
                || this.$refs.unwantedEffects.$v.$invalid
                || this.$refs.questionnairesAsthma.$v.$invalid) {
                this.$refs.diagnoses.$v.$touch();
                this.$refs.targetTherapy.$v.$touch();
                this.$refs.basicTherapy.$v.$touch();
                this.$refs.exacerbations.$v.$touch();
                this.$refs.unwantedEffects.$v.$touch();
                this.$refs.questionnairesAsthma.$v.$touch();
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss">
.asthma {
    @include mobile_or_P {
        max-width: 100vmin;
        margin: 0 auto;

        h4 {
            margin-bottom: 1rem;
        }
    }
}
</style>
