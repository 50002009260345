<template lang="pug">
.app-plus
    .app-plus__inner(v-on="listeners")
        plus-icon
        span.forMobile_or_p Добавить
</template>

<script>
import plusIcon from '@/assets/images/icons/plus_2.svg';

export default {
    name: 'app-plus',

    components: {
        plusIcon,
    },

    computed: {
        listeners() {
            return Object.assign(
                {},
                this.$listeners,
            );
        },
    },
};
</script>
