<template lang="pug">
    .page.auth
        .auth__container
            .auth__top
                logo
                b Регистр аллергологии<br> и иммунологии
            .auth__title Авторизация
            form(@submit.prevent="submit")
                app-text-field(
                    v-model="email"
                    label="Ваш email"
                    :error="emailError"
                )
                app-text-field(
                    v-model="password"
                    label="Ваш пароль"
                    type="password"
                    :error="passwordError"
                )
                app-button(
                    type="submit"
                    :loading="loading"
                    large
                    center
                ) Войти
</template>

<script>
import logo from '@/assets/images/logo.svg';
import { required, email } from 'vuelidate/lib/validators';

export default {
    name: 'auth',

    components: {
        logo,
    },

    data: () => ({
        email: '',
        password: '',

        commonError: '',

        loading: false,
    }),

    validations: {
        email: {
            required,
            email,
        },

        password: {
            required,
        },
    },

    computed: {
        emailError() {
            if (this.$v.$dirty) {
                if (!this.$v.email.required) {
                    return 'Введите ваш email';
                } else if (!this.$v.email.email) {
                    return 'Некорректный формат email';
                }
            }
            return '';
        },

        passwordError() {
            if (this.$v.$dirty) {
                if (!this.$v.password.required) {
                    return 'Введите пароль';
                }
            }
            if (this.commonError) {
                return this.commonError;
            }
            return '';
        },
    },

    methods: {
        async submit() {
            if (this.loading) return;

            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            const fd = new FormData();
            fd.append('username', this.email);
            fd.append('password', this.password);

            this.loading = true;

            try {
                await this.$store.dispatch('authorize', fd);
                this.$router.replace('/');
            } catch (e) {
                if (e.status === 401) {
                    this.commonError = 'Неверный email или пароль';
                } else if (e.status === 403) {
                    this.commonError = 'Данный пользователь не активен';
                }  else if (e.status === 400) {
                    this.commonError = 'Пришло время установить новый пароль. Проверьте вашу электронную почту.';
                } else {
                    console.log(e);
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.auth {
    display: flex;
    justify-content: center;
    align-items: center;

    height: inherit;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: rem(424px);
        max-width: 100%;
        padding: 2rem;

        background-color: $white;
        border-radius: rem(16px);
        box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.03);
    }

    &__top {
        display: flex;
        align-items: center;

        margin-bottom: rem(50px);

        svg {
            width: rem(50px);
            margin-right: 1rem;
        }
    }

    &__title {
        margin-bottom: rem(48px);

        font-size: rem(24px);
        line-height: rem(29px);
        font-weight: bold;
        color: $dark-blue;
    }

    .app-text-field {
        margin-bottom: rem(24px);
    }

    .app-button {
        width: 100%;
        margin: 0;
    }

    @include mobile_or_P {
        &__container {
            padding: 1rem;
        }

        @include landscape {
            align-items: flex-start;

            &__top, &__title {
                margin-bottom: 0.75rem;
            }
            .app-text-field {
                margin-bottom: 1.25rem;
            }
        }
    }
}
</style>
