<template lang="pug">
    .chronic-diseases-block(:class="{'chronic-diseases-block--re-contact': isReContact}")
        .chronic-diseases-block__row(
            v-for="(item, index) in diagnoses"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-select.chronic-diseases-block--field(
                    v-model="item.illness"
                    :options="illnessesList"
                    searchable
                    :internal-search="false"
                    @search-change="fetchIllnesses"
                    noOptionsText="Начните вводить значение"
                    label="Заболевание (код или название)"
                    :disabled="additionOnly && item.old"
                    :error="getFieldError(item, index, 'illness')"
                )
                app-text-field.chronic-diseases-block--field(
                    v-model="item.description"
                    label="Развернутый диагноз"
                    :disabled="additionOnly && item.old && item.old_values.description"
                )
                app-datepicker.chronic-diseases-block--date(
                    v-model="item.date"
                    :inaccurate="item.dateInaccurate"
                    label="Дата"
                    :disabled="additionOnly && item.old && item.old_values.date"
                    :error="getFieldError(item, index, 'date')"
                )
                .app-choice-group.app-choice-group--center.chronic-diseases-block--inaccurrate
                    app-choice(
                        v-model="item.dateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="additionOnly && item.old && item.old_values.date"
                    )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropDiagnosis(index)"
                )
            hr.narrow(v-if="index < diagnoses.length - 1")
        .br
        app-button(
            plus
            @click="addNewDiagnosis"
        ) Добавить заболевание
</template>

<script>
import illnessesList from '@/mixins/illnessesList';
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'chronic-diseases-block',

    mixins: [illnessesList],

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        diagnoses: [],
    }),

    validations: {
        diagnoses: {
            $each: {
                illness: {
                    required,
                    uniqual(value, item) {
                        return !this.diagnoses.some(a => a.id !== item.id && a.illness.id === value.id);
                    },
                },
                date: {
                    required,
                },
            },
        },
    },

    watch: {
        diagnoses(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.diagnoses = this.value;
        });
    },

    methods: {
        addNewDiagnosis() {
            this.diagnoses.push({
                id: uniqueId(),
                illness: '',
                description: '',
                date: '',
                dateInaccurate: '',
            });
            this.$v.$reset();
        },

        dropDiagnosis(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить заболевание?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.diagnoses = this.diagnoses.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.diagnoses.$each.$iter[index].$dirty && !this.$v.diagnoses.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else if (field === 'illness' && !this.$v.diagnoses.$each.$iter[index][field].uniqual) {
                return 'Значение должно быть уникальным';
            }  else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.diagnoses.map(d => ({
                illnesses_id: d.illness.id,
                data: d.description,
                diagnosis_date: moment(d.date).format('YYYY-MM-DD'),
                diagnosis_date_inaccurate: +d.dateInaccurate,
            }));
        },

        getFormattedSavedFields(diagnoses) {
            return diagnoses.map(d => {
                const obj = {
                    id: d.id,
                    illness: {
                        ...d.illness,
                        name: `${d.illness.code} - ${d.illness.name}`,
                    },
                    description: d.diagnoses[0]?.data || '',
                    date: d.diagnoses[0]?.diagnosis_date ? +moment(d.diagnoses[0].diagnosis_date) : '',
                    dateInaccurate: d.diagnoses[0]?.diagnosis_date_inaccurate,
                    old: true,
                };
                return {
                    ...obj,
                    old_values: { ...obj },
                };
            });
        },
    },
};
</script>

<style lang="scss">
.chronic-diseases-block {
    .chronic-diseases-block {
        &--date {
            width: rem(168px);
        }

        &--field {
            width: rem(340px);
        }
    }

    &--re-contact {
        margin-top: -1rem;

        .chronic-diseases-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 1rem 1rem 0;

            hr {
                margin-bottom: 0;
            }

            &.old {
                background: $light-blue;

                .chronic-diseases-block {
                    &--date, &--field {
                        opacity: 0.5;

                        pointer-events: none;
                    }
                }
            }
        }
    }

    @include mobile_or_P {
        .chronic-diseases-block {
            &--date,
            &--field,
            &--inaccurrate {
                width: 100%;
            }
        }
    }
}
</style>
