import Vue from 'vue';

export default {
    state: () => ({
        user: null,
        token: null,
    }),

    mutations: {
        setUser(state, data) {
            state.user = data;
        },

        setToken(state, data) {
            state.token = data;
        },

        clearUser(state) {
            state.user = null;
            state.token = null;
        },
    },

    getters: {
        user(state) {
            return state.user;
        },

        role(state) {
            return state.user?.role;
        },
    },

    actions: {
        async authorize({ commit }, data) {
            try {
                const response = await Vue.axios.post(`auth/signin`, data);
                commit('setUser', response.data.user);
                commit('setToken', response.data.token);
                Vue.cookie.set('token', response.data.token);
            } catch (e) {
                throw e.response;
            }
        },

        async verify({ commit }, data) {
            try {
                const response = await Vue.axios.post(`auth/verify`, data);
                commit('setUser', response.data.user);
                commit('setToken', response.data.token);
                Vue.cookie.set('token', response.data.token);
            } catch (e) {
                throw e.response;
            }
        },

        logout({ commit }) {
            commit('clearUser');
            Vue.cookie.delete('token');
            localStorage.clear();
        },

        async refreshUser({ commit }, token) {
            try {
                const response = await Vue.axios.get(`/profile`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                commit('setUser', response.data.user);
                commit('setToken', token);
            } catch (e) {
                Vue.cookie.delete('token');
            }
        },
    },
};
