<template lang="pug">
    .fixed-patient-panel(ref="bar" :class="{show: showBar}")
        .fixed-patient-panel__container
            h2 {{ title }}
            h6 {{ patientName }}
            small ID {{ patient.id }}
</template>

<script>
export default {
    name: 'fixed-patient-panel',

    props: {
        title: {
            type: String,
            required: true,
        },

        patient: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        showBar: false,
    }),

    computed: {
        patientName() {
            return `${this.patient?.last_name} ${this.patient?.first_name} ${this.patient?.middle_name || ''}`;
        },
    },

    mounted() {
        window.addEventListener('scroll', this.updatePosition);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.updatePosition);
    },

    methods: {
        updatePosition() {
            if (this.$refs.bar.getBoundingClientRect().top < 0) {
                this.showBar = true;
            } else {
                this.showBar = false;
            }
        },
    },
};
</script>

<style lang="scss">
.fixed-patient-panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    pointer-events: none;

    &__container {
        position: sticky;
        top: 0;
        z-index: 10;

        margin: 0 rem(-37px) 0 rem(-20px);
        padding: rem(12px) 1.5rem 1rem;

        background: #fff;
        box-shadow: 0 1px rem(20px) rgba(0, 0, 0, 0.05);

        pointer-events: all;
    }

    &:not(.show) &__container {
        display: none;
    }

    h2 {
        margin: 0 0 rem(3px);
    }

    h6 {
        margin: 0 0 rem(4px);

        font-size: rem(24px);
        line-height: rem(29px);
        font-weight: bold;
        color: $dark-blue;
    }

    small {
        color: $dark-grey;
    }

    @include mobile_or_P {
        display: none;

        &__container {
            margin: 0;
        }

        h2 {
            padding-left: 0;
        }
    }
}
</style>
