<template lang="pug">
    .patient-data
        .row.patient-data--top-fields
            app-text-field.patient-data--top-field(
                v-model="lastName"
                label="Фамилия"
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.lastName.required ? 'Заполните поле' : ''"
            )
            app-text-field.patient-data--top-field(
                v-model="firstName"
                label="Имя"
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.firstName.required ? 'Заполните поле' : ''"
            )
            app-text-field.patient-data--top-field(
                v-model="middleName"
                label="Отчество"
                :disabled="additionOnly"
            )
            app-datepicker.patient-data--top-field(
                v-model="birthday"
                label="Дата рождения"
                :max-date="+moment()"
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.birthday.required ? 'Заполните поле' : ''"
            )
            .app-choice-group
                .app-choice-group__label Пол
                .app-choice-group__list
                    app-choice(
                        v-for="(name, id) in SEXList"
                        v-model="gender"
                        type="radio"
                        :label="name"
                        :radio-value="id"
                        :disabled="additionOnly"
                        :error="$v.$dirty && !$v.gender.required"
                    )
            .app-choice-group
                .app-choice-group__label Раса
                .app-choice-group__list
                    app-choice(
                        v-for="(name, id) in RACEList"
                        v-model="race"
                        type="radio"
                        :label="name"
                        :radio-value="id"
                        :disabled="additionOnly"
                        :error="$v.$dirty && !$v.race.required"
                    )
            app-text-field.patient-data--address(
                v-model="address"
                label="Адрес места проживания"
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.address.required ? 'Заполните поле' : ''"
            )
            app-text-field.patient-data--top-field(
                v-model="phone"
                label="Телефон"
                v-mask="'+7 (###) ###-##-##'"
                :disabled="additionOnly"
                :error="$v.$dirty && (!$v.phone.required ? 'Заполните поле' : !$v.phone.format ? 'Некорректный формат телефона' : '') || saveErrors.phone"
                @input="saveErrors.phone ? $emit('clearSaveError', 'phone') : ''"
            )
            app-text-field.patient-data--top-field(
                v-model="email"
                label="Email"
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.email.email ? 'Некорректный формат Email' : saveErrors.email"
                @input="saveErrors.email ? $emit('clearSaveError', 'email') : ''"
            )
            app-text-field.patient-data--top-field(
                v-model="oms"
                label="ОМС"
                v-mask="'################'"
                :disabled="additionOnly"
                :error="$v.$dirty && (!$v.oms.required ? 'Заполните поле' : !$v.oms.format ? 'Некорректный формат ОМС' : '') || saveErrors.oms"
                @input="saveErrors.oms ? $emit('clearSaveError', 'oms') : ''"
            )
            app-text-field.patient-data--top-field(
                v-model="insuranceCompany"
                label="Страховая компания"
                :disabled="additionOnly"
            )
            app-text-field.patient-data--top-field(
                v-model="sentFrom"
                label="Направлен(-а) из"
                :disabled="additionOnly"
            )
            app-text-field.patient-data--ageAtStart(
                v-model="ageAtStart"
                label="Возраст на момент включения в регистр"
                is-number
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.ageAtStart.required ? 'Заполните поле' : ''"
            )
        .br

        h4 Основные заболевания
        .row
            app-choice(
                :value="enableAtopicDermatitis"
                type="checkbox"
                label="Атопический дерматит"
                @input="$emit('toggleEnableAtopicDermatitis')"
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.enableAtopicDermatitis.required"
            )
            app-choice(
                :value="enableBronchialAsthma"
                type="checkbox"
                label="Бронхиальная астма"
                @input="$emit('toggleEnableBronchialAsthma')"
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.enableBronchialAsthma.required"
            )
            app-choice(
                :value="enableHives"
                type="checkbox"
                label="Крапивница"
                @input="$emit('toggleEnableHives')"
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.enableHives.required"
            )
        .br

        h4 Даты контактов
        contacts-dates-block(
            v-model="contacts"
            ref="contacts"
            required
            :addition-only="additionOnly"
        )

        .br
        .br
        .row
            .app-choice-group.patient-data--activity
                .app-choice-group__label Активность карты
                .app-choice-group__list
                    app-choice(
                        v-model="mapActivity"
                        type="radio"
                        label="Нет"
                        radio-value="0"
                        :disabled="additionOnly"
                        :error="$v.$dirty && !$v.mapActivity.required"
                    )
                    app-choice(
                        v-model="mapActivity"
                        type="radio"
                        label="Да"
                        radio-value="1"
                        :disabled="additionOnly"
                        :error="$v.$dirty && !$v.mapActivity.required"
                    )
            app-text-field.patient-data--reason(
                v-model="reason"
                label="Причина"
                :class="{'app-text-field--disabled': mapActivity !== '0'}"
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.reason.required ? 'Заполните поле' : ''"
            )
        .row
            app-text-field.patient-data--note(
                v-model="note"
                label="Примечание"
                is-textarea
                :disabled="additionOnly"
                :error="$v.$dirty && !$v.note.maxLength ? 'Максимальная длина 70 символов' : ''"
            )
        .row.sticky-buttons-block
            app-button(
                large
                :loading="saveAndContinueLoading"
                @click="saveAndContinue"
            ) Сохранить и продолжить заполнение
            app-button(
                :loading="saveLoading"
                large
                color="white"
                @click="saveAndClose"
            ) Сохранить и закончить заполнение
            app-link.app-link--red.app-link--left-margin(@click="close") Закрыть без сохранения

</template>

<script>

import moment from 'moment';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import { SEX, RACE } from '@/js/const';
import saving from '@/mixins/saving';
import ContactsDatesBlock from '@/components/pages/patients/blocks/patient-data/ContactsDatesBlock';

export default {
    name: 'patient-data',

    components: {
        ContactsDatesBlock,
    },

    mixins: [saving],

    props: {
        additionOnly: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        firstName: '',
        lastName: '',
        middleName: '',
        birthday: '',
        ageAtStart: '',
        gender: '',
        race: '',

        address: '',
        phone: '',
        email: '',
        oms: '',
        insuranceCompany: '',
        sentFrom: '',

        contacts: [],

        mapActivity: '1',
        reason: '',
        note: '',

        SEXList: SEX,
        RACEList: RACE,
    }),

    validations: {
        firstName: { required },
        lastName: { required },
        birthday: { required },
        ageAtStart: { required },
        gender: { required },
        race: { required },

        address: { required },
        phone: {
            required,
            format(value) {
                return value.replace(/\D/g, '').length === 11;
            },
        },
        email: { email },
        oms: {
            required,
            format(value) {
                return value.length === 9 || value.length === 16;
            },
        },

        enableAtopicDermatitis: {
            required(value) {
                return value || this.enableBronchialAsthma || this.enableHives;
            },
        },
        enableBronchialAsthma: {
            required(value) {
                return value || this.enableAtopicDermatitis || this.enableHives;
            },
        },
        enableHives: {
            required(value) {
                return value || this.enableAtopicDermatitis || this.enableBronchialAsthma;
            },
        },

        mapActivity: { required },
        reason: {
            required(value) {
                return value || this.mapActivity !== '0';
            },
        },
        note: {
            maxLength: maxLength(70),
        },
    },

    watch: {
        birthday() {
            if (!this.lastData) {
                this.ageAtStart = moment().diff(this.birthday, 'years');
            }
        },
    },

    beforeMount() {
        if (this.lastData && !this.hasDataFromLocalStorage) {
            this.firstName = this.lastData.first_name || '';
            this.lastName = this.lastData.last_name || '';
            this.middleName = this.lastData.middle_name || '';
            this.birthday = +moment(this.lastData.birth_date);
            this.ageAtStart = this.lastData.age_at_start;
            this.gender = this.lastData.sex || '';
            this.race = this.lastData.race_id || '';

            this.address = this.lastData.living_address || '';
            this.phone = this.lastData.phone || '';

            this.email = this.lastData.email || '';
            this.oms = this.lastData.oms || '';
            this.insuranceCompany = this.lastData.insurance_company || '';
            this.sentFrom = this.lastData.sent_from || '';

            this.mapActivity = String(this.lastData.is_active);
            this.reason = this.lastData.block_reason || '';
            this.note = this.lastData.note || '';
        }
    },

    mounted() {
        if (this.lastData && !this.hasDataFromLocalStorage) {
            this.contacts = this.$refs.contacts.getFormattedSavedFields(this.lastData.contacts);
        }
    },

    methods: {
        getFormFields() {
            const fields = {
                first_name: this.firstName,
                last_name: this.lastName,
                middle_name: this.middleName,
                birth_date: moment(this.birthday).format('YYYY-MM-DD'),
                age_at_start: this.ageAtStart,
                sex: this.gender,
                race_id: this.race,

                living_address: this.address,
                phone: this.phone.replace(/\D/g, ''),
                email: this.email || '',
                oms: this.oms,
                insurance_company: this.insuranceCompany,
                sent_from: this.sentFrom,

                is_active: this.mapActivity,
                note: this.note,

                contacts: this.$refs.contacts.getFieldsForSave(),
            };
            if (this.mapActivity === '0') {
                fields.block_reason = this.reason;
            }
            return fields;
        },

        checkValid() {
            if (this.$v.$invalid || this.$refs.contacts.$v.$invalid) {
                this.$v.$touch();
                this.$refs.contacts.$v.$touch();
                return false;
            }
            return true;
        },

        moment,
    },
};
</script>

<style lang="scss">
.patient-data{
   .patient-data {
       &--top-fields {
           flex-wrap: wrap;
       }

       &--top-field {
           width: rem(203px);
       }

       &--ageAtStart {
           width: rem(350px);
       }

       &--address {
           width: rem(393px);
       }

       &--reason {
           width: rem(606px);
       }

       &--note {
           width: rem(758px);

           textarea {
               min-height: rem(56px);
           }
       }
   }

    @include mobile_or_P {
        max-width: 100vmin;
        margin: 0 auto;

        h4 {
            margin-bottom: 1rem;
        }

        .patient-data {
            &--top-field,
            &--ageAtStart,
            &--address {
                width: 100%;
            }

            &--activity {
                flex-direction: row;

                min-height: initial;
                margin-top: 0.75rem;
                margin-bottom: 0.5rem;

                .app-choice-group__label {
                    margin-right: 1.5rem;
                }
            }
        }
    }
}
</style>
