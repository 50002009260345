<template lang="pug">
    .laboratory-indicators-dictionary
        .edit-dictionary__top
            .edit-dictionary__name Лабораторные показатели
            p Данный справочник служит для указания препаратов таргетной терапии. Доза, единицы измерения и кратность могут быть указаны только в соответствии с приведенными в справочнике значениями. Если поле оставлено пустым (например, не указано значение дозы), то пи заполнении карты пациента можно будет указать любое значение.
        dictionary-default-template(
            action-name="fetchLaboratoryIndicators"
            getter-name="laboratoryIndicators"
            url-part="laboratory-indicators"
        )
</template>

<script>
import DictionaryDefaultTemplate from '@/components/pages/dictionaries/DictionaryDefaultTemplate';

export default {
    name: 'laboratory-indicators-dictionary',

    components: {
        DictionaryDefaultTemplate,
    },
};
</script>
