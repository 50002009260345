<template lang="pug">
    .npvs-block(:class="{'npvs-block--re-contact': isReContact}")
        .npvs-block__row(
            v-for="(item, index) in npvs"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-select.npvs-block--name(
                    v-model="item.name"
                    :options="medicinesList"
                    searchable
                    @search-change="fetchMedicines"
                    noOptionsText="Начните вводить значение"
                    label="НПВС"
                    :disabled="additionOnly && item.old"
                    :error="getFieldError(item, index, 'name')"
                )
                app-text-field.npvs-block--reactions(
                    v-model="item.reactions"
                    label="Проявление реакции"
                    :disabled="additionOnly && item.old && item.old_values.reactions"
                )
                app-text-field.npvs-block--year(
                    v-model="item.year"
                    label="Год проявления"
                    v-mask="'####'"
                    :disabled="additionOnly && item.old && item.old_values.year"
                )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropNpvs(index)"
                )
            hr.narrow(v-if="index < npvs.length - 1")
        .br
        app-button(
            plus
            @click="addNewNpvs"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import medicinesList from '@/mixins/medicinesList';

export default {
    name: 'npvs-block',

    mixins: [medicinesList],

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        npvs: [],
    }),

    validations: {
        npvs: {
            $each: {
                name: { required },
            },
        },
    },

    watch: {
        npvs(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.npvs = this.value;
        });
    },

    methods: {
        addNewNpvs() {
            this.npvs.push({
                id: uniqueId(),
                name: '',
                reactions: '',
                year: '',
            });
            this.$v.$reset();
        },

        dropNpvs(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.npvs = this.npvs.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.npvs.$each.$iter[index].$dirty && !this.$v.npvs.$each.$iter[index][field].required) {
                return 'Заполните поле';
            }
            return null;
        },

        getFieldsForSave() {
            return this.npvs.map(d => {
                return {
                    medicines_id: d.name.id,
                    reactions: d.reactions,
                    year: d.year,
                };
            });
        },

        getFormattedSavedFields(npvs) {
            return npvs.map(d => {
                const obj = {
                    id: d.id,
                    name: {
                        ...d.medicine,
                        name: d.medicine.chemistry_name,
                    },
                    reactions: d.reactions || '',
                    year: d.year || '',
                    old: true,
                };
                return {
                    ...obj,
                    old_values: { ...obj },
                };
            });
        },
    },
};
</script>

<style lang="scss">
.npvs-block {
    .npvs-block {
        &--name, &--reactions {
            width: rem(438px);
        }

        &--year {
            width: rem(156px);
        }
    }

    &--re-contact {
        .npvs-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 1rem 1rem 0;

            &:first-child {
                margin-top: -1rem;
            }

            hr {
               margin-bottom: 0;
            }

            &.old {
                background: $light-blue;

                .npvs-block {
                    &--name, &--reactions, &--year {
                        opacity: 0.5;

                        pointer-events: none;
                    }
                }
            }
        }
    }

    @include mobile_or_P {
        .npvs-block {
            &--name, &--reactions, &--year {
                width: 100%;
            }
        }

        &--re-contact {
            margin-top: 0;
        }
    }
}
</style>
