import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import dictionaries from './dictionaries';
import { isEmpty } from 'lodash';
import getFIOAbbreviation from '@/js/utils/getFIOAbbreviation';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userList: [],
    },

    getters: {
        userList: state => state.userList,
    },

    mutations: {
        setUserList(state, data) {
            state.userList = data;
        },
    },

    actions: {
        async fetchUserList({ state, commit }, refresh= false) {
            if (!isEmpty(state.userList) && !refresh) return;
            try {
                const { data } = await Vue.axios.get(`users?perPage=1000`);
                commit('setUserList', data?.data.map(u => ({
                    ...u,
                    name: getFIOAbbreviation(u),
                })));
            } catch (e) {
                console.log(e);
            }
        },
    },

    modules: {
        auth,
        dictionaries,
    },
});
