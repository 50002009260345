<template lang="pug">
    .layout-sidebar(:class="sidebarClasses")
        .layout-sidebar__wrapper
            router-link.layout-sidebar__link(to="/patients")
                users3-icon
                span Пациенты
            router-link.layout-sidebar__link(
                v-if="role === 'ADMIN'"
                to="/users"
            )
                users3-icon
                span Пользователи
            router-link.layout-sidebar__link(
                to="/dictionaries"
                v-if="role === 'ADMIN' || role === 'DOCTOR_EDITOR'"
            )
                document-icon
                span Справочники
            .layout-sidebar__toggle(@click="rolled = !rolled")
                sidebar-toggle
</template>

<script>
import users3Icon from '@/assets/images/icons/users3.svg';
import documentIcon from '@/assets/images/icons/document.svg';
import sidebarToggle from '@/assets/images/icons/sidebar_toggle.svg';
import { bus } from '@/main';

export default {
    name: 'layout-sidebar',

    components: {
        users3Icon,
        documentIcon,
        sidebarToggle,
    },

    data: () => ({
        rolled: false,
        openMenu: false,
    }),

    computed: {
        sidebarClasses() {
            return {
                'layout-sidebar--rolled': this.rolled,
                'layout-sidebar--open': this.openMenu,
            };
        },

        role() {
            return this.$store.getters.role;
        },
    },

    created() {
        bus.$on('toggleMenu', () => {
            this.openMenu = !this.openMenu;
        });
    },
};
</script>

<style lang="scss">
.layout-sidebar {
    z-index: 20;

    flex-shrink: 0;

    width: rem(325px);
    min-height: calc(100vh - #{rem($header-height)});

    background: $white;

    transition: width .3s;

    &__wrapper {
        position: sticky;
        top: 0;
    }

    &__link {
        position: relative;

        display: flex;
        align-items: center;

        height: rem(88px);
        padding-left: rem(32px);

        font-size: rem(18px);
        font-weight: 600;
        color: $dark-blue;

        transition: background-color .3s, color .3s;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 100%;

            display: block;

            width: 2px;

            background: $blue;

            opacity: 0;
            transition: opacity .3s;
        }

        svg {
            flex-shrink: 0;

            width: rem(24px);
            height: rem(24px);
            margin-right: rem(16px);

            transition: fill .3s;
        }

        span {
            width: 100%;
            overflow: hidden;

            transition: opacity .1s;
        }

        &:hover {
            color: $dark;

            svg {
                fill: $dark;
            }
        }

        &.router-link-active {
            background-color: $light-blue;

            &:after {
                opacity: 1;
            }
        }
    }

    &__toggle {
        position: absolute;
        top: calc((100vh - #{rem($header-height)}) / 2);
        left: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        width: rem(32px);
        height: rem(32px);

        background: $white;
        border: 1px solid $light-blue;
        border-radius: 50%;

        transform: translate(-50%, -50%);
        cursor: pointer;

        svg {
            width: rem(16px);
            height: rem(16px);

            transition: transform .3s, fill .3s;
        }

        &:hover {
            box-shadow: 0 0 0 1px $light-blue;

            svg {
                fill: $dark;
            }
        }
    }

    &--rolled {
        width: rem(88px);
    }

    &--rolled & {
        &__link span {
            opacity: 0;
            transition: opacity .1s .2s;
        }

        &__toggle svg {
            transform: rotate(180deg);
        }
    }

    @include mobile_or_P {
        position: absolute;
        z-index: 100;

        width: 100%;
        height: calc(100% - #{rem(81px)});

        transform: translate3d(-100%,0,0);
        transition: transform .5s;

        &__wrapper {
            position: static;
        }

        &__toggle {
            display: none;
        }

        &__link:after {
            display: none;
        }

        &--open {
            transform: translate3d(0,0,0);
        }

        @include landscape {
            &__link {
                height: 4rem;
            }
        }
    }
}
</style>
