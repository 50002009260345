<template lang="pug">
    .snot20-block(:class="{'snot20-block--re-contact': isReContact, 'snot20-block--addition-only': additionOnly}")
        .snot20-block__row(
            v-for="(item, index) in snot20"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.snot20-block--date(
                    v-model="item.date"
                    label="Дата"
                    :disabled-dates="disabledDates"
                    :error="getFieldError(item, index, 'date')"
                )

                questionnaire-snot20(
                    v-model="item.result"
                    :error="getFieldError(item, index, 'result')"
                    @setProblems="item.problems = $event"
                )

                app-text-field.snot20-block--problems(
                    v-model="item.problems"
                    label="Наиболее существенные проблемы"
                    :error="getFieldError(item, index, 'problems')"
                )

                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropSnot20(index)"
                )
            hr.narrow(v-if="index < snot20.length - 1")
        .br
        app-button(
            plus
            @click="addNewSnot20()"
        ) Добавить
</template>

<script>
import QuestionnaireSnot20 from '@/components/pages/patients/questionnaires/modals/SNOT20';

import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
    name: 'snot20-block',

    components: {
        QuestionnaireSnot20,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        snot20: [],
    }),

    validations: {
        snot20: {
            $each: {
                date: { required },
                result: { required },
                problems: { required },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.snot20.map(e => e.date);
        },
    },

    watch: {
        snot20(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.snot20 = this.value;
        });
    },

    methods: {
        addNewSnot20(date = '') {
            this.snot20.push({
                id: uniqueId(),
                date: date,
                result: '',
                problems: '',
            });
            this.$v.$reset();
        },

        dropSnot20(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.snot20 = this.snot20.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.snot20.$each.$iter[index].$dirty && !this.$v.snot20.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.snot20.map(d => {
                return {
                    date_at: moment(d.date).format('YYYY-MM-DD'),
                    result: d.result,
                    problems: d.problems,
                    version: 'SNOT20',
                };
            });
        },

        getFormattedSavedFields(snot20) {
            return snot20.map(d => ({
                id: d.id,
                date: +moment(d.date_at),
                result: d.result,
                problems: d.problems,
                old: true,
            }));
        },
    },
};
</script>

<style lang="scss">
.snot20-block {
    .snot20-block {
        &--date {
            width: rem(168px);
        }

        &--problems {
            width: rem(298px);
        }
    }

    &--re-contact {
        .snot20-block__row.old {
            display: none;
        }
    }

    &--addition-only {
        .snot20-block__row.old {
            .app-datepicker, .app-text-field {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .snot20-block {
            &--date {
                width: 100%;
            }
        }
    }
}
</style>
