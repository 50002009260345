<template lang="pug">
    .app-select(
            :id="uid"
            :class="classes"
        )
        label {{ label }}
        multiselect(
            v-model="state"
            label="name"
            :track-by="trackBy"
            :options="options"
            :searchable="searchable"
            :internal-search="internalSearch"
            :multiple="multiple"
            :close-on-select="!multiple"
            :clear-on-select="!multiple"
            :allow-empty="allowEmpty"
            :loading="loading"
            :disabled="disabled"
            placeholder=""
            @open="focusHandler"
            @search-change="$emit('search-change', $event)"
            @close="inFocus=false"
        )
            span(slot="noResult") {{ noResultText }}
            span(slot="noOptions") {{ noOptionsText }}

            template(
                slot="selection"
                slot-scope="{ values, search, isOpen }"
            )
                span.multiselect__single(v-if="values.length && (!isOpen || !searchable)")
                    span.multiselect__multiple-items {{ values.map(v => v.name).join(', ') }}
                    span.multiselect__multiple-count(v-if="values.length > 1") &nbsp;({{ values.length }})
                span(v-else)

        arrow-select.app-select__arrow

        .app-select__clear(
            v-if="clearable && !isEmpty(state)"
            @click="clearAll"
        ) &#10006;

        .app-select__error(v-if="error") {{ error }}
</template>

<script>
import Multiselect from 'vue-multiselect';
import ArrowSelect from '@/assets/images/icons/arrow_select.svg';
import { uniqueId, isEmpty } from 'lodash';

export default {
    name: 'app-select',

    components: {
        Multiselect,
        ArrowSelect,
    },

    props: {
        value: {
            validator(){
                return 1;
            },
            required: true,
        },

        options: {
            type: Array,
            default: () => [],
        },

        label: {
            type: String,
            required: false,
            default: '',
        },

        searchable: {
            type: Boolean,
            default: false,
        },

        internalSearch: {
            type: Boolean,
            default: true,
        },

        clearable: {
            type: Boolean,
            default: false,
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        allowEmpty: {
            type: Boolean,
            default: false,
        },

        trackBy: {
            type: String,
            default: '',
        },

        loading: {
            type: Boolean,
            default: false,
        },

        disabled: {
            validator: () => true,
            required: false,
            default: false,
        },

        noResultText: {
            type: String,
            default: 'Ничего не найдено',
        },

        noOptionsText: {
            type: String,
            default: 'Список пока пуст',
        },

        error: {
            type: String,
            required: false,
            default: '',
        },
    },

    data: () => ({
        uid: null,
        inFocus: false,
    }),

    computed: {
        state: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },

        classes() {
            return {
                'app-select--focus': this.inFocus,
                'app-select--no-empty': Array.isArray(this.state) ? this.state.length : this.state,
                'app-text-field--disabled': this.disabled,
                'app-select--invalid': this.error,
                'app-select--with-label': this.label,
            };
        },
    },

    mounted() {
        this.uid = 'select-' + uniqueId();
    },

    methods: {
        focusHandler() {
            this.inFocus=true;
            setTimeout(() => {
                if (this.scrollIsInit) return;
                this.scrollIsInit = true;
                window.Scrollbar.init(document.querySelector(`.app-select#${this.uid} .multiselect__content-wrapper`), {
                    alwaysShowTracks: true,
                    plugins:{ horizontalScroll: false },
                });
            });
        },

        clearAll() {
            this.state = '';
        },

        isEmpty,
    },
};
</script>
