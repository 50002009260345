<template lang="pug">
    .questionnaire-easi
        app-text-field.result-field(
            v-model="result"
            label="EASI"
            is-float-number
            :max="72"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет EASI
            .block(v-for="block in blocks")
                .block__name {{ block.name }}
                .block-row
                    .block-row__item.block-choices.app-choice-group(v-for="choice in block.choices")
                        .app-choice-group__label {{ choice.name }}
                        .app-choice-group__list
                            app-choice(
                                v-model="choice.value"
                                type="radio"
                                label="Отсутствует"
                                radio-value="0"
                            )
                            app-choice(
                                v-model="choice.value"
                                type="radio"
                                label="Легко"
                                radio-value="1"
                            )
                            app-choice(
                                v-model="choice.value"
                                type="radio"
                                label="Умеренно"
                                radio-value="2"
                            )
                            app-choice(
                                v-model="choice.value"
                                type="radio"
                                label="Тяжело"
                                radio-value="3"
                            )
                .block-range.app-choice-group
                    .app-choice-group__label Площадь поражения (A)
                    .app-choice-group__list
                        app-choice(
                            v-for="variant in rangeVariantsList"
                            v-model="block.range"
                            type="radio"
                            :label="variant.name"
                            :radio-value="variant.value"
                        )
            .bottom
                .result(v-if="tempResult !== null") EASI = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import toFixed from '@/js/utils/toFixed';

export default {
    name: 'questionnaire-easi',

    mixins: [questionnaireMixin],

    data: () => ({
        rangeVariantsList: [
            { name: '0%', value: 0 },
            { name: '1-10%', value: 1 },
            { name: '10-29%', value: 2 },
            { name: '30-49%', value: 3 },
            { name: '50-69%', value: 4 },
            { name: '70-89%', value: 5 },
            { name: '90-100%', value: 6 },
        ],

        blocks: {
            H: {
                name: 'Голова, лицо и шея (H)',
                choices: [
                    { name: 'Эритема (E)', value: 0 },
                    { name: 'Инфильтрация (I)', value: 0 },
                    { name: 'Экскориация (Ex)', value: 0 },
                    { name: 'Лихенификация (L)', value: 0 } ,
                ],
                range: 0,
            },
            T: {
                name: 'Туловище, подмышечные впадины и пах (T)',
                choices: [
                    { name: 'Эритема (E)', value: 0 },
                    { name: 'Инфильтрация (I)', value: 0 },
                    { name: 'Экскориация (Ex)', value: 0 },
                    { name: 'Лихенификация (L)', value: 0 } ,
                ],
                range: 0,
            },
            UL: {
                name: 'Руки (UL)',
                choices: [
                    { name: 'Эритема (E)', value: 0 },
                    { name: 'Инфильтрация (I)', value: 0 },
                    { name: 'Экскориация (Ex)', value: 0 },
                    { name: 'Лихенификация (L)', value: 0 } ,
                ],
                range: 0,
            },
            LL: {
                name: 'Ноги (LL)',
                choices: [
                    { name: 'Эритема (E)', value: 0 },
                    { name: 'Инфильтрация (I)', value: 0 },
                    { name: 'Экскориация (Ex)', value: 0 },
                    { name: 'Лихенификация (L)', value: 0 } ,
                ],
                range: 0,
            },
        },
    }),

    methods: {
        calculate() {
            const sumH = this.blocks.H.choices.reduce((sum, item) => sum + Number(item.value), 0) * this.blocks.H.range;
            const sumT = this.blocks.T.choices.reduce((sum, item) => sum + Number(item.value), 0) * this.blocks.T.range;
            const sumUL = this.blocks.UL.choices.reduce((sum, item) => sum + Number(item.value), 0) * this.blocks.UL.range;
            const sumLL = this.blocks.LL.choices.reduce((sum, item) => sum + Number(item.value), 0) * this.blocks.LL.range;
            this.tempResult = toFixed(sumH*0.1 + sumT*0.3 + sumUL*0.2 + sumLL*0.4, 1);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-easi {
    .result-field {
        width: rem(65px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: rem(24px);
        }

        .block-range {
            .app-choice {
                width: rem(90px);
                margin-right: calc((100% - #{rem(90px)} * 3) / 2);

                &:nth-child(5n) {
                    margin-right: calc((100% - #{rem(90px)} * 3) / 2);
                }

                &:nth-child(3n) {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
