<template lang="pug">
    .therapy-block(:class="{'therapy-block--re-contact': isReContact}")
        .therapy-block__rows
            .therapy-block__row(
                v-for="(item, index) in therapy"
                v-if="!isReContact || !item.isFullField"
                :key="item.id"
                :class="{old: item.old, 'receives-same-volume': item.receivesSameVolume}"
            )
                app-choice(
                    v-if="isReContact && item.old"
                    v-model="item.receivesSameVolume"
                    @input="changeReceivesSameVolume(item)"
                    type="checkbox"
                    label="Получает<br> в прежнем объеме"
                )
                .row
                    app-select.therapy-block--drug(
                        v-model="item.drug"
                        :options="medicinesList"
                        searchable
                        @search-change="fetchMedicines"
                        noOptionsText="Начните вводить значение"
                        :label="isMobileOrTablet ? 'Получаемый препарат' : 'Получаемый препарат (начните вводить МНН)'"
                        :disabled="additionOnly && item.old"
                        :error="getFieldError(item, index, 'drug')"
                    )
                    app-text-field.therapy-block--dayDose(
                        v-model="item.dayDose"
                        label="Суточная доза"
                        is-float-number-with-slash
                        :disabled="item.dosageNotDetermined || additionOnly && item.old"
                        :error="getFieldError(item, index, 'dayDose')"
                    )
                    app-select.therapy-block--measurement(
                        v-model="item.measurement"
                        :options="measurements"
                        searchable
                        :disabled="item.dosageNotDetermined || additionOnly && item.old"
                        :error="getFieldError(item, index, 'measurement')"
                    )
                    app-text-field.therapy-block--onceDose(
                        v-model="item.onceDose"
                        label="Разовая доза"
                        is-float-number-with-slash
                        :disabled="item.dosageNotDetermined || additionOnly && item.old"
                        :error="getFieldError(item, index, 'onceDose')"
                    )
                    .app-choice-group.app-choice-group--center.therapy-block--not-determined
                        app-choice(
                            v-model="item.dosageNotDetermined"
                            type="checkbox"
                            label="Дозировка не определена"
                            :disabled="additionOnly && item.old"
                        )
                    app-dropper.forDesktop(
                        :class="{hidden: (isReContact || additionOnly) && item.old}"
                        @click="dropTherapy(index)"
                    )
                .row
                    app-text-field.therapy-block--multiplicity(
                        v-model="item.multiplicity"
                        label="Кратность"
                        is-natural-number
                        :disabled="additionOnly && item.old"
                        :error="getFieldError(item, index, 'multiplicity')"
                    )
                    app-link.app-link--dark.app-link--with-mobile-margin(no-pointer-events) раз (-а)
                    app-select.therapy-block--period(
                        v-model="item.period"
                        :options="periods"
                        :disabled="additionOnly && item.old"
                        :error="getFieldError(item, index, 'period')"
                    )
                    app-text-field.therapy-block--periodCustom(
                        v-if="item.period && (item.period.name.toLowerCase() === 'другая')"
                        v-model="item.periodCustom"
                        :disabled="additionOnly && item.old"
                        :error="getFieldError(item, index, 'periodCustom')"
                    )
                    app-select.therapy-block--administrationMethod(
                        v-model="item.administrationMethod"
                        label="Способ введения"
                        :options="administrationMethods"
                        :disabled="additionOnly && item.old"
                        :error="getFieldError(item, index, 'administrationMethod')"
                    )
                    app-datepicker.therapy-block--therapyStart(
                        v-model="item.therapyStart"
                        label="Инициация терапии"
                        :max-date="item.therapyEnd ? item.therapyEnd - 1 : null"
                        :disabled="additionOnly && item.old"
                        :error="getFieldError(item, index, 'therapyStart')"
                    )
                    app-datepicker.therapy-block--therapyEnd(
                        v-if="!isReContact || item.old"
                        v-model="item.therapyEnd"
                        clearable
                        :min-date="item.therapyStart ? item.therapyStart + 1 : null"
                        label="Окончание терапии"
                        :disabled="additionOnly && item.old && item.old_values.therapyEnd"
                        :error="getFieldError(item, index, 'therapyEnd')"
                    )
                    app-dropper.forMobile_or_p(
                        :class="{hidden: (isReContact || additionOnly) && item.old}"
                        @click="dropTherapy(index)"
                    )
                hr
        .br
        app-button(
            plus
            @click="addNewTherapy"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import medicinesList from '@/mixins/medicinesList';

export default {
    name: 'therapy-block',

    mixins: [medicinesList],

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        therapy: [],
        isMobileOrTablet: window.isMobileOrTablet,
    }),

    validations: {
        therapy: {
            $each: {
                drug: {
                    required,
                    uniqual(value, item) {
                        return !this.therapy.some(a => a.id !== item.id
                            && a.drug.id === item.drug.id
                            && item.therapyStart
                            && a.therapyStart === item.therapyStart);
                    },
                },
                dayDose: {
                    required(value, item) {
                        return value || item.dosageNotDetermined;
                    },
                },
                measurement: {
                    required(value, item) {
                        return value || item.dosageNotDetermined;
                    },
                },
                onceDose: {
                    required(value, item) {
                        return value || item.dosageNotDetermined;
                    },
                },

                multiplicity: { required },
                period: { required },
                periodCustom: {
                    required(value, item) {
                        return value || item.period?.name.toLowerCase() !== 'другая';
                    },
                },
                administrationMethod: { required },
                therapyStart: {
                    required,
                    valid(value, item) {
                        // Дата начала терапии с тем же препаратом
                        // не может быть раньше даты окончания какой-либо другой
                        const itemsWithEqualDrugs = this.therapy.filter(t => t.id !== item.id && t.drug.id === item.drug.id);
                        if (itemsWithEqualDrugs.some(i => item.therapyStart > i.therapyStart
                            && item.therapyStart < i.therapyEnd)) {
                            return false;
                        }
                        return true;
                    },
                },
                therapyEnd: {
                    required(value, item) {
                        if (value) return true;
                        if (this.isReContact && item.old && !item.receivesSameVolume) return false;

                        // Если 2 одинаковых препарата, то должны быть даты окончания терапии
                        const equalTherapy = this.therapy.filter(t => t.drug.id === item.drug.id);
                        if (equalTherapy.length - equalTherapy.filter(t => t.therapyEnd).length > 1) return false;

                        return true;
                    },
                },
            },
        },
    },

    computed: {
        ...mapGetters([
            'measurements',
            'periods',
            'administrationMethods',
        ]),
    },

    watch: {
        therapy(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.therapy = this.value;
        });
    },

    methods: {
        addNewTherapy() {
            this.therapy.push({
                id: uniqueId(),
                drug: '',
                dayDose: '',
                measurement: '',
                onceDose: '',
                dosageNotDetermined: false,

                multiplicity: '',
                period: '',
                periodCustom: '',
                administrationMethod: '',
                therapyStart: '',
                therapyEnd: '',
            });
            this.$v.$reset();
        },

        dropTherapy(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить терапию?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.therapy = this.therapy.filter((d, i) => i !== index);
                    }
                },
            });
        },

        changeReceivesSameVolume(item){
            if (!item.receivesSameVolume) {
                item.therapyEnd = +moment();
            } else {
                item.therapyEnd = '';
            }
        },

        getFieldError(item, index, field) {
            if (this.$v.therapy.$each.$iter[index].$dirty && !this.$v.therapy.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else if ((field === 'drug' || field === 'therapyStart')
                && !this.$v.therapy.$each.$iter[index].drug.uniqual) {
                return 'Препарат дублируется';
            } else if (field === 'therapyStart'
                && !this.$v.therapy.$each.$iter[index].therapyStart.valid) {
                return 'Недопустимая дата инициации';
            }
            return null;
        },

        getFieldsForSave() {
            return this.therapy.map(t => {
                const obj = {
                    medicine_id: t.drug.id,
                    dose_is_undefined: +t.dosageNotDetermined,

                    multiplicity: t.multiplicity,
                    period_id: t.period.id,
                    period_custom: t.periodCustom,
                    administration_method_id: t.administrationMethod.id,
                    start_at: moment(t.therapyStart).format('YYYY-MM-DD'),
                };
                if (!t.dosageNotDetermined) {
                    obj.day_dose = t.dayDose;
                    obj.measurement_id = t.measurement.id;
                    obj.once_dose = t.onceDose;
                }
                if (t.therapyEnd) {
                    obj.end_at = moment(t.therapyEnd).format('YYYY-MM-DD');
                }
                return obj;
            });
        },

        getFormattedSavedFields(therapy) {
            let fields = therapy.map(t => {
                const obj = {
                    id: t.id,
                    drug: {
                        ...t.medicine,
                        name: t.medicine.chemistry_name,
                    },
                    dayDose: t.day_dose || '',
                    measurement: t.measurement || '',
                    onceDose: t.once_dose || '',
                    dosageNotDetermined: Boolean(t.dose_is_undefined),

                    multiplicity: t.multiplicity,
                    period: t.period,
                    periodCustom: t.period_custom || '',
                    administrationMethod: t.administration_method,
                    therapyStart: +moment(t.start_at),
                    therapyEnd: t.end_at ? +moment(t.end_at) : '',
                    old: true,
                    isFullField: t.end_at, // Полностью заполнено и не надо показывать при повторном контакте
                    receivesSameVolume: true,
                };
                return {
                    ...obj,
                    old_values: { ...obj },
                };
            });
            fields.sort((f1, f2) => f1.therapyStart - f2.therapyStart);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.therapy-block {
    &__row:last-of-type hr {
        display: none;
    }

    .therapy-block{
        &--drug {
            width: rem(434px);
        }

        &--measurement,
        &--dayDose,
        &--onceDose,
        &--period {
            width: rem(145px);
        }

        &--multiplicity {
            width: rem(107px);
        }

        &--periodCustom {
            width: rem(250px);
        }

        &--administrationMethod {
            width: rem(203px);
        }

        &--therapyStart, &--therapyEnd {
            width: rem(215px);
        }
    }

    &--re-contact {
        .therapy-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 2rem 1rem 0;

            hr {
                margin-bottom: 0;
            }

            &:first-child {
                padding-top: 1rem;
            }

            &.old {
                background: $light-blue;

                .therapy-block {
                    &--drug,
                    &--dayDose,
                    &--measurement,
                    &--onceDose,
                    &--not-determined,
                    &--multiplicity,
                    &--period,
                    &--periodCustom,
                    &--administrationMethod,
                    &--therapyStart {
                        opacity: 0.5;

                        pointer-events: none;
                    }
                }
            }

            &.receives-same-volume .therapy-block {
                &--therapyEnd {
                    opacity: 0.5;

                    pointer-events: none;
                }
            }
        }
    }

    @include mobile_or_P {
        .therapy-block {
            &--measurement,
            &--dayDose,
            &--onceDose,
            &--period,
            &--multiplicity,
            &--not-determined {
                width: rem(153px);
            }

            &--dayDose,
            &--onceDose,
            &--multiplicity {
                margin-right: rem(20px);
            }

            &--not-determined label {
                white-space: initial;
            }

            &--drug,
            &--periodCustom,
            &--administrationMethod,
            &--therapyStart,
            &--therapyEnd {
                width: 100%;
            }
        }
    }
}
</style>
