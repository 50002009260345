<template lang="pug">
.app-dropper
    .app-dropper__inner(
        v-on="listeners"
    )
        dropper-icon
        span.forMobile_or_p Удалить
</template>

<script>
import dropperIcon from '@/assets/images/icons/dropper.svg';

export default {
    name: 'app-dropper',

    components: {
        dropperIcon,
    },

    computed: {
        listeners() {
            return Object.assign(
                {},
                this.$listeners,
            );
        },
    },
};
</script>
