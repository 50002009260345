export default (content, fileName, mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') => {
    const a = document.createElement('a');

    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(new Blob([content], {
            type: mimeType,
        }), fileName);
    } else if (URL && 'download' in a) {
        a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType,
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
};
