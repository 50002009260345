export const ROLES = {
    DOCTOR: 'Врач',
    DOCTOR_EDITOR: 'Врач-редактор',
    ADMIN: 'Администратор',
};

export const STATUSES = {
    active: 'Активный',
    disabled: 'Не активный',
    waiting_for_verification: 'Ожидает подтверждения',
};

export const DICTIONARIES = {
    'administration-methods': 'Способы введения',
    'allergens': 'Аллергокомпоненты',
    'cancellation-reasons': 'Причины отмены',
    'causes-of-exacerbations': 'Причины обострения',
    'drugs': 'Биологические препараты',
    'functional-tests': 'Функциональные тесты',
    'injuries': 'Травмы/Операции',
    'laboratory-indicators': 'Лабораторные показатели',
    'provocative-tests': 'Провокационные тесты',
    'measurements': 'Единицы измерения',
    'medical-organizations': 'Медицинские организации',
    'periods': 'Кратность (период)',
    'relatives': 'Родственники',
    'special-procedures': 'КТ и рентген',
    'specific-examinations': 'Специфические обследования',
};

export const SEX = {
    1: 'Муж',
    2: 'Жен',
};

export const RACE = {
    1: 'Европеоидная',
    3: 'Монголоидная',
    2: 'Негроидная',
};


export const CONTACT_TYPES = [
    {
        name: 'Визит',
        id: 'VISIT',
    },
    {
        name: 'Звонок',
        id: 'CALL',
    },
    {
        name: 'Видеосвязь',
        id: 'VIDEO_COMMUNICATION',
    },
];

export const ASTHMA_FORMS = [
    {
        name: 'Аллергическая атопическая',
        id: '1',
    },
    {
        name: 'Аллергическая неатопическая',
        id: '2',
    },
    {
        name: 'Неаллергическая',
        id: '3',
    },
];

export const ILLNESSES = {
    DERMATITIS: 'Атопический дерматит',
    ASTHMA: 'Бронхиальная астма',
    URTICARIA_MAIN: 'Хроническая крапивница',
    ALLERGIC_RHINITIS: 'Аллергический ринит',
    ALLERGIC_CONJUNCTIVITIS: 'Аллергический конъюнктивит',
    POLYPOID_RHINOSINUSITIS: 'Полипозный риносинусит',
    GERB: 'ГЭРБ',
    SIDE_DERMATITIS: 'Атопический дерматит (сопутствующее)',
    SIDE_ASTHMA: 'Бронхиальная астма (сопутствующее)',
    CHRONIC_URTICARIA: 'Хроническая крапивница (сопутствующее)',
    ANGIOOTEK: 'Ангиоотеки (сопутствующее)',
    ANGIOOTEK_URTICARIA_MAIN: 'Ангиоотеки',
};

export const HIVES_TYPES = [
    {
        name: 'Спонтанная',
        id: 'SPONTANEOUS',
    },
    {
        name: 'Индуцированная',
        id: 'INDUCED',
    },
    {
        name: 'Спонтанная + индуцированная',
        id: 'SPONTANEOUS_INDUCED',
    },
];

export const INDUCED_TYPES = {
    chassis: {
        name: 'Холодовая',
        value: false,
        dateStart: '',
        dateStartInaccurate: false,
        dateEnd: '',
    },
    thermal: {
        name: 'Тепловая',
        value: false,
        dateStart: '',
        dateStartInaccurate: false,
        dateEnd: '',
    },
    dermographic: {
        name: 'Дермографическая',
        value: false,
        dateStart: '',
        dateStartInaccurate: false,
        dateEnd: '',
    },
    cholinergic: {
        name: 'Холинэргическая',
        value: false,
        dateStart: '',
        dateStartInaccurate: false,
        dateEnd: '',
    },
    vibrating: {
        name: 'Вибрационная',
        value: false,
        dateStart: '',
        dateStartInaccurate: false,
        dateEnd: '',
    },
    sunny: {
        name: 'Солнечная',
        value: false,
        dateStart: '',
        dateStartInaccurate: false,
        dateEnd: '',
    },
    aquagenic:{
        name: 'Аквагенная',
        value: false,
        dateStart: '',
        dateStartInaccurate: false,
        dateEnd: '',
    },
    slow:{
        name: 'Замедленная от давления',
        value: false,
        dateStart: '',
        dateStartInaccurate: false,
        dateEnd: '',
    },
    contact:{
        name: 'Контактная',
        value: false,
        dateStart: '',
        dateStartInaccurate: false,
        dateEnd: '',
    },
};

export const FOOD_ALLERGIES_TYPES = [
    {
        name: 'Истинная',
        id: 'TRUE',
    },
    {
        name: 'Перекрестная',
        id: 'CROSS',
    },
];
