<template lang="pug">
    .questionnaire-snot22
        app-text-field.result-field(
            v-model="result"
            label="Результат"
            is-number
            :max="110"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет SNOT 22

            .block
                .block-question(v-for="(question, id) in questions")
                    .block-question__name {{id}}. {{ question.name }}
                    .app-choice-group
                        app-choice(
                            v-for="variant in variants"
                            v-model="question.value"
                            type="radio"
                            :label="variant.name"
                            :radio-value="variant.value"
                            :error="$v.$dirty && $v.questions.$each.$iter[id].value.$invalid"
                        )
                    app-choice(
                        v-model="question.significant"
                        type="checkbox"
                        label="Наиболее выраженный симптом"
                        :error="$v.$dirty && $v.questions.$each.$iter[id].significant.$invalid"
                        :disabled="!question.significant && significantQuestionsLength === 5"
                    )

            .bottom
                .result(v-if="tempResult !== null") SNOT 22 = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-snot22',

    mixins: [questionnaireMixin],

    data: () => ({
        questions: {
            1: { name: 'Выделения из носа (насморк)' },
            2: { name: 'Заложенность носа' },
            3: { name: 'Чихание' },
            4: { name: 'Ринорея' },
            5: { name: 'Кашель' },
            6: { name: 'Постназальное стекание' },
            7: { name: 'Густое отделяемое из носа' },
            8: { name: 'Заложенность ушей' },
            9: { name: 'Головокружение' },
            10: { name: 'Боль в ухе' },
            11: { name: 'Лицевая боль/ощущение давления' },
            12: { name: 'Снижение обоняния/вкуса' },
            13: { name: 'Трудности при засыпании' },
            14: { name: 'Ночные пробуждения' },
            15: { name: 'Отсутствие полноценного ночного сна' },
            16: { name: 'Разбитость после пробуждения' },
            17: { name: 'Утомляемость' },
            18: { name: 'Снижение работоспособности' },
            19: { name: 'Снижение концентрации внимания' },
            20: { name: 'Разочарованность/тревожность/раздражительность' },
            21: { name: 'Уныние' },
            22: { name: 'Растерянность' },
        },

        variants: [
            { name: 'Совсем не беспокоит', value: 0 },
            { name: 'Почти не беспокоит', value: 1 },
            { name: 'Незначительно беспокоит', value: 2 },
            { name: 'Значительно беспокоит', value: 3 },
            { name: 'Сильно беспокоит', value: 4 },
            { name: 'Крайне сильно беспокоит', value: 5 },
        ],
    }),

    validations: {
        questions: {
            $each: {
                value: { required },
                significant: {
                    required(value) {
                        return value || this.significantQuestionsLength >= 1;
                    },
                },
            },
        },
    },

    computed: {
        significantQuestions() {
            return Object.values(this.questions).filter(q => q.significant);
        },

        significantQuestionsLength() {
            return this.significantQuestions.length;
        },
    },

    created() {
        for (let id in this.questions) {
            this.$set(this.questions[id], 'value', null);
            this.$set(this.questions[id], 'significant', false);
        }
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = Object.values(this.questions).reduce((sum, question) => sum + question.value, 0);
        },

        save() {
            const significantQuestionsNames = this.significantQuestions
                .map(q => q.name)
                .join(', \n')
                .toLowerCase();

            this.result = this.tempResult;
            this.$emit('setProblems', significantQuestionsNames);

            this.$refs.modal.close();
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-snot22 {
    .result-field {
        width: rem(105px);

        @include mobile_or_P {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }

    .block-question {
        margin-bottom: rem(24px);

        .app-choice-group {
            margin-bottom: rem(8px);
        }
    }
}
</style>
