<template lang="pug">
    .page.patients-re-contact
        h2 Повторный контакт
        app-loader(v-if="pageLoading")
        template(v-else)
            re-contact-patient-data(
                ref="patientData"
                :last-data="patient"
            )
            .container.white-bg.border-r.shadow
                app-horizontal-tabs(
                    v-model="activeTabId"
                    :tabs="tabs"
                )
                fixed-patient-panel(
                    title="Повторный контакт"
                    :patient="patient"
                )
                component(
                    v-for="tab in tabs"
                    v-show="activeTabId === tab.id"
                    :is="tab.component"
                    :ref="tab.refName"
                    :last-data="patient"
                    :saveLoading="saveLoading"
                    :saveErrors="saveErrors"
                    @clearSaveError="clearSaveError"
                    @nextStep="nextStep"
                    @save="save"
                )
</template>

<script>
import AppHorizontalTabs from '@/components/app/app-horizontal-tabs/index';

import ReContactPatientData from '@/components/pages/patients/re-contact/ReContactPatientData';
import ReContactAtopicDermatitis from '@/components/pages/patients/re-contact/ReContactAtopicDermatitis';
import ReContactAsthma from '@/components/pages/patients/re-contact/ReContactAsthma';
import ReContactHives from '@/components/pages/patients/re-contact/RecontactHives';
import ReContactAllergyanamnesis from '@/components/pages/patients/re-contact/ReContactAllergyanamnesis';
import ReContactAnamnesis from '@/components/pages/patients/re-contact/ReContactAnamnesis';
import ReContactResearch from '@/components/pages/patients/re-contact/ReContactResearch';
import convertObjectToFormData from '@/js/utils/convertObjectToFormData';
import scrollToInvalidField from '@/js/utils/scrollToInvalidField';
import saveErrors from '@/mixins/saveErrors';
import FixedPatientPanel from '@/components/pages/patients/other/FixedPatientPanel';

export default {
    name: 'patients-re-contact',

    components: {
        FixedPatientPanel,
        ReContactPatientData,
        ReContactAtopicDermatitis,
        ReContactAsthma,
        ReContactHives,
        ReContactAllergyanamnesis,
        ReContactAnamnesis,
        ReContactResearch,
        AppHorizontalTabs,
    },

    mixins: [saveErrors],

    data: () => ({
        activeTabId: 0,

        patient: {},

        pageLoading: false,
        saveLoading: false,
    }),

    computed: {
        tabs() {
            return [
                {
                    id: 0,
                    name: 'Атопический дерматит',
                    component: 'ReContactAtopicDermatitis',
                    refName: 'atopicDermatitis',
                },
                {
                    id: 1,
                    name: 'Бронхиальная астма',
                    component: 'ReContactAsthma',
                    refName: 'asthma',
                },
                {
                    id: 2,
                    name: 'Крапивница',
                    component: 'ReContactHives',
                    refName: 'hives',
                },
                {
                    id: 3,
                    name: 'Аллергоанамнез',
                    component: 'ReContactAllergyanamnesis',
                    refName: 'allergyanamnesis',
                },
                {
                    id: 4,
                    name: 'Анамнез жизни',
                    component: 'ReContactAnamnesis',
                    refName: 'anamnesis',
                },
                {
                    id: 5,
                    name: 'Исследования',
                    component: 'ReContactResearch',
                    refName: 'research',
                },
            ];
        },
    },

    created() {
        this.fetchPatient();
        this.$store.dispatch('fetchRelatives');
        this.$store.dispatch('fetchAllergens');
        this.$store.dispatch('fetchMeasurements');
        this.$store.dispatch('fetchPeriods');
        this.$store.dispatch('fetchAdministrationMethods');
        this.$store.dispatch('fetchInjuries');
        this.$store.dispatch('fetchDrugs');
        this.$store.dispatch('fetchCancellationReasons');
        this.$store.dispatch('fetchCausesExacerbations');
        this.$store.dispatch('fetchUserList');
        this.$store.dispatch('fetchLaboratoryIndicators');
        this.$store.dispatch('fetchProvocativeTests');
        this.$store.dispatch('fetchFunctionalTests');
        this.$store.dispatch('fetchSpecificExaminations');
        this.$store.dispatch('fetchSpecialProcedures');
    },

    methods: {
        nextStep() {
            this.activeTabId++;
            setTimeout(() => {
                this.$scrollTo({ y: 0 });
            });
        },

        async save() {
            if (!this.$refs.patientData.checkValid()) {
                setTimeout(scrollToInvalidField);
                return;
            }
            for (let t of this.tabs) {
                if (!this.$refs[t.refName][0].checkValid()) {
                    this.activeTabId = t.id;
                    setTimeout(scrollToInvalidField);
                    return;
                }
            }

            if (this.saveLoading) return;

            this.saveErrors = {};
            this.saveLoading = true;

            try {
                let fd = {
                    ...this.$refs.patientData.getFormFields(),
                    ...this.$refs.atopicDermatitis[0].getFormFields(),
                    ...this.$refs.asthma[0].getFormFields(),
                    ...this.$refs.hives[0].getFormFields(),
                    ...this.$refs.allergyanamnesis[0].getFormFields(),
                    ...this.$refs.anamnesis[0].getFormFields(),
                    ...this.$refs.research[0].getFormFields(),
                    version: this.patient.version,
                };
                await this.axios.post(`patients/${this.$route.params.id}`, convertObjectToFormData(fd));
                localStorage.removeItem(`re-contact-patient-data-${this.$route.params.id}`);
                this.$router.push({ name: 'Patients' });
            } catch (e) {
                this.setSaveErrors(e?.response);
            } finally {
                this.saveLoading = false;
            }
        },

        async fetchPatient() {
            this.pageLoading = true;
            try {
                const { data } = await this.axios.get(`patients/${this.$route.params.id}`);
                this.patient = data;
            } catch (e) {
                this.$router.replace('/not-found');
            } finally {
                this.pageLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.patients-re-contact > .container {
    position: relative;
}
</style>
