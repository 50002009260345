<template lang="pug">
    .default-layout
        layout-header
        .default-layout__wrapper
            layout-sidebar
            .default-layout__page
                slot
</template>

<script>
import LayoutHeader from '@/components/layouts/LayoutHeader';
import LayoutSidebar from '@/components/layouts/LayoutSidebar';

export default {
    name: 'default-layout',

    components: {
        LayoutHeader,
        LayoutSidebar,
    },
};
</script>
