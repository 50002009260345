<template lang="pug">
    .questionnaires-hives(:class="{'questionnaires-hives--re-contact': isReContact}")
        .questionnaires-hives__row(
            v-for="(item, index) in questionnaires"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-datepicker.questionnaires-hives--date(
                    v-model="item.date"
                    label="Дата"
                    :disabled-dates="disabledDates"
                    :error="getFieldError(item, index, 'date')"
                    :disabled="additionOnly && item.old"
                )

                questionnaire-uas7(
                    v-model="item.UAS7"
                    :error="getFieldError(item, index, 'UAS7')"
                    :disabled="additionOnly && item.old && item.old_values.UAS7"
                )

                questionnaire-uct(
                    v-model="item.UCT"
                    :error="getFieldError(item, index, 'UCT')"
                    :disabled="additionOnly && item.old && item.old_values.UCT"
                )

                questionnaire-dlqi(
                    v-model="item.DLQI"
                    :error="getFieldError(item, index, 'DLQI')"
                    :disabled="additionOnly && item.old && item.old_values.DLQI"
                )

                questionnaire-cu-q2ol(
                    v-model="item.CUQ2OL"
                    :error="getFieldError(item, index, 'CUQ2OL')"
                    :disabled="additionOnly && item.old && item.old_values.CUQ2OL"
                )

                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropQuestionnaires(index)"
                )
            hr.narrow(v-if="index < questionnaires.length - 1")
        .br
        app-button(
            plus

            @click="addNewQuestionnaires()"
        ) Добавить
</template>

<script>
import QuestionnaireUas7 from '@/components/pages/patients/questionnaires/modals/UAS7';
import QuestionnaireUct from '@/components/pages/patients/questionnaires/modals/UCT';
import QuestionnaireDlqi from '@/components/pages/patients/questionnaires/modals/DLQI';
import QuestionnaireCuQ2ol from '@/components/pages/patients/questionnaires/modals/CU-Q2OL';

import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
const requiredField = (value, item) => {
    return item.UAS7 || item.UCT || item.DLQI || item.CUQ2OL;
};

export default {
    name: 'questionnaires-hives',

    components: {
        QuestionnaireUas7,
        QuestionnaireUct,
        QuestionnaireDlqi,
        QuestionnaireCuQ2ol,
    },

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },

        hasHives: {
            type: Boolean,
            default: true,
        },
    },

    data: () => ({
        questionnaires: [],
        canActualize: true,
    }),

    validations: {
        questionnaires: {
            $each: {
                date: { required },
                UAS7: { required: requiredField },
                UCT: { required: requiredField },
                DLQI: { required: requiredField },
                CUQ2OL: { required: requiredField },
            },
        },
    },

    computed: {
        disabledDates() {
            return this.questionnaires.map(e => e.date);
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.value.length) {
                this.questionnaires = this.value;
            }
            setTimeout(() => { // для того, чтобы при повторном контакте сначала добавились новые опросники, а потом уже ставился вотчер
                this.$eventBus.$on('actualizeQuestionnaires', this.actualizeQuestionnaires);
                this.$watch('questionnaires', (value) => {
                    this.$emit('input', value);
                    if (this.canActualize) {
                        this.$eventBus.$emit('actualizeQuestionnaires', this.questionnaires);
                    }
                }, { deep: true });
            });
        });
    },

    methods: {
        actualizeQuestionnaires(questionnaires) {
            if (!this.hasHives) return;
            this.canActualize = false;
            questionnaires.forEach((q, ind) => {
                if (!this.questionnaires[ind]) {
                    this.addNewQuestionnaires();
                }
                this.questionnaires[ind].date = q.date;
                this.questionnaires[ind].DLQI = q.DLQI;
            });
            this.questionnaires = this.questionnaires.slice(0, questionnaires.length);
            setTimeout(() => {
                this.canActualize = true;
            });
        },

        addNewQuestionnaires(date = '') {
            this.questionnaires.push({
                id: uniqueId(),
                date: date,
                UAS7: '',
                UCT: '',
                DLQI: '',
                CUQ2OL: '',
            });
            this.$v?.$reset();
        },

        dropQuestionnaires(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить опросник?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.questionnaires = this.questionnaires.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.questionnaires.$each.$iter[index].$dirty && !this.$v.questionnaires.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.questionnaires.map(d => ({
                date_at: moment(d.date).format('YYYY-MM-DD'),
                data: {
                    UAS7: d.UAS7,
                    UCT: d.UCT,
                    DLQI: d.DLQI,
                    CUQ2OL: d.CUQ2OL,
                },
            }));
        },

        getFormattedSavedFields(questionnaires) {
            let fields = questionnaires.map(d => {
                const obj = {
                    id: d.id,
                    date: +moment(d.date_at),
                    UAS7: d.data.UAS7 || '',
                    UCT: d.data.UCT || '',
                    DLQI: d.data.DLQI || '',
                    CUQ2OL: d.data.CUQ2OL || '',
                    old: true,
                };
                return {
                    ...obj,
                    old_values: { ...obj },
                };
            });
            fields.sort((f1, f2) => f1.date - f2.date);
            return fields;
        },
    },
};
</script>

<style lang="scss">
.questionnaires-hives {
    .questionnaires-hives--date {
        width: rem(168px);
    }
    &--re-contact {
        .questionnaires-hives__row.old {
            display: none;
        }
    }

    @include mobile_or_P {
        .questionnaires-hives--date {
            width: 100%;
        }
    }
}
</style>
