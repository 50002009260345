<template lang="pug">
    .questionnaire-uct
        app-text-field.result-field(
            v-model="result"
            label="UCT"
            is-number
            :max="16"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет UCT

            .block
                .block-question(v-for="(question, id) in questions")
                    .block-question__name(v-html="question.name")
                    .app-choice-group
                        app-choice(
                            v-for="answer in question.answers"
                            v-model="question.value"
                            type="radio"
                            :label="answer.name"
                            :radio-value="answer.value"
                            :error="$v.$dirty && $v.questions.$each.$iter[id].value.$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") UCT = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';

export default {
    name: 'questionnaire-uct',

    mixins: [questionnaireMixin],

    data: () => ({
        questions: {
            1: {
                name: 'Насколько сильно Вас беспокоили за&nbsp;прошедшие 4&nbsp;недели проявления крапивницы (зуд, волдыри и/или отеки)?',
                answers: [
                    { name: 'очень сильно', value: 0 },
                    { name: 'сильно', value: 1 },
                    { name: 'средне', value: 2 },
                    { name: 'мало', value: 3 },
                    { name: 'не беспокоили', value: 4 },
                ],
                value: null,
            },
            2: {
                name: 'Насколько сильно за&nbsp;последние 4&nbsp;недели крапивница ухудшила качество вашей жизни?',
                answers: [
                    { name: 'очень сильно', value: 0 },
                    { name: 'сильно', value: 1 },
                    { name: 'средне', value: 2 },
                    { name: 'мало', value: 3 },
                    { name: 'не ухудшила', value: 4 },
                ],
                value: null,
            },
            3: {
                name: 'Как часто за&nbsp;последние 4&nbsp;недели проводимое лечение было недоступным для контроля над крапивницей?',
                answers: [
                    { name: 'очень часто', value: 0 },
                    { name: 'часто', value: 1 },
                    { name: 'периодически', value: 2 },
                    { name: 'редко', value: 3 },
                    { name: 'никогда', value: 4 },
                ],
                value: null,
            },
            4: {
                name: 'Насколько успешно Вам удавалось в&nbsp;целом контролировать крапивницу за&nbsp;последние 4&nbsp;недели?',
                answers: [
                    { name: 'вообще не удавалось', value: 0 },
                    { name: 'сильно', value: 1 },
                    { name: 'средне', value: 2 },
                    { name: 'хорошо', value: 3 },
                    { name: 'полностью', value: 4 },
                ],
                value: null,
            },
        },
    }),

    validations: {
        questions: {
            $each: {
                value: { required },
            },
        },
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = Object.values(this.questions).reduce((sum, question) => sum + question.value, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-uct {
    .result-field {
        width: rem(66px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
