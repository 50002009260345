<template lang="pug">
    .drugs-dictionary
        .edit-dictionary__top
            .edit-dictionary__name Биологические препараты
            p Данный справочник служит для указания препаратов таргетной терапии. Доза, единицы измерения и кратность могут быть указаны только в соответствии с приведенными в справочнике значениями. Если поле оставлено пустым (например, не указано значение дозы), то пи заполнении карты пациента можно будет указать любое значение.
        app-loader(v-if="pageLoading")
        template(v-else)
            form.edit-dictionary__form(@submit.prevent="submit")
                app-button(tag="div" plus @click="addNewItem('before')") Добавить
                .br
                .row(
                    v-for="(item, index) in dictionary"
                    v-show="!item.deleted"
                )
                    app-text-field.drugs-dictionary--name(
                        v-model="item.name"
                        label="Название препарата"
                        :class="{hide: hideName(item, index)}"
                        :error="getFieldError(item, index, 'name')"
                    )
                    app-text-field.drugs-dictionary--dose(
                        v-model.number="item.dose"
                        label="Доза"
                        is-natural-number
                    )
                    app-select.drugs-dictionary--measurement(
                        v-model="item.measurement"
                        label="Единицы"
                        clearable
                        :options="measurementsList"
                    )
                    app-text-field.drugs-dictionary--period-value(
                        v-model.number="item.period_value"
                        label="Кратность"
                        is-natural-number
                    )
                    app-select.drugs-dictionary--period(
                        v-model="item.period"
                        label="Период"
                        clearable
                        :options="periodsList"
                    )
                    app-plus(
                        :class="{hidden: !showPlus(item, index)}"
                        @click="addNewItem(index+1, item)"
                    )
                    app-dropper(@click="dropItem(item)")
                    hr.solid.forMobile_or_p(:class="{hidden: !showPlus(item, index)}")
                .br
                app-button(tag="div" plus @click="addNewItem") Добавить

            .edit-dictionary__bottom
                app-button(
                    :loading="loading"
                    large
                    @click="submit"
                ) Сохранить изменения
                app-button(
                    tag="router-link"
                    :to="{name: 'Dictionaries'}"
                    color="white"
                    large
                ) Закрыть без сохранения
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'drugs-dictionary',

    validations: {
        dictionary: {
            $each: {
                name: { required },
            },
        },
    },

    data: () => ({
        dictionary: [],

        pageLoading: false,
        loading: false,
    }),

    computed: {
        dictionaryList() {
            const drugs = this.$store.getters.drugs;
             drugs.sort((d1, d2) => {
                if (d1.name > d2.name) {
                    return 1;
                } else if (d1.name < d2.name) {
                    return -1;
                }
                return 0;
            });
            return drugs;
        },
        measurementsList() {
            return this.$store.getters.measurements;
        },
        periodsList() {
            return this.$store.getters.periods;
        },
    },

    async created() {
        this.pageLoading = true;
        await this.$store.dispatch('fetchMeasurements');
        await this.$store.dispatch('fetchPeriods');
        await this.$store.dispatch('fetchDrugs');

        this.dictionary = this.dictionaryList.map(d => {
            const measurement = this.measurementsList.find(m => m.id === d.measurement_id);
            const period = this.periodsList.find(m => m.id === d.period_id);
            return {
                ...d,
                measurement,
                period,
                oldName: d.name,
                oldDose: d.dose,
                oldMeasurement: measurement,
                oldPeriodValue: d.period_value,
                oldPeriod: period,
                deleted: false,
                error: null,
            };
        });
        this.pageLoading = false;
    },

    methods: {
        addNewItem(position, item) {
            const row = {
                name: '',
                dose: '',
                measurement: null,
                period_value: '',
                period: null,
                deleted: false,
                isNew: true,
                error: null,
            };
            if (position === 'before') {
                this.dictionary.unshift(row);
            } else if (typeof position === 'number') {
                row.name = item.name;
                this.dictionary.splice(position, 0, row);
            } else {
                this.dictionary.push(row);
            }
            this.$v.dictionary.$reset();
        },

        dropItem(item) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        item.deleted = true;
                        this.dictionary = this.dictionary.filter(d => !d.isNew || !d.deleted);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.dictionary.$each.$iter[index].$dirty && !this.$v.dictionary.$each.$iter[index][field].required) {
                if (field === 'period_value') {
                    return 'Заполните поле';
                }
                return 'Поле обязательно для заполнения';
            } else if (field === 'name') {
                return item.error;
            } else {
                return null;
            }
        },

        async submit() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            this.loading = true;
            try {
                await Promise.all(this.dictionary.map((d, ind) => {
                    d.error = null;
                    const fd = new FormData;
                    fd.append('name', d.name);
                    fd.append('dose', d.dose || '');
                    fd.append('measurement_id', d.measurement?.id || '');
                    fd.append('period_value', d.period_value || '');
                    fd.append('period_id', d.period?.id || '');
                    if (d.isNew && !d.deleted) {
                        return this.axios.post(`dictionaries/drugs/create`, fd)
                            .then(data => {
                                const measurement = this.measurementsList.find(m => m.id === data.drug.measurement_id);
                                const period = this.periodsList.find(m => m.id === data.drug.period_id);
                                this.dictionary[ind] = {
                                    ...data.drug,
                                    measurement,
                                    period,
                                    oldName: data.drug.name,
                                    oldDose: data.drug.dose,
                                    oldMeasurement: measurement,
                                    oldPeriodValue: data.drug.period_value,
                                    oldPeriod: period,
                                    deleted: false,
                                    error: null,
                                };
                                console.log(this.dictionary[ind]);
                            })
                            .catch(e=> {
                                d.error = e.response?.data?.error;
                            });
                    } else if (!d.isNew && d.deleted) {
                        return this.axios.delete(`dictionaries/drugs/${d.id}`)
                            .catch(e=> {
                                d.deleted = false;
                                d.error = e.response?.data?.error;
                            });
                    } else if (d.name !== d.oldName
                        || d.dose !== d.oldDose
                        || d.measurement?.id !== d.oldMeasurement?.id
                        || d.period_value !== d.oldPeriodValue
                        || d.period?.id !== d.oldPeriod?.id) {
                        return this.axios.post(`dictionaries/drugs/${d.id}`, fd)
                            .catch(e=> {
                                d.error = e.response?.data?.error;
                            });
                    }
                }));
                this.$store.dispatch('fetchDrugs', true);
                if (!this.dictionary.find(d => d.error)) {
                    this.$router.push({ name: 'Dictionaries' });
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },

        hideName(item, index) {
            return this.dictionary.slice(0, index).some(d => d.name===item.name && !d.deleted);
        },

        showPlus(item, index) {
            return !this.dictionary.slice(index+1).some(d => d.name===item.name && !d.deleted);
        },
    },
};
</script>

<style lang="scss">
.drugs-dictionary {
    padding-bottom: rem(20px);

    .app-text-field.hide {
        opacity: 0;

        pointer-events: none;
    }

    &--name {
        max-width: rem(600px);
    }

    &--dose, &--measurement, &--period {
        max-width: rem(200px);
    }

    &--period-value {
        max-width: rem(107px);
    }

    @include mobile_or_P {
        .row:nth-last-child(3) hr {
            display: none;
        }

        .app-text-field.hide {
            display: none;
        }

        .app-plus {
            order: 2;
        }

        hr {
            order: 3;

            width: 100%;
            margin: 2rem 0 0;
        }

        &--dose, &--measurement, &--period, &--period-value {
            max-width: calc(50% - 0.5rem);
        }

        .drugs-dictionary--dose, .drugs-dictionary--period-value {
            margin-right: 1rem;
        }
    }
}
</style>
