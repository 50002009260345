<template lang="pug">
    .questionnaire-tnss
        app-text-field.result-field(
            v-model="result"
            label="Результат"
            is-float-number
            :max="12"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет TNSS

            .block(v-for="(day, number) in days")
                .block__name День {{ number}}
                .block-choices.app-choice-group(v-for="(question, questionId) in questions")
                    .app-choice-group__label {{ question.name }}
                    .app-choice-group__list
                        app-choice(
                            v-for="variant in variants"
                            v-model="day['q'+questionId]"
                            type="radio"
                            :label="variant.name"
                            :radio-value="variant.value"
                            :error="$v.$dirty && $v.days.$each.$iter[number]['q'+questionId].$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") TNSS = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

import { required } from 'vuelidate/lib/validators';
import toFixed from '@/js/utils/toFixed';

export default {
    name: 'questionnaire-tnss',

    mixins: [questionnaireMixin],

    data: () => ({
        questions: {
            1: { name: 'Чихание' },
            2: { name: 'Водянистые выделения из носа' },
            3: { name: 'Заложенность носа' },
            4: { name: 'Зуд в полости носа' },
        },

        variants: [
            { name: 'Cимптомы отсутствуют', value: 0 },
            { name: 'Легкие симптомы', value: 1 },
            { name: 'Симптомы средней силы', value: 2 },
            { name: 'Выраженные проявления', value: 3 },
        ],

        days: {
            1: { q1: null, q2: null, q3: null, q4: null },
            2: { q1: null, q2: null, q3: null, q4: null },
            3: { q1: null, q2: null, q3: null, q4: null },
            4: { q1: null, q2: null, q3: null, q4: null },
            5: { q1: null, q2: null, q3: null, q4: null },
            6: { q1: null, q2: null, q3: null, q4: null },
            7: { q1: null, q2: null, q3: null, q4: null },
        },
    }),

    validations: {
        days: {
            $each: {
                q1: { required },
                q2: { required },
                q3: { required },
                q4: { required },
            },
        },
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = toFixed(Object.values(this.days).reduce((sum, day) => {
                return sum + day.q1 + day.q2 + day.q3 + day.q4;
            }, 0) / 7, 1);

        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-tnss {
    .result-field {
        width: rem(105px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
