<template lang="pug">
    .hives-induced-types-block(v-show="showBlock")
        .row
            .app-choice-group
                .app-choice-group__label Вид индуцированной крапивницы
                .app-choice-group__list.hives-induced-types-block__types
                    template(v-for="type in inducedTypes")
                        app-choice.hives-induced-types-block__type(
                            :value="type.value"
                            type="checkbox"
                            :label="type.name"
                            :class="{ 'hives-induced-types-block__type--active': activeTypeName === type.name }"
                            :error="$v.$dirty && !$v.inducedTypes.required"
                            :disabled="(additionOnly || isReContact) && type.old"
                            @click.native.prevent="changeType(type)"
                        )
                        .row(
                            v-if="activeTypeName === type.name"
                            :key="type.name"
                        )
                            app-datepicker.hives-induced-types-block__date-start(
                                v-model="type.dateStart"
                                :inaccurate="type.dateStartInaccurate"
                                label="Дата начала"
                                :disabled="additionOnly"
                            )
                            .app-choice-group.app-choice-group--center
                                app-choice(
                                    v-model="type.dateStartInaccurate"
                                    type="checkbox"
                                    label="Неточная дата"
                                    :disabled="additionOnly"
                                )
                            app-datepicker.hives-induced-types-block__date-end(
                                v-model="type.dateEnd"
                                label="Дата окончания"
                                :disabled="additionOnly"
                            )
</template>

<script>
import { INDUCED_TYPES } from '@/js/const';
import { cloneDeep } from 'lodash';
import moment from 'moment';

export default {
    name: 'hives-induced-types-block',

    props: {
        value: {
            type: Object,
            default: null,
        },

        hivesType: {
            type: Object,
            default: null,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        inducedTypes: INDUCED_TYPES,
        activeTypeName: null,
    }),

    validations: {
        inducedTypes: {
            required(value) {
                return !this.showBlock ||Object.values(value).some(v => v.value);
            },
        },
    },

    computed: {
        showBlock() {
            return this.hivesType?.id === 'INDUCED' || this.hivesType?.id === 'SPONTANEOUS_INDUCED';
        },
    },

    mounted() {
        this.$nextTick(() => {
            if (this.value) {
                this.inducedTypes = this.value;
            }
        });
    },

    methods: {
        changeType(type) {
            if (!type.value) {
                type.value = true;
                this.activeTypeName = type.name;
            } else if (this.activeTypeName !== type.name) {
                type.value = true;
                this.activeTypeName = type.name;
            } else {
                type.value = false;
                this.activeTypeName = null;
            }
        },

        getFieldsForSave() {
            if (!this.hivesType || this.hivesType.id === 'SPONTANEOUS') {
                return Object.keys(this.inducedTypes).reduce((result, typeId) => {
                    result[typeId] = 0;
                    return result;
                }, {});
            }
            return Object.keys(this.inducedTypes).reduce((result, typeId) => {
                const type = this.inducedTypes[typeId];
                result[typeId] = +type.value;
                if (+type.value) {
                    if (type.dateStart) {
                        result[`${typeId}_date_start`] = moment(type.dateStart).format('YYYY-MM-DD');
                    }
                    result[`${typeId}_date_start_inaccurate`] = +type.dateStartInaccurate;
                    if (type.dateEnd) {
                        result[`${typeId}_date_end`] = moment(type.dateEnd).format('YYYY-MM-DD');
                    }
                }
                return result;
            }, {});
        },

        getFormattedSavedFields(options) {
            const types = cloneDeep(INDUCED_TYPES);
            for (let typeId in types) {
                types[typeId].value = Boolean(options[typeId]);
                types[typeId].dateStart = +moment(options[`${typeId}_date_start`]) || '';
                types[typeId].dateStartInaccurate = Boolean(options[`${typeId}_date_start_inaccurate`]);
                types[typeId].dateEnd = +moment(options[`${typeId}_date_end`]) || '';
                types[typeId].old = Boolean(options[typeId]);
            }
            return types;
        },
    },
};
</script>

<style lang="scss">
.hives-induced-types-block {
    .hives-induced-types-block {
        &__types {
            display: flex;
            flex-wrap: wrap;

            .row {
                order: 10;

                width: 100%;
            }

            .hives-induced-types-block__type {
                margin-bottom: rem(24px);

                &--active {
                    text-decoration: underline;
                }
            }
        }

        &__date-start,
        &__date-end {
            width: rem(220px);
        }
    }

    @include mobile_or_P {
        margin-bottom: rem(16px);

        .hives-induced-types-block {
            &__types {
                flex-direction: column;

                .row {
                    order: initial;

                    margin-bottom: rem(8px);
                }

                .hives-induced-types-block__type {
                    margin-bottom: rem(16px);
                }
            }

            &__date-start,
            &__date-end {
                width: 100%;
            }
        }
    }
}
</style>
