<template lang="pug">
    .injuries-block(:class="{'injuries-block--re-contact': isReContact, 'injuries-block--addition-only': additionOnly}")
        .injuries-block__row(
            v-for="(item, index) in injuries"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-text-field.injuries-block--name(
                    v-model="item.name"
                    :options="injuriesList"
                    is-select
                    allow-custom-value
                    label="Операция / травма"
                    :error="getFieldError(item, index, 'name')"
                )
                app-text-field.injuries-block--year(
                    v-model="item.year"
                    v-mask="'####'"
                    label="Год"
                    :error="getFieldError(item, index, 'year')"
                )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropInjury(index)"
                )
            hr.narrow(v-if="index < injuries.length - 1")
        .br
        app-button(
            plus
            @click="addNewInjury"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'injuries-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        injuries: [],
    }),

    validations: {
        injuries: {
            $each: {
                name: { required },
                year: { required },
            },
        },
    },

    computed: {
        injuriesList() {
            return this.$store.getters.injuries;
        },
    },

    watch: {
        injuries(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.injuries = this.value;
        });
    },

    methods: {
        addNewInjury() {
            this.injuries.push({
                id: uniqueId(),
                name: '',
                year: '',
            });
            this.$v.$reset();
        },

        dropInjury(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.injuries = this.injuries.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.injuries.$each.$iter[index].$dirty && !this.$v.injuries.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.injuries.map(d => {
                const obj = {
                    year: d.year,
                };
                const injuriesFromList = this.injuriesList.find(a => a.name === d.name);
                if (injuriesFromList) {
                    obj.injuries_id = injuriesFromList.id;
                } else {
                    obj.custom_injury = d.name;
                }
                return obj;
            });
        },

        getFormattedSavedFields(injuries) {
            return injuries.map(d => ({
                id: d.id,
                name: d.injury || d.custom_injury,
                year: d.year,
                old: true,
            }));
        },
    },
};
</script>

<style lang="scss">
.injuries-block {
    .injuries-block {
        &--name {
            width: rem(438px);
        }

        &--year {
            width: rem(156px);
        }
    }

    &--re-contact {
        .injuries-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 1rem 1rem 0;

            &:first-child {
                margin-top: -1rem;
            }

            hr {
                margin-bottom: 0;
            }

            &.old {
                background: $light-blue;

                .app-text-field {
                    opacity: 0.5;

                    pointer-events: none;
                }
            }
        }
    }

    &--addition-only {
        .injuries-block__row.old {
            .app-text-field {
                opacity: 0.5;

                pointer-events: none;
            }
        }
    }

    @include mobile_or_P {
        .injuries-block {
            &--year {
                width: 100%;
            }
        }
    }
}
</style>
