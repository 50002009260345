<template lang="pug">
    .re-contact-anamnesis
        app-toggler(
            v-model="sideDermatitis.openBlock"
            label="Атопический дерматит (сопутствующее)"
            :disabled="sideDermatitis.old"
        )
        div(v-show="sideDermatitis.openBlock")
            .row
                app-datepicker.re-contact-anamnesis--date(
                    v-model="sideDermatitis.date"
                    :inaccurate="sideDermatitis.dateInaccurate"
                    label="Установка диагноза"
                    :error="$v.$dirty && !$v.sideDermatitis.date.required ? 'Заполните поле' : ''"
                    :disabled="sideDermatitis.old"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="sideDermatitis.dateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="sideDermatitis.old"
                    )
            h4 Терапия дерматита
            therapy-block(
                v-model="sideDermatitis.therapy"
                ref="sideDermatitisTherapy"
                is-re-contact
            )
            .row.br
            h4 Опросники
            questionnaires-dermatitis(
                v-model="sideDermatitis.questionnaires"
                ref="sideDermatitisQuestionnaires"
                is-re-contact
                :has-dermatitis="sideDermatitis.openBlock"
            )

        app-toggler(
            v-model="sideAsthma.openBlock"
            label="Бронхиальная астма (сопутствующее)"
            :disabled="sideAsthma.old"
        )
        div(v-show="sideAsthma.openBlock")
            .row
                app-datepicker.re-contact-anamnesis--date(
                    v-model="sideAsthma.date"
                    :inaccurate="sideAsthma.dateInaccurate"
                    label="Установка диагноза"
                    :error="$v.$dirty && !$v.sideAsthma.date.required ? 'Заполните поле' : ''"
                    :disabled="sideAsthma.old"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="sideAsthma.dateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="sideAsthma.old"
                    )
            h4 Терапия дерматита
            therapy-block(
                v-model="sideAsthma.therapy"
                ref="sideAsthmaTherapy"
                is-re-contact
            )
            .row.br
            h4 Опросники
            questionnaires-asthma(
                v-model="sideAsthma.questionnaires"
                ref="sideAsthmaQuestionnaires"
                is-re-contact
            )

        app-toggler(
            v-model="sideHives.openBlock"
            label="Хроническая крапивница (сопутствующее)"
            :disabled="sideHives.old"
        )
        div(v-show="sideHives.openBlock")
            .row
                app-datepicker.re-contact-anamnesis--date(
                    v-model="sideHives.date"
                    :inaccurate="sideHives.dateInaccurate"
                    label="Установка диагноза"
                    :error="$v.$dirty && !$v.sideHives.date.required ? 'Заполните поле' : ''"
                    :disabled="sideHives.old"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="sideHives.dateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="sideHives.old"
                    )
                app-datepicker.re-contact-anamnesis--date(
                    v-model="sideHives.symptomsDate"
                    :inaccurate="sideHives.symptomsDateInaccurate"
                    label="Первые симптомы"
                    :error="$v.$dirty && !$v.sideHives.symptomsDate.required ? 'Заполните поле' : ''"
                    :disabled="sideHives.old"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="sideHives.symptomsDateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="sideHives.old"
                    )
                app-select.re-contact-anamnesis--type(
                    v-model="sideHives.type"
                    :options="hivesTypes"
                    label="Вид хронической крапивницы"
                    :error="$v.$dirty && !$v.sideHives.type.required ? 'Заполните поле' : ''"
                )
            .br
            hives-induced-types-block(
                v-model="sideHives.inducedTypes"
                ref="sideHivesInducedTypes"
                :hives-type="sideHives.type"
                is-re-contact
            )

            h4 Терапия крапивницы
            therapy-block(
                v-model="sideHives.therapy"
                ref="sideHivesTherapy"
                is-re-contact
            )

            h4 Обострение крапивницы
            exacerbations-block(
                v-model="sideHives.exacerbations"
                ref="sideHivesExacerbations"
                is-re-contact
            )
            .row.br

            h4 Опросники (Крапивница)
            questionnaires-hives(
                v-model="sideHives.questionnaires"
                ref="sideHivesQuestionnaires"
                is-re-contact
                :has-hives="sideHives.openBlock"
            )
        app-toggler(
            v-model="allergicRhinitis.openBlock"
            label="Аллергический ринит"
            :disabled="allergicRhinitis.old"
        )
        div(v-show="allergicRhinitis.openBlock")
            .row
                app-datepicker.re-contact-anamnesis--date(
                    v-model="allergicRhinitis.date"
                    :inaccurate="allergicRhinitis.dateInaccurate"
                    label="Установка диагноза"
                    :error="$v.$dirty && !$v.allergicRhinitis.date.required ? 'Заполните поле' : ''"
                    :disabled="allergicRhinitis.old"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="allergicRhinitis.dateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="allergicRhinitis.old"
                    )


            h4 TNSS
            tnss-block(
                v-model="allergicRhinitis.tnss"
                ref="allergicRhinitisTnss"
                is-re-contact
            )

            h4 ВАШ
            vas-block(
                v-model="allergicRhinitis.vas"
                ref="allergicRhinitisVas"
                is-re-contact
            )

            h4 Терапия ринита
            therapy-block(
                v-model="allergicRhinitis.therapy"
                ref="allergicRhinitisTherapy"
                is-re-contact
            )

            hr.solid

        app-toggler(
            v-model="allergicConjunctivitis.openBlock"
            label="Аллергический конъюнктивит"
            :disabled="allergicConjunctivitis.old"
        )
        div(v-show="allergicConjunctivitis.openBlock")
            .row
                app-datepicker.re-contact-anamnesis--date(
                    v-model="allergicConjunctivitis.date"
                    :inaccurate="allergicConjunctivitis.dateInaccurate"
                    label="Установка диагноза"
                    :error="$v.$dirty && !$v.allergicConjunctivitis.date.required ? 'Заполните поле' : ''"
                    :disabled="allergicConjunctivitis.old"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="allergicConjunctivitis.dateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="allergicConjunctivitis.old"
                    )


            h4 Терапия конъюнктивита
            therapy-block(
                v-model="allergicConjunctivitis.therapy"
                ref="allergicConjunctivitisTherapy"
                is-re-contact
            )



        app-toggler(
            v-model="polypoidRhinosinusitis.openBlock"
            label="Полипозный риносинусит"
            :disabled="polypoidRhinosinusitis.old"
        )
        div(v-show="polypoidRhinosinusitis.openBlock")
            .row
                app-datepicker.re-contact-anamnesis--date(
                    v-model="polypoidRhinosinusitis.date"
                    :inaccurate="polypoidRhinosinusitis.dateInaccurate"
                    label="Установка диагноза"
                    :error="$v.$dirty && !$v.polypoidRhinosinusitis.date.required ? 'Заполните поле' : ''"
                    :disabled="polypoidRhinosinusitis.old"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="polypoidRhinosinusitis.dateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="polypoidRhinosinusitis.old"
                    )
            .row
                app-text-field.re-contact-anamnesis--polyectomiaCount(
                    v-model="polypoidRhinosinusitis.polyectomiaCount"
                    is-number
                    label="Количество полиэктомий"
                    :error="$v.$dirty && !$v.polypoidRhinosinusitis.polyectomiaCount.required ? 'Заполните поле' : ''"
                )
                app-datepicker.re-contact-anamnesis--lastPolyectomiaDate(
                    v-model="polypoidRhinosinusitis.lastPolyectomiaDate"
                    :inaccurate="polypoidRhinosinusitis.lastPolyectomiaDateInaccurate"
                    label="Последняя полиэктомия"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="polypoidRhinosinusitis.lastPolyectomiaDateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                    )
                app-datepicker.re-contact-anamnesis--date(
                    v-model="polypoidRhinosinusitis.lastKTPPH"
                    :inaccurate="polypoidRhinosinusitis.lastKTPPHInaccurate"
                    label="Последний КТ ППН"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="polypoidRhinosinusitis.lastKTPPHInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                    )
            h4 Консультация ЛОР - врача
            consultations-block(
                v-model="polypoidRhinosinusitis.consultations"
                ref="polypoidRhinosinusitisConsultations"
                is-re-contact
            )
            h4 Snot 20
            snot20-block(
                v-model="polypoidRhinosinusitis.snot20"
                ref="polypoidRhinosinusitisSnot20"
                is-re-contact
            )
            h4 Snot 22
            snot22-block(
                v-model="polypoidRhinosinusitis.snot22"
                ref="polypoidRhinosinusitisSnot22"
                is-re-contact
            )

            h4 Терапия риносинусита
            therapy-block(
                v-model="polypoidRhinosinusitis.therapy"
                ref="polypoidRhinosinusitisTherapy"
                is-re-contact
            )
        app-toggler(
            v-model="gerb.openBlock"
            label="ГЭРБ"
            :disabled="gerb.old"
        )
        div(v-show="gerb.openBlock")
            .row
                app-datepicker.re-contact-anamnesis--date(
                    v-model="gerb.date"
                    :inaccurate="gerb.dateInaccurate"
                    label="Установка диагноза"
                    :error="$v.$dirty && !$v.gerb.date.required? 'Заполните поле' : ''"
                    :disabled="gerb.old"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="gerb.dateInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="gerb.old"
                    )
                .app-choice-group
                    .app-choice-group__label Терапия ГЭРБ
                    .app-choice-group__list
                        app-choice(
                            v-model="gerb.effect"
                            type="radio"
                            label="С эффектом"
                            radio-value="THERAPY_WITH_EFFECT"
                        )
                        app-choice(
                            v-model="gerb.effect"
                            type="radio"
                            label="Без эффекта"
                            radio-value="THERAPY_WITHOUT_EFFECT"
                        )
                        app-choice(
                            v-model="gerb.effect"
                            type="radio"
                            label="Не получает"
                            radio-value="NO_THERAPY"
                        )
            h4 Терапия ГЭРБ
            therapy-block(
                v-model="gerb.therapy"
                ref="gerbTherapy"
                is-re-contact
            )


        app-toggler(
            v-model="angiooteki.openBlock"
            label="Ангиоотеки"
            :disabled="angiooteki.old"
        )
        div(v-show="angiooteki.openBlock")
            .row
                app-datepicker.re-contact-anamnesis--angiootekiDate(
                    v-model="angiooteki.debut"
                    :inaccurate="angiooteki.debutInaccurate"
                    label="Дебют ангиоотеков"
                    :error="$v.$dirty && !$v.angiooteki.debut.required ? 'Заполните поле' : ''"
                    :disabled="angiooteki.old"
                )
                .app-choice-group.app-choice-group--center
                    app-choice(
                        v-model="angiooteki.debutInaccurate"
                        type="checkbox"
                        label="Неточная дата"
                        :disabled="angiooteki.old"
                    )
                app-datepicker.re-contact-anamnesis--angiootekiDate(
                    v-model="angiooteki.lastEdema"
                    label="Последний отек"
                    :error="$v.$dirty && !$v.angiooteki.lastEdema.required ? 'Заполните поле' : ''"
                )

            h4 Локализация отеков
            localization-edema-block(
                v-model="angiooteki.localizations"
                ref="angiootekiLocalizations"
                is-re-contact
            )

            h4 Опросники (Ангиоотеки)
            questionnaires-angiooteki(
                v-model="angiooteki.questionnaires"
                ref="angiootekiQuestionnaires"
                is-re-contact
            )

            .row.br.forDesktop
            hr.solid
            .row.br



        h4 Иные хронические заболевания
        chronic-diseases-block(
            v-model="other.diagnoses"
            ref="otherDiagnoses"
            is-re-contact
        )

        h4 Терапия других заболеваний
        therapy-block(
            v-model="other.therapy"
            ref="otherTherapy"
            is-re-contact
        )

        h4 Обострения других заболеваний
        exacerbations-block(
            v-model="other.exacerbations"
            ref="otherExacerbations"
            with-illness
            nebulizer
            is-re-contact
        )

        h4 Операции и травмы
        injuries-block(
            v-model="injuries"
            ref="injuries"
            is-re-contact
        )

        h4 Рост и вес
        .row(v-if="weights.length")
            app-text-field.re-contact-anamnesis--height(
                v-model="height"
                is-natural-number
                label="Рост, см"
                :disabled="weights.some(w => w.old)"
                :error="$v.$dirty && !$v.height.required ? 'Заполните поле' : ''"
            )
        .br.forDesktop
        weights-block(
            v-model="weights"
            :height="height"
            ref="weights"
            is-re-contact
        )

        hr.solid

        smoking-block(
            v-model="smoking"
            ref="smoking"
            is-re-contact
        )

        .br
        app-text-field.re-contact-anamnesis--otherInformation(
            v-model="otherInformation"
            label="Другое"
            is-textarea
        )

        .row.sticky-buttons-block
            app-button(
                large
                @click="saveAndContinue"
            ) Сохранить и продолжить заполнение
            app-button(
                :loading="saveLoading"
                large
                color="white"
                @click="saveAndClose"
            ) Сохранить и закончить заполнение
            app-link.app-link--red.app-link--left-margin(@click="close") Закрыть без сохранения
</template>

<script>
import saving from '@/mixins/saving';
import TherapyBlock from '@/components/pages/patients/blocks/TherapyBlock';
import TnssBlock from '@/components/pages/patients/blocks/anamnesis/TnssBlock';
import VasBlock from '@/components/pages/patients/blocks/anamnesis/VASBlock';
import ConsultationsBlock from '@/components/pages/patients/blocks/anamnesis/ConsultationsBlock';
import Snot20Block from '@/components/pages/patients/blocks/anamnesis/Snot20Block';
import Snot22Block from '@/components/pages/patients/blocks/anamnesis/Snot22Block';
import ExacerbationsBlock from '@/components/pages/patients/blocks/ExacerbationsBlock';
import QuestionnairesHives from '@/components/pages/patients/questionnaires/QuestionnairesHives';
import LocalizationEdemaBlock from '@/components/pages/patients/blocks/anamnesis/LocalizationEdemaBlock';
import QuestionnairesAngiooteki from '@/components/pages/patients/questionnaires/QuestionnairesAngiooteki';
import ChronicDiseasesBlock from '@/components/pages/patients/blocks/anamnesis/ChronicDiseasesBlock';
import InjuriesBlock from '@/components/pages/patients/blocks/anamnesis/InjuriesBlock';
import WeightsBlock from '@/components/pages/patients/blocks/anamnesis/WeightsBlock';
import SmokingBlock from '@/components/pages/patients/blocks/anamnesis/SmokingBlock';
import HivesInducedTypesBlock from '@/components/pages/patients/blocks/anamnesis/HivesInducedTypesBlock';
import QuestionnairesAsthma from '@/components/pages/patients/questionnaires/QuestionnairesAsthma';
import QuestionnairesDermatitis from '@/components/pages/patients/questionnaires/QuestionnairesDermatitis';
import moment from 'moment';
import { HIVES_TYPES } from '@/js/const';

export default {
    name: 're-contact-anamnesis',

    components: {
        QuestionnairesAsthma,
        QuestionnairesDermatitis,
        HivesInducedTypesBlock,
        SmokingBlock,
        TherapyBlock,
        TnssBlock,
        VasBlock,
        ConsultationsBlock,
        Snot20Block,
        Snot22Block,
        ExacerbationsBlock,
        QuestionnairesHives,
        LocalizationEdemaBlock,
        QuestionnairesAngiooteki,
        ChronicDiseasesBlock,
        InjuriesBlock,
        WeightsBlock,
    },

    mixins: [saving],

    data: () => ({
        sideDermatitis: {
            openBlock: false,
            date: '',
            dateInaccurate: false,
            therapy: [],
            questionnaires: [],
        },

        sideAsthma: {
            openBlock: false,
            date: '',
            dateInaccurate: false,
            therapy: [],
            questionnaires: [],
        },

        sideHives: {
            openBlock: false,
            date: '',
            dateInaccurate: false,
            symptomsDate: '',
            symptomsDateInaccurate: false,
            type: null,
            inducedTypes: null,

            therapy: [],

            exacerbations: [],

            questionnaires: [],
        },

        allergicRhinitis: {
            openBlock: false,
            date: '',
            dateInaccurate: false,
            tnss: [],
            vas: [],
            therapy: [],
        },

        allergicConjunctivitis: {
            openBlock: false,
            date: '',
            dateInaccurate: false,
            therapy: [],
        },

        polypoidRhinosinusitis: {
            openBlock: false,
            date: '',
            dateInaccurate: false,
            polyectomiaCount: '',
            lastPolyectomiaDate: '',
            lastPolyectomiaDateInaccurate: false,
            lastKTPPH: '',
            lastKTPPHInaccurate: false,
            consultations: [],
            snot20: [],
            snot22: [],
            therapy: [],
        },

        gerb: {
            openBlock: false,
            date: '',
            dateInaccurate: false,
            effect: 'THERAPY_WITH_EFFECT',
            therapy: [],
        },

        angiooteki: {
            openBlock: false,
            debut: '',
            debutInaccurate: false,
            lastEdema: '',

            localizations: [],

            questionnaires: [],
        },

        other: {
            diagnoses: [],

            therapy: [],

            exacerbations: [],
        },

        injuries: [],

        height: '',
        weights: [],

        smoking: null,

        otherInformation: '',

        hivesTypes: HIVES_TYPES,
    }),

    validations: {
        sideDermatitis: {
            date: { required(value) { return value || value === 0 || !this.sideDermatitis.openBlock;} },
        },

        sideAsthma: {
            date: { required(value) { return value || value === 0 || !this.sideAsthma.openBlock;} },
        },

        sideHives: {
            date: { required(value) { return value || value === 0 || !this.sideHives.openBlock;} },
            symptomsDate: { required(value) { return value || value === 0 || !this.sideHives.openBlock;} },
            type: { required(value) { return value || !this.sideHives.openBlock;} },
        },

        allergicRhinitis: {
            date: { required(value) { return value || value === 0 || !this.allergicRhinitis.openBlock;} },
        },

        allergicConjunctivitis: {
            date: { required(value) { return value || value === 0 || !this.allergicConjunctivitis.openBlock;} },
        },

        polypoidRhinosinusitis: {
            date: { required(value) { return value || value === 0 || !this.polypoidRhinosinusitis.openBlock;} },
            polyectomiaCount: { required(value) { return value || !this.polypoidRhinosinusitis.openBlock;} },
        },

        gerb: {
            date: { required(value) { return value || value === 0 || !this.gerb.openBlock;} },
        },

        angiooteki: {
            debut: { required(value) { return value || value === 0 || !this.angiooteki.openBlock;} },
            lastEdema: { required(value) { return value || value === 0 || !this.angiooteki.openBlock;} },
        },

        height: {
            required(value) {
                return value || !this.weights.length;
            },
        },
    },

    mounted() {
        if (this.lastData && !this.hasDataFromLocalStorage) {
            const sideDermatitis = this.lastData.sicknesses.find(s => s.type === 'SIDE_DERMATITIS');
            if (sideDermatitis) {
                this.sideDermatitis.openBlock = true;
                this.sideDermatitis.old = true;
                this.sideDermatitis.date = +moment(sideDermatitis.diagnoses[0]?.diagnosis_date);
                this.sideDermatitis.dateInaccurate = sideDermatitis.diagnoses[0]?.diagnosis_date_inaccurate;

                this.sideDermatitis.therapy = this.$refs.sideDermatitisTherapy.getFormattedSavedFields(sideDermatitis.basis_therapies);
                this.sideDermatitis.questionnaires = this.$refs.sideDermatitisQuestionnaires.getFormattedSavedFields(sideDermatitis.questionnaires);
                setTimeout(() => {
                    this.$refs.sideDermatitisQuestionnaires.addNewQuestionnaires(+moment());
                });
            }

            const sideAsthma = this.lastData.sicknesses.find(s => s.type === 'SIDE_ASTHMA');
            if (sideAsthma) {
                this.sideAsthma.openBlock = true;
                this.sideAsthma.old = true;
                this.sideAsthma.date = +moment(sideAsthma.diagnoses[0]?.diagnosis_date);
                this.sideAsthma.dateInaccurate = sideAsthma.diagnoses[0]?.diagnosis_date_inaccurate;

                this.sideAsthma.therapy = this.$refs.sideAsthmaTherapy.getFormattedSavedFields(sideAsthma.basis_therapies);
                this.sideAsthma.questionnaires = this.$refs.sideAsthmaQuestionnaires.getFormattedSavedFields(sideAsthma.questionnaires);
                setTimeout(() => {
                    this.$refs.sideAsthmaQuestionnaires.addNewQuestionnaires(+moment());
                });
            }

            const sideHives = this.lastData.sicknesses.find(s => s.type === 'CHRONIC_URTICARIA');
            if (sideHives) {
                this.sideHives.openBlock = true;
                this.sideHives.old = true;
                this.sideHives.date = +moment(sideHives.diagnoses[0]?.diagnosis_date);
                this.sideHives.dateInaccurate = sideHives.diagnoses[0]?.diagnosis_date_inaccurate;
                this.sideHives.symptomsDate = +moment(sideHives.urticaria_option.first_symptoms_at);
                this.sideHives.symptomsDateInaccurate = sideHives.urticaria_option.first_symptoms_inaccurate;

                this.sideHives.type = this.hivesTypes.find(h => h.id === sideHives.urticaria_option.type);
                this.sideHives.inducedTypes = this.$refs.sideHivesInducedTypes.getFormattedSavedFields(sideHives.urticaria_option);

                this.sideHives.therapy = this.$refs.sideHivesTherapy.getFormattedSavedFields(sideHives.basis_therapies);
                this.sideHives.exacerbations = this.$refs.sideHivesExacerbations.getFormattedSavedFields(sideHives.exacerbations);
                this.sideHives.questionnaires = this.$refs.sideHivesQuestionnaires.getFormattedSavedFields(sideHives.questionnaires);
                setTimeout(() => {
                    this.$refs.sideHivesQuestionnaires.addNewQuestionnaires(+moment());
                });
            }

            const allergicRhinitis = this.lastData.sicknesses.find(s => s.type === 'ALLERGIC_RHINITIS');
            if (allergicRhinitis) {
                this.allergicRhinitis.openBlock = true;
                this.allergicRhinitis.old = true;
                this.allergicRhinitis.date = +moment(allergicRhinitis.diagnoses[0]?.diagnosis_date);
                this.allergicRhinitis.dateInaccurate = allergicRhinitis.diagnoses[0]?.diagnosis_date_inaccurate;
                this.allergicRhinitis.tnss = this.$refs.allergicRhinitisTnss.getFormattedSavedFields(allergicRhinitis.tnss);
                this.allergicRhinitis.vas = this.$refs.allergicRhinitisVas.getFormattedSavedFields(allergicRhinitis.vas);
                this.allergicRhinitis.therapy = this.$refs.allergicRhinitisTherapy.getFormattedSavedFields(allergicRhinitis.basis_therapies);
                setTimeout(() => {
                    this.$refs.allergicRhinitisTnss.addNewTnss(+moment());
                    this.$refs.allergicRhinitisVas.addNewVas(+moment());
                });
            }

            const allergicConjunctivitis = this.lastData.sicknesses.find(s => s.type === 'ALLERGIC_CONJUNCTIVITIS');
            if (allergicConjunctivitis) {
                this.allergicConjunctivitis.openBlock = true;
                this.allergicConjunctivitis.old = true;
                this.allergicConjunctivitis.date = +moment(allergicConjunctivitis.diagnoses[0]?.diagnosis_date);
                this.allergicConjunctivitis.dateInaccurate = allergicConjunctivitis.diagnoses[0]?.diagnosis_date_inaccurate;
                this.allergicConjunctivitis.therapy = this.$refs.allergicConjunctivitisTherapy.getFormattedSavedFields(allergicConjunctivitis.basis_therapies);
            }

            const polypoidRhinosinusitis = this.lastData.sicknesses.find(s => s.type === 'POLYPOID_RHINOSINUSITIS');
            if (polypoidRhinosinusitis) {
                this.polypoidRhinosinusitis.openBlock = true;
                this.polypoidRhinosinusitis.old = true;
                this.polypoidRhinosinusitis.date = +moment(polypoidRhinosinusitis.diagnoses[0]?.diagnosis_date);
                this.polypoidRhinosinusitis.dateInaccurate = polypoidRhinosinusitis.diagnoses[0]?.diagnosis_date_inaccurate;

                this.polypoidRhinosinusitis.polyectomiaCount = polypoidRhinosinusitis.rhinosinusitis_option?.polyectomia_count;
                if (polypoidRhinosinusitis.rhinosinusitis_option?.last_polyectomia_at) {
                    this.polypoidRhinosinusitis.lastPolyectomiaDate = +moment(polypoidRhinosinusitis.rhinosinusitis_option.last_polyectomia_at);
                }
                this.polypoidRhinosinusitis.lastPolyectomiaDateInaccurate = polypoidRhinosinusitis.rhinosinusitis_option?.last_polyectomia_at_inaccurate;
                if (polypoidRhinosinusitis.rhinosinusitis_option?.last_kt_at) {
                    this.polypoidRhinosinusitis.lastKTPPH = +moment(polypoidRhinosinusitis.rhinosinusitis_option.last_kt_at);
                }
                this.polypoidRhinosinusitis.lastKTPPHInaccurate = polypoidRhinosinusitis.rhinosinusitis_option?.last_kt_at_inaccurate;
                this.polypoidRhinosinusitis.consultations = this.$refs.polypoidRhinosinusitisConsultations.getFormattedSavedFields(polypoidRhinosinusitis.consultations);
                this.polypoidRhinosinusitis.snot20 = this.$refs.polypoidRhinosinusitisSnot20.getFormattedSavedFields(polypoidRhinosinusitis.snots.filter(({ version }) => version === 'SNOT20'));
                this.polypoidRhinosinusitis.snot22 = this.$refs.polypoidRhinosinusitisSnot22.getFormattedSavedFields(polypoidRhinosinusitis.snots.filter(({ version }) => version === 'SNOT22'));
                this.polypoidRhinosinusitis.therapy = this.$refs.polypoidRhinosinusitisTherapy.getFormattedSavedFields(polypoidRhinosinusitis.basis_therapies);
                setTimeout(() => {
                    this.$refs.polypoidRhinosinusitisSnot20.addNewSnot20(+moment());
                    this.$refs.polypoidRhinosinusitisSnot22.addNewSnot22(+moment());
                });
            }

            const gerb = this.lastData.sicknesses.find(s => s.type === 'GERB');
            if (gerb) {
                this.gerb.openBlock = true;
                this.gerb.old = true;
                this.gerb.date = +moment(gerb.diagnoses[0]?.diagnosis_date);
                this.gerb.dateInaccurate = gerb.diagnoses[0]?.diagnosis_date_inaccurate;
                this.gerb.effect = gerb.gerb_option?.therapy;
                this.gerb.therapy = this.$refs.gerbTherapy.getFormattedSavedFields(gerb.basis_therapies);
            }

            const angiooteki = this.lastData.sicknesses.find(s => s.type === 'ANGIOOTEK');
            if (angiooteki) {
                this.angiooteki.openBlock = true;
                this.angiooteki.old = true;
                this.angiooteki.debut = +moment(angiooteki.diagnoses[0]?.diagnosis_date);
                this.angiooteki.debutInaccurate = angiooteki.diagnoses[0]?.diagnosis_date_inaccurate;
                this.angiooteki.lastEdema = +moment(angiooteki.angiootek_option.last_edema_at);
                this.angiooteki.localizations = this.$refs.angiootekiLocalizations.getFormattedSavedFields(angiooteki.edema_localizations);
                this.angiooteki.questionnaires = this.$refs.angiootekiQuestionnaires.getFormattedSavedFields(angiooteki.questionnaires);
                setTimeout(() => {
                    this.$refs.angiootekiQuestionnaires.addNewQuestionnaires(+moment());
                });
            }

            const otherSicknesses = this.lastData.sicknesses.filter(s => s.type === 'OTHER');
            this.other.diagnoses = this.$refs.otherDiagnoses.getFormattedSavedFields(otherSicknesses);

            this.other.therapy = this.$refs.otherTherapy.getFormattedSavedFields(this.lastData.other_therapies);
            this.other.exacerbations = this.$refs.otherExacerbations.getFormattedSavedFields(this.lastData.other_exacerbations);

            this.injuries = this.$refs.injuries.getFormattedSavedFields(this.lastData.injuries);
            this.height = this.lastData.height || '';
            this.weights = this.$refs.weights.getFormattedSavedFields(this.lastData.sizes);
            this.smoking = this.$refs.smoking.getFormattedSavedFields(this.lastData.smoking);
            this.otherInformation = this.lastData.life_anamnez_other || '';
        }
    },

    methods: {
        getFormFields() {
            const obj = {};
            if (this.sideDermatitis.openBlock) {
                obj.side_dermatitis = {
                    diagnosis_date: moment(this.sideDermatitis.date).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +this.sideDermatitis.dateInaccurate,
                    basis_therapies: this.$refs.sideDermatitisTherapy.getFieldsForSave(),
                    questionnaires: this.$refs.sideDermatitisQuestionnaires.getFieldsForSave(),
                };
            }
            if (this.sideAsthma.openBlock) {
                obj.side_asthma = {
                    diagnosis_date: moment(this.sideAsthma.date).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +this.sideAsthma.dateInaccurate,
                    basis_therapies: this.$refs.sideAsthmaTherapy.getFieldsForSave(),
                    questionnaires: this.$refs.sideAsthmaQuestionnaires.getFieldsForSave(),
                };
            }
            if (this.sideHives.openBlock) {
                obj.chronic_urticaria = {
                    diagnosis_date: moment(this.sideHives.date).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +this.sideHives.dateInaccurate,
                    first_symptoms_at: moment(this.sideHives.symptomsDate).format('YYYY-MM-DD'),
                    first_symptoms_inaccurate: +this.sideHives.symptomsDateInaccurate,

                    type: this.sideHives.type.id,
                    ...this.$refs.sideHivesInducedTypes.getFieldsForSave(),

                    basis_therapies: this.$refs.sideHivesTherapy.getFieldsForSave(),
                    exacerbations: this.$refs.sideHivesExacerbations.getFieldsForSave(),
                    questionnaires: this.$refs.sideHivesQuestionnaires.getFieldsForSave(),
                };
            }
            if (this.allergicRhinitis.openBlock) {
                obj.allergic_rhinitis = {
                    diagnosis_date: moment(this.allergicRhinitis.date).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +this.allergicRhinitis.dateInaccurate,
                    tnss: this.$refs.allergicRhinitisTnss.getFieldsForSave(),
                    vas: this.$refs.allergicRhinitisVas.getFieldsForSave(),
                    basis_therapies: this.$refs.allergicRhinitisTherapy.getFieldsForSave(),
                };
            }
            if (this.allergicConjunctivitis.openBlock) {
                obj.allergic_conjunctivitis = {
                    diagnosis_date: moment(this.allergicConjunctivitis.date).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +this.allergicConjunctivitis.dateInaccurate,
                    basis_therapies: this.$refs.allergicConjunctivitisTherapy.getFieldsForSave(),
                };
            }
            if (this.polypoidRhinosinusitis.openBlock) {
                obj.polypoid_rhinosinusitis = {
                    diagnosis_date: moment(this.polypoidRhinosinusitis.date).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +this.polypoidRhinosinusitis.dateInaccurate,
                    polyectomia_count: this.polypoidRhinosinusitis.polyectomiaCount,
                    last_polyectomia_at: this.polypoidRhinosinusitis.lastPolyectomiaDate ? moment(this.polypoidRhinosinusitis.lastPolyectomiaDate).format('YYYY-MM-DD') : '',
                    last_polyectomia_at_inaccurate: +this.polypoidRhinosinusitis.lastPolyectomiaDateInaccurate,
                    last_kt_at: this.polypoidRhinosinusitis.lastKTPPH ? moment(this.polypoidRhinosinusitis.lastKTPPH).format('YYYY-MM-DD') : '',
                    last_kt_at_inaccurate: +this.polypoidRhinosinusitis.lastKTPPHInaccurate,
                    consultations: this.$refs.polypoidRhinosinusitisConsultations.getFieldsForSave(),
                    snots: [
                        ...this.$refs.polypoidRhinosinusitisSnot20.getFieldsForSave(),
                        ...this.$refs.polypoidRhinosinusitisSnot22.getFieldsForSave(),
                    ],
                    basis_therapies: this.$refs.polypoidRhinosinusitisTherapy.getFieldsForSave(),
                };
            }
            if (this.gerb.openBlock) {
                obj.gerb = {
                    diagnosis_date: moment(this.gerb.date).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +this.gerb.dateInaccurate,
                    therapy: this.gerb.effect,
                    basis_therapies: this.$refs.gerbTherapy.getFieldsForSave(),
                };
            }
            if (this.angiooteki.openBlock) {
                obj.angiootek = {
                    diagnosis_date: moment(this.angiooteki.debut).format('YYYY-MM-DD'),
                    diagnosis_date_inaccurate: +this.angiooteki.debutInaccurate,
                    last_edema_at: moment(this.angiooteki.lastEdema).format('YYYY-MM-DD'),

                    edema_localizations: this.$refs.angiootekiLocalizations.getFieldsForSave(),
                    questionnaires: this.$refs.angiootekiQuestionnaires.getFieldsForSave(),
                };
            }

            obj.other_sickness = this.$refs.otherDiagnoses.getFieldsForSave();
            obj.other = {};
            obj.other.basis_therapies = this.$refs.otherTherapy.getFieldsForSave();
            obj.other.exacerbations = this.$refs.otherExacerbations.getFieldsForSave();
            obj.injuries = this.$refs.injuries.getFieldsForSave();

            obj.height = this.height;
            obj.sizes = this.$refs.weights.getFieldsForSave();
            if (this.smoking) {
                obj.smoking = this.$refs.smoking.getFieldsForSave();
            }
            if ( this.otherInformation) {
                obj.life_anamnez_other = this.otherInformation;
            }

            return obj;
        },

        checkValid() {
            if (this.$v?.$invalid) {
                this.$v.$touch();
                return false;
            }
            if (this.sideDermatitis.openBlock) {
                if (this.$refs.sideDermatitisTherapy.$v.$invalid || this.$refs.sideDermatitisQuestionnaires.$v.$invalid) {
                    this.$refs.sideDermatitisTherapy.$v.$touch();
                    this.$refs.sideDermatitisQuestionnaires.$v.$touch();
                    return false;
                }
            }
            if (this.sideAsthma.openBlock) {
                if (this.$refs.sideAsthmaTherapy.$v.$invalid || this.$refs.sideAsthmaQuestionnaires.$v.$invalid) {
                    this.$refs.sideAsthmaTherapy.$v.$touch();
                    this.$refs.sideAsthmaQuestionnaires.$v.$touch();
                    return false;
                }
            }
            if (this.sideHives.openBlock) {
                if (this.$refs.sideHivesTherapy.$v.$invalid || this.$refs.sideHivesExacerbations.$v.$invalid || this.$refs.sideHivesQuestionnaires.$v.$invalid || this.$refs.sideHivesInducedTypes.$v.$invalid) {
                    this.$refs.sideHivesTherapy.$v.$touch();
                    this.$refs.sideHivesExacerbations.$v.$touch();
                    this.$refs.sideHivesQuestionnaires.$v.$touch();
                    this.$refs.sideHivesInducedTypes.$v.$touch();
                    return false;
                }
            }
            if (this.allergicRhinitis.openBlock) {
                if (this.$refs.allergicRhinitisTnss.$v.$invalid || this.$refs.allergicRhinitisVas.$v.$invalid || this.$refs.allergicRhinitisTherapy.$v.$invalid) {
                    this.$refs.allergicRhinitisTnss.$v.$touch();
                    this.$refs.allergicRhinitisVas.$v.$touch();
                    this.$refs.allergicRhinitisTherapy.$v.$touch();
                    return false;
                }
            }
            if (this.allergicConjunctivitis.openBlock) {
                if (this.$refs.allergicConjunctivitisTherapy.$v.$invalid) {
                    this.$refs.allergicConjunctivitisTherapy.$v.$touch();
                    return false;
                }
            }
            if (this.polypoidRhinosinusitis.openBlock) {
                if (this.$refs.polypoidRhinosinusitisConsultations.$v.$invalid || this.$refs.polypoidRhinosinusitisSnot20.$v.$invalid || this.$refs.polypoidRhinosinusitisSnot22.$v.$invalid || this.$refs.polypoidRhinosinusitisTherapy.$v.$invalid) {
                    this.$refs.polypoidRhinosinusitisConsultations.$v.$touch();
                    this.$refs.polypoidRhinosinusitisSnot20.$v.$touch();
                    this.$refs.polypoidRhinosinusitisSnot22.$v.$touch();
                    this.$refs.polypoidRhinosinusitisTherapy.$v.$touch();
                    return false;
                }
            }
            if (this.gerb.openBlock) {
                if (this.$refs.gerbTherapy.$v.$invalid) {
                    this.$refs.gerbTherapy.$v.$touch();
                    return false;
                }
            }
            if (this.angiooteki.openBlock) {
                if (this.$refs.angiootekiLocalizations.$v.$invalid || this.$refs.angiootekiQuestionnaires.$v.$invalid) {
                    this.$refs.angiootekiLocalizations.$v.$touch();
                    this.$refs.angiootekiQuestionnaires.$v.$touch();
                    return false;
                }
            }
            if (this.$refs.otherDiagnoses.$v.$invalid) {
                this.$refs.otherDiagnoses.$v.$touch();
                return false;
            }
            if (this.$refs.otherTherapy.$v.$invalid) {
                this.$refs.otherTherapy.$v.$touch();
                return false;
            }
            if (this.$refs.otherExacerbations.$v.$invalid) {
                this.$refs.otherExacerbations.$v.$touch();
                return false;
            }
            if (this.$refs.injuries.$v.$invalid) {
                this.$refs.injuries.$v.$touch();
                return false;
            }
            if (this.$refs.weights.$v.$invalid) {
                this.$refs.weights.$v.$touch();
                return false;
            }
            if (this.$refs.smoking.$v.$invalid) {
                this.$refs.smoking.$v.$touch();
                return false;
            }
            return true;
        },
    },
};
</script>

<style lang="scss">
.re-contact-anamnesis {
    .re-contact-anamnesis {
        &--date {
            width: rem(214px);
        }

        &--lastPolyectomiaDate {
            width: rem(250px);
        }

        &--polyectomiaCount {
            width: rem(218px);
        }

        &--type {
            width: rem(300px);
        }

        &--hospitalizationDays, &--height {
            width: rem(192px);
        }

        &--ORITDays {
            width: rem(128px);
        }

        &--lpu {
            width: rem(231px);
        }

        &--daysOfApplicationOfSGKS {
            width: rem(209px);
        }

        &--exacerbationDate {
            width: rem(215px);
        }

        &--angiootekiDate {
            width: rem(220px);
        }

        &--daysOfNebulizerTherapy {
            width: rem(253px);
        }

        &--otherInformation {
            max-width: rem(1254px);
        }
    }

    @include mobile_or_P {
        max-width: 100vmin;
        margin: 0 auto;

        h4 {
            margin-bottom: 1rem;
        }

        .re-contact-anamnesis {
            &--date,
            &--lastPolyectomiaDate,
            &--polyectomiaCount,
            &--type,
            &--exacerbationDate,
            &--hospitalizationDays,
            &--height,
            &--daysOfApplicationOfSGKS,
            &--angiootekiDate,
            &--daysOfNebulizerTherapy {
                width: 100%;
            }

            &--ORITDays, &--lpu{
                width: rem(155px);
            }

            &--ORITDays {
                margin-right: rem(16px);
            }
        }
    }
}
</style>
