<template lang="pug">
    .allergens-block(:class="{'allergens-block--re-contact': isReContact}")
        .allergens-block__row(
            v-for="(item, index) in allergens"
            :key="item.id"
            :class="{old: item.old}"
        )
            .row
                app-text-field.allergens-block--allergen(
                    v-model="item.allergen"
                    :options="allergensList"
                    is-select
                    allow-custom-value
                    label="Аллергокомпонент"
                    :disabled="additionOnly && item.old"
                    :error="getFieldError(item, index, 'allergen')"
                )
                app-text-field.allergens-block--reactions(
                    v-model="item.reactions"
                    label="Проявление реакции"
                    :disabled="additionOnly && item.old && item.old_values.reactions"
                )
                app-text-field.allergens-block--year(
                    v-model="item.year"
                    label="Год проявления"
                    v-mask="'####'"
                    :disabled="additionOnly && item.old && item.old_values.year"
                )
                app-dropper(
                    :class="{hidden: (isReContact || additionOnly) && item.old}"
                    @click="dropAllergen(index)"
                )
            hr.narrow(v-if="index < allergens.length - 1")
        .br
        app-button(
            plus
            @click="addNewAllergen"
        ) Добавить
</template>

<script>
import { uniqueId } from 'lodash';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'allergens-block',

    props: {
        value: {
            type: Array,
            required: true,
        },

        isReContact: {
            type: Boolean,
            required: false,
            default: false,
        },

        additionOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data: () => ({
        allergens: [],
    }),

    validations: {
        allergens: {
            $each: {
                allergen: {
                    required,
                    uniqual(value, item) {
                        return !this.allergens.some(a => a.id !== item.id && value && a.allergen === value);
                    },
                },
            },
        },
    },

    computed: {
        allergensList() {
            return this.$store.getters.allergens.map(a => ({
                ...a,
                name: a.component,
            }));
        },
    },

    watch: {
        allergens(value) {
            this.$emit('input', value);
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.allergens = this.value;
        });
    },

    methods: {
        addNewAllergen() {
            this.allergens.push({
                id: uniqueId(),
                allergen: '',
                reactions: '',
                year: '',
            });
            this.$v.$reset();
        },

        dropAllergen(index) {
            this.$confirm({
                message: `Вы действительно хотите удалить блок?`,
                button: {
                    no: 'Нет',
                    yes: 'Да',
                },
                callback: confirm => {
                    if (confirm) {
                        this.allergens = this.allergens.filter((d, i) => i !== index);
                    }
                },
            });
        },

        getFieldError(item, index, field) {
            if (this.$v.allergens.$each.$iter[index].$dirty && !this.$v.allergens.$each.$iter[index][field].required) {
                return 'Заполните поле';
            } else if (field === 'allergen' && !this.$v.allergens.$each.$iter[index][field].uniqual) {
                return 'Значение должно быть уникальным';
            } else {
                return null;
            }
        },

        getFieldsForSave() {
            return this.allergens.map(d => {
                const obj = {
                    reactions: d.reactions,
                    year: d.year,
                };
                const allergenFromList = this.allergensList.find(a => a.name === d.allergen);
                if (allergenFromList) {
                    obj.allergen_id = allergenFromList.id;
                } else {
                    obj.allergen_custom = d.allergen;
                }
                return obj;
            });
        },

        getFormattedSavedFields(allergens) {
            return allergens.map(d => {
                const obj = {
                    id: d.id,
                    allergen: d.allergen?.component || d.allergen_custom,
                    reactions: d.reactions || '',
                    year: d.year || '',
                    old: true,
                };
                return {
                    ...obj,
                    old_values: { ...obj },
                };
            });
        },
    },
};
</script>

<style lang="scss">
.allergens-block {
    .allergens-block {
        &--allergen, &--reactions {
            width: rem(438px);
        }

        &--year {
            width: rem(156px);
        }
    }

    &--re-contact {
        .allergens-block__row {
            margin: 0 -1rem 0 -1rem;
            padding: 1rem 1rem 0;

            &:first-child {
                margin-top: -1rem;
            }

            hr {
                margin-bottom: 0;
            }

            &.old {
                background: $light-blue;

                .allergens-block {
                    &--allergen, &--reactions, &--year {
                        opacity: 0.5;

                        pointer-events: none;
                    }
                }
            }
        }
    }

    @include mobile_or_P {
        .allergens-block {
            &--allergen, &--reactions, &--year {
                width: 100%;
            }
        }

        &--re-contact {
            margin-top: 0;
        }
    }
}
</style>
