<template lang="pug">
    .page.new-user
        h2 Новый пользователь
        .container.white-bg.border-r.shadow
            form(@submit.prevent="submit")
                .row
                    app-text-field(
                        v-model="lastName"
                        label="Фамилия"
                        :error="$v.$dirty && !$v.lastName.required ? 'Поле обязательно для заполнения' : ''"
                    )
                    app-text-field(
                        v-model="firstName"
                        label="Имя"
                        :error="$v.$dirty && !$v.firstName.required ? 'Поле обязательно для заполнения' : ''"
                    )
                    app-text-field(
                        v-model="middleName"
                        label="Отчество"
                        :error="$v.$dirty && !$v.middleName.required ? 'Поле обязательно для заполнения' : ''"
                    )
                .row
                    app-text-field.new-user--email(
                        v-model="email"
                        label="Email"
                        :error="emailError"
                    )
                    app-select.new-user--MO(
                        v-model="MO"
                        :options="medicalOrganizations"
                        label="МО"
                        :error="$v.$dirty && !$v.MO.required ? 'Поле обязательно для заполнения' : ''"
                    )
                    app-select.new-user--role(
                        v-model="role"
                        :options="roleList"
                        label="Группа пользователей"
                        :error="$v.$dirty && !$v.role.required ? 'Поле обязательно для заполнения' : ''"
                    )
                    .br.forMobile_or_p
                    app-link.app-link--red(tag="router-link" to="/users") Закрыть
                    app-button(
                        type="submit"
                        :loading="loading"
                        large
                    ) Создать пользователя

</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { ROLES } from '@/js/const';

export default {
    name: 'new-user',

    data: () => ({
        firstName: '',
        lastName: '',
        middleName: '',
        email: '',
        MO: '',
        role: '',

        apiErrors: null,

        loading: false,
    }),

    validations: {
        firstName: { required },
        lastName: { required },
        middleName: { required },
        email: { required, email },
        MO: { required },
        role: { required },
    },

    computed: {
        medicalOrganizations() {
            return this.$store.getters.medicalOrganizations;
        },

        roleList() {
            return Object.keys(ROLES).map(id => ({
                name: ROLES[id],
                id,
            }));
        },

        emailError() {
            if (this.$v.$dirty) {
                if (!this.$v.email.required) {
                    return 'Поле обязательно для заполнения';
                } else if (!this.$v.email.email) {
                    return 'Некорректный формат email';
                }
            }
            return this.apiErrors?.email?.[0];
        },
    },

    beforeCreate() {
        this.$store.dispatch('fetchMedicalOrganizations');
    },

    methods: {
        async submit() {
            this.apiErrors = null;

            if (this.loading) return;

            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }

            const fd = new FormData();
            fd.append('first_name', this.firstName);
            fd.append('last_name', this.lastName);
            fd.append('middle_name', this.middleName);
            fd.append('email', this.email);
            fd.append('role', this.role.id);
            fd.append('medical_organization_id', this.MO.id);

            this.loading = true;

            try {
                await this.axios.post('users/create', fd);
                await this.$router.push({ name: 'Users' });
            } catch (e) {
                this.apiErrors = e?.response?.data?.errors;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.new-user {
    .new-user {
        &--email, &--MO, &--role {
            flex-basis: rem(360px);
        }
    }

    .app-link {
        margin-left: auto;
    }

    @include mobile_or_P {
        .new-user {
            &--email, &--MO, &--role {
                flex-basis: 100%;
            }
        }

        .app-link {
            order: 5;
        }

        form {
            max-width: 100vmin;
            margin: 0 auto;
        }

        .app-button {
            justify-content: center;

            width: 100%;
        }

        .app-link {
            margin-right: auto;
            margin-left: auto;
        }
    }
}
</style>
