<template lang="pug">
    .questionnaire-dlqi
        app-text-field.result-field(
            v-model="result"
            label="DLQI"
            is-number
            :max="30"
            :error="error"
            :disabled="disabled"
        )

        app-link(
            @click="openModal"
            :disabled="disabled"
        ) Рассчитать

        questionnaire-modal(ref="modal")
            .title Расчет DLQI
            .block
                .block-question(
                    v-for="(question, id) in questions"
                    v-if="+id !== 8 || questions[7].value === 0"
                )
                    .block-question__name(v-html="question.name")
                    .app-choice-group
                        app-choice(
                            v-for="answer in question.answers"
                            v-model="question.value"
                            type="radio"
                            :label="answer.name"
                            :radio-value="answer.value"
                            :error="$v.$dirty && $v.questions.$each.$iter[id].value.$invalid"
                        )

            .bottom
                .result(v-if="tempResult !== null") DLQI = <b>{{ tempResult }}</b>
                app-button(
                    large
                    @click="calculate"
                ) Рассчитать
                app-button(
                    large
                    color="white"
                    :disabled="tempResult === null"
                    @click="save"
                ) Сохранить рассчет
</template>

<script>
import questionnaireMixin from '@/mixins/questionnaireMixin';

export default {
    name: 'questionnaire-dlqi',

    mixins: [questionnaireMixin],

    data: () => ({
        questions: {
            1: {
                name: 'На&nbsp;протяжении последней недели насколько сильно беспокоили Вас зуд, чувствительность, болезненность или жжение кожи?',
                answers: [
                    { name: 'Очень сильно', value: 3 },
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                ],
                value: null,
            },
            2: {
                name: 'На&nbsp;протяжении последней недели насколько сильно&nbsp;Вы чувствовали смущение или неловкость из-за состояния Вашей кожи?',
                answers: [
                    { name: 'Очень сильно', value: 3 },
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                ],
                value: null,
            },
            3: {
                name: 'На&nbsp;протяжении последней недели насколько сильно состояние Вашей кожи мешало Вашим походам за&nbsp;покупками, уходу за&nbsp;домом или садом?',
                answers: [
                    { name: 'Очень сильно', value: 3 },
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                    { name: 'Ко мне не относится', value: -1 },
                ],
                value: null,
            },
            4: {
                name: 'На&nbsp;протяжении последней недели насколько сильно состояние Вашей кожи влияло на&nbsp;выбор одежды, которую&nbsp;Вы надевали?',
                answers: [
                    { name: 'Очень сильно', value: 3 },
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                    { name: 'Ко мне не относится', value: -1 },
                ],
                value: null,
            },
            5: {
                name: 'На&nbsp;протяжении последней недели насколько сильно состояние Вашей кожи влияло на&nbsp;Вашу социальную жизнь или досуг?',
                answers: [
                    { name: 'Очень сильно', value: 3 },
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                    { name: 'Ко мне не относится', value: -1 },
                ],
                value: null,
            },
            6: {
                name: 'На&nbsp;протяжении последней недели насколько сильно состояние Вашей кожи затрудняло Ваши занятия спортом?',
                answers: [
                    { name: 'Очень сильно', value: 3 },
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                    { name: 'Ко мне не относится', value: -1 },
                ],
                value: null,
            },
            7: {
                name: 'На&nbsp;протяжении последней недели пропускали&nbsp;ли Вы&nbsp;работу или учебу из-за состояния Вашей кожи?',
                answers: [
                    { name: 'Да', value: 3 },
                    { name: 'Нет', value: 0 },
                    { name: 'Ко мне не относится', value: -1 },
                ],
                value: null,
            },
            8: {
                id: 8,
                name: 'Насколько сильно Вас беспокоило состояние Вашей кожи, когда вы&nbsp;находились на&nbsp;работе или учебе?',
                answers: [
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                ],
                value: null,
            },
            9: {
                name: 'На&nbsp;протяжении последней недели насколько сильно состояние Вашей кожи создавало проблемы с&nbsp;Вашим партнером(-шей) или Вашими близкими друзьями или родственниками?',
                answers: [
                    { name: 'Очень сильно', value: 3 },
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                    { name: 'Ко мне не относится', value: -1 },
                ],
                value: null,
            },
            10: {
                name: 'На&nbsp;протяжении последней недели насколько сильно состояние Вашей кожи было причиной Ваших каких&nbsp;бы то&nbsp;ни&nbsp;было сексуальных проблем?',
                answers: [
                    { name: 'Очень сильно', value: 3 },
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                    { name: 'Ко мне не относится', value: -1 },
                ],
                value: null,
            },
            11: {
                name: 'На&nbsp;протяжении последней недели насколько сильно лечение Вашего кожного заболевания создавало Вам сложности, например, создавало беспорядок в&nbsp;доме или отнимало время?',
                answers: [
                    { name: 'Очень сильно', value: 3 },
                    { name: 'Сильно', value: 2 },
                    { name: 'Незначительно', value: 1 },
                    { name: 'Совсем нет', value: 0 },
                    { name: 'Ко мне не относится', value: -1 },
                ],
                value: null,
            },
        },
    }),

    validations: {
        questions: {
            $each: {
                value: {
                    required(value, question) {
                        return value !== null || (question.id === 8 && this.questions[7].value !== 0);
                    },
                },
            },
        },
    },

    methods: {
        calculate() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$refs.modal.scrollToInvalidField();
                return;
            }

            this.tempResult = Object.values(this.questions).reduce((sum, question) => {
                if (question.id !== 8 || this.questions[7].value === 0) {
                    if (question.value !== -1) {
                        sum += question.value;
                    }
                }
                return sum;
            }, 0);
        },
    },
};
</script>

<style scoped lang="scss">
.questionnaire-dlqi {
    .result-field {
        width: rem(66px);
    }

    @include mobile_or_P {
        .result-field {
            width: rem(200px);
            margin-right: 1.5rem;
        }
    }
}
</style>
