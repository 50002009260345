import Vue from 'vue';
import { isEmpty } from 'lodash';

export default {
    state: () => ({
        medicalOrganizations: [], // Медицинские организации
        administrationMethods: [], // Способы введения
        cancellationReasons: [], // Причины отмены
        causesExacerbations: [], // Причины обострения
        relatives: [], // Родственники
        injuries: [], // Травмы/Операции
        measurements: [], // Единицы измерения
        specificExaminations: [], // Специфические обследования
        functionalTests: [], // Функциональные тесты
        laboratoryIndicators: [], // Лабораторные показатели
        provocativeTests: [], // Лабораторные показатели
        allergens: [], // Аллергокомпоненты
        specialProcedures: [], // КТ и рентген
        periods: [], // Кратность (период)
        drugs: [], // Биологические препараты
    }),

    getters: {
        medicalOrganizations: state => state.medicalOrganizations,
        administrationMethods: state => state.administrationMethods,
        cancellationReasons: state => state.cancellationReasons,
        causesExacerbations: state => state.causesExacerbations,
        relatives: state => state.relatives,
        injuries: state => state.injuries,
        measurements: state => state.measurements,
        specificExaminations: state => state.specificExaminations,
        functionalTests: state => state.functionalTests,
        laboratoryIndicators: state => state.laboratoryIndicators,
        provocativeTests: state => state.provocativeTests,
        allergens: state => state.allergens,
        specialProcedures: state => state.specialProcedures,
        periods: state => state.periods,
        drugs: state => state.drugs,
    },

    mutations: {
        setMedicalOrganizations(state, data) {
            state.medicalOrganizations = data;
        },
        setAdministrationMethods(state, data) {
            state.administrationMethods = data;
        },
        setCancellationReasons(state, data) {
            state.cancellationReasons = data;
        },
        setCausesExacerbations(state, data) {
            state.causesExacerbations = data;
        },
        setRelatives(state, data) {
            state.relatives = data;
        },
        setInjuries(state, data) {
            state.injuries = data;
        },
        setMeasurements(state, data) {
            state.measurements = data;
        },
        setSpecificExaminations(state, data) {
            state.specificExaminations = data;
        },
        setFunctionalTests(state, data) {
            state.functionalTests = data;
        },
        setLaboratoryIndicators(state, data) {
            state.laboratoryIndicators = data;
        },
        setProvocativeTests(state, data) {
            state.provocativeTests = data;
        },
        setAllergens(state, data) {
            state.allergens = data;
        },
        setSpecialProcedures(state, data) {
            state.specialProcedures = data;
        },
        setPeriods(state, data) {
            state.periods = data;
        },
        setDrugs(state, data) {
            state.drugs = data;
        },
    },

    actions: {
        async fetchMedicalOrganizations({ state, commit }, refresh= false) {
            if (!isEmpty(state.medicalOrganizations) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/medical-organization`);
                commit('setMedicalOrganizations', response.data);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchAdministrationMethods({ state, commit }, refresh= false) {
            if (!isEmpty(state.administrationMethods) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/administration-methods`);
                commit('setAdministrationMethods', response.data.administrationMethods);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchCancellationReasons({ state, commit }, refresh= false) {
            if (!isEmpty(state.cancellationReasons) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/cancellation-reason`);
                commit('setCancellationReasons', response.data.cancellationReasons);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchCausesExacerbations({ state, commit }, refresh= false) {
            if (!isEmpty(state.causesExacerbations) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/causes-exacerbations`);
                commit('setCausesExacerbations', response.data.causeOfExacerbations);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchRelatives({ state, commit }, refresh= false) {
            if (!isEmpty(state.relatives) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/relatives`);
                commit('setRelatives', response.data.relatives);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchInjuries({ state, commit }, refresh= false) {
            if (!isEmpty(state.injuries) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/injuries`);
                commit('setInjuries', response.data.injuries);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchMeasurements({ state, commit }, refresh= false) {
            if (!isEmpty(state.measurements) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/measurements`);
                commit('setMeasurements', response.data.measurements);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchSpecificExaminations({ state, commit }, refresh= false) {
            if (!isEmpty(state.specificExaminations) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/specific-examinations`);
                commit('setSpecificExaminations', response.data.specificExaminations);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchFunctionalTests({ state, commit }, refresh= false) {
            if (!isEmpty(state.functionalTests) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/functional-tests`);
                commit('setFunctionalTests', response.data.functionalTests);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchLaboratoryIndicators({ state, commit }, refresh= false) {
            if (!isEmpty(state.laboratoryIndicators) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/laboratory-indicators`);
                commit('setLaboratoryIndicators', response.data.laboratoryIndicators);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchProvocativeTests({ state, commit }, refresh= false) {
            if (!isEmpty(state.provocativeTests) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/provocative-test`);
                commit('setProvocativeTests', response.data.provocativeTests);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchAllergens({ state, commit }, refresh= false) {
            if (!isEmpty(state.allergens) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/allergens`);
                commit('setAllergens', response.data.injuries);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchSpecialProcedures({ state, commit }, refresh= false) {
            if (!isEmpty(state.specialProcedures) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/special-procedures`);
                commit('setSpecialProcedures', response.data.specialProcedures);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchPeriods({ state, commit }, refresh= false) {
            if (!isEmpty(state.periods) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/periods`);
                commit('setPeriods', response.data.periods);
            } catch (e) {
                console.log(e);
            }
        },

        async fetchDrugs({ state, commit }, refresh= false) {
            if (!isEmpty(state.drugs) && !refresh) return;
            try {
                const response = await Vue.axios.get(`dictionaries/drugs`);
                commit('setDrugs', response.data.drugs);
            } catch (e) {
                console.log(e);
            }
        },
    },
};
