<template lang="pug">
    .page.patients-new
        h2 Включение нового пациента
        .container.white-bg.border-r.shadow
            app-horizontal-tabs(
                v-model="activeTabId"
                :tabs="tabs"
            )
            component(
                v-for="tab in tabs"
                v-show="activeTabId === tab.id"
                :is="tab.component"
                :ref="tab.refName"
                :saveLoading="saveLoading"
                :saveErrors="saveErrors"
                @clearSaveError="clearSaveError"
                :enable-atopic-dermatitis="enableAtopicDermatitis"
                @toggleEnableAtopicDermatitis="enableAtopicDermatitis=!enableAtopicDermatitis"
                :enable-bronchial-asthma="enableBronchialAsthma"
                @toggleEnableBronchialAsthma="enableBronchialAsthma=!enableBronchialAsthma"
                :enable-hives="enableHives"
                @toggleEnableHives="enableHives=!enableHives"
                @nextStep="nextStep"
                @save="save"
            )

</template>

<script>
import AppHorizontalTabs from '@/components/app/app-horizontal-tabs/index';
import PatientData from '@/components/pages/patients/PatientData';
import AtopicDermatitis from '@/components/pages/patients/AtopicDermatitis';
import Asthma from '@/components/pages/patients/Asthma';
import Hives from '@/components/pages/patients/Hives';
import Allergyanamnesis from '@/components/pages/patients/Allergyanamnesis';
import Anamnesis from '@/components/pages/patients/Anamnesis';
import Research from '@/components/pages/patients/Research';
import convertObjectToFormData from '@/js/utils/convertObjectToFormData';
import scrollToInvalidField from '@/js/utils/scrollToInvalidField';
import saveErrors from '@/mixins/saveErrors';

export default {
    name: 'patients-new',

    components: {
        Research,
        Anamnesis,
        Allergyanamnesis,
        Asthma,
        Hives,
        AtopicDermatitis,
        PatientData,
        AppHorizontalTabs,
    },

    mixins: [saveErrors],

    data: () => ({
        activeTabId: 0,

        enableAtopicDermatitis: false,
        enableBronchialAsthma: false,
        enableHives: false,

        saveLoading: false,
    }),

    computed: {
        tabs() {
            return [
                {
                    id: 0,
                    name: 'Данные пациента',
                    component: 'PatientData',
                    refName: 'patientData',
                },
                {
                    id: 1,
                    name: 'Атопический дерматит',
                    component: 'AtopicDermatitis',
                    refName: 'atopicDermatitis',
                    disabled: !this.enableAtopicDermatitis,
                },
                {
                    id: 2,
                    name: 'Бронхиальная астма',
                    component: 'Asthma',
                    refName: 'asthma',
                    disabled: !this.enableBronchialAsthma,
                },
                {
                    id: 3,
                    name: 'Крапивница',
                    component: 'Hives',
                    refName: 'hives',
                    disabled: !this.enableHives,
                },
                {
                    id: 4,
                    name: 'Аллергоанамнез',
                    component: 'Allergyanamnesis',
                    refName: 'allergyanamnesis',
                },
                {
                    id: 5,
                    name: 'Анамнез жизни',
                    component: 'Anamnesis',
                    refName: 'anamnesis',
                },
                {
                    id: 6,
                    name: 'Исследования',
                    component: 'Research',
                    refName: 'research',
                },
            ];
        },
    },

    created() {
        this.$store.dispatch('fetchRelatives');
        this.$store.dispatch('fetchAllergens');
        this.$store.dispatch('fetchMeasurements');
        this.$store.dispatch('fetchPeriods');
        this.$store.dispatch('fetchAdministrationMethods');
        this.$store.dispatch('fetchInjuries');
        this.$store.dispatch('fetchDrugs');
        this.$store.dispatch('fetchCancellationReasons');
        this.$store.dispatch('fetchCausesExacerbations');
        this.$store.dispatch('fetchUserList');
        this.$store.dispatch('fetchLaboratoryIndicators');
        this.$store.dispatch('fetchProvocativeTests');
        this.$store.dispatch('fetchFunctionalTests');
        this.$store.dispatch('fetchSpecificExaminations');
        this.$store.dispatch('fetchSpecialProcedures');
    },

    methods: {
        nextStep() {
            this.activeTabId = this.tabs.slice(this.activeTabId+1).find(t => !t.disabled).id;
            setTimeout(() => {
                this.$scrollTo({ y: 0 });
            });
        },

        async save() {
            for (let t of this.tabs) {
                if (!this.$refs[t.refName][0].checkValid()) {
                    this.activeTabId = t.id;
                    setTimeout(scrollToInvalidField);
                    return;
                }
            }

            if (this.saveLoading) return;

            this.saveErrors = {};
            this.saveLoading = true;

            try {
                let fd = {
                    ...this.$refs.patientData[0].getFormFields(),
                    ...this.$refs.atopicDermatitis[0].getFormFields(),
                    ...this.$refs.asthma[0].getFormFields(),
                    ...this.$refs.hives[0].getFormFields(),
                    ...this.$refs.allergyanamnesis[0].getFormFields(),
                    ...this.$refs.anamnesis[0].getFormFields(),
                    ...this.$refs.research[0].getFormFields(),
                };
                await this.axios.post('patients/create', convertObjectToFormData(fd));
                localStorage.removeItem('new-patient-data');
                this.$router.push({ name: 'Patients' });
            } catch (e) {
                console.log(e);
                this.setSaveErrors(e?.response);
            } finally {
                this.saveLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.patients-new {
    padding-bottom: rem(20px);

    @include mobile_or_P {
        padding-bottom: 0;

        .app-horizontal-tabs__item {
            width: rem(235px);
            padding: 0 1rem 1.15rem;
        }
    }
}
</style>
