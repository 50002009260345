import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import Patients from '../views/patients/index';
import PatientsNew from '../views/patients/new/index';
import PatientsReContact from '../views/patients/re-contact/index';
import PatientsEdit from '../views/patients/edit/index';
import PatientsDetail from '../views/patients/detail/index';

import Auth from '../views/auth/index';
import Verify from '../views/auth/verify/index';
import Refresh from '../views/auth/refresh/index';

import Users from '../views/users/index';
import NewUser from '../views/users/new/index';
import EditUser from '../views/users/edit/index';
import Dictionaries from '../views/dictionaries/index';
import EditDictionary from '../views/dictionaries/edit/index';
import Page404 from '../views/404';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/patients',
    },
    {
        path: '/patients',
        name: 'Patients',
        component: Patients,
        meta: { requiresAuth: true },
    },
    {
        path: '/patients/new',
        name: 'PatientsNew',
        component: PatientsNew,
        meta: { requiresAuth: true },
    },
    {
        path: '/patients/re-contact/:id',
        name: 'PatientsReContact',
        component: PatientsReContact,
        meta: { requiresAuth: true },
    },
    {
        path: '/patients/edit/:id',
        name: 'PatientsEdit',
        component: PatientsEdit,
        meta: { requiresAuth: true },
    },
    {
        path: '/patients/:id',
        name: 'PatientsDetail',
        component: PatientsDetail,
        meta: { requiresAuth: true },
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    },
    {
        path: '/users/new',
        name: 'NewUser',
        component: NewUser,
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    },
    {
        path: '/users/edit/:id',
        name: 'EditUser',
        component: EditUser,
        meta: {
            requiresAuth: true,
            roles: ['ADMIN'],
        },
    },
    {
        path: '/dictionaries',
        name: 'Dictionaries',
        component: Dictionaries,
        meta: {
            requiresAuth: true,
            roles: ['ADMIN', 'DOCTOR_EDITOR'],
        },
    },
    {
        path: '/dictionaries/edit/:id',
        name: 'EditDictionary',
        component: EditDictionary,
        meta: {
            requiresAuth: true,
            roles: ['ADMIN', 'DOCTOR_EDITOR'],
        },
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        meta: {
            layout: 'empty-layout',
        },
    },
    {
        path: '/verify',
        name: 'Verify',
        component: Verify,
        meta: {
            layout: 'empty-layout',
        },
    },
    {
        path: '/refresh',
        name: 'Refresh',
        component: Refresh,
        meta: {
            layout: 'empty-layout',
        },
    },
    {
        path: '/404',
        name: '404',
        component: Page404,
        meta: {
            layout: 'empty-layout',
        },
        redirect: '/patients',
    },
    {
        path: '*',
        redirect: '/patients',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach( async (to, from, next) => {
    const checkAccess = () => {
        const role = store.getters.role;
        const enableRoles = to.meta.roles;
        if (enableRoles && !enableRoles.includes(role)) {
            next(from);
        } else {
            next();
        }
    };
    if (store.getters.user) {
        checkAccess();
    } else {
        let token = Vue.cookie.get('token');
        if (token) {
            await store.dispatch('refreshUser', token);
            if (store.getters.user) {
                checkAccess();
            } else if (to.meta.requiresAuth) {
                next({ name: 'Auth' });
            } else {
                next();
            }
        } else {
            if (to.meta.requiresAuth){
                next({ name: 'Auth' });
            } else {
                next();
            }
        }
    }

});

export default router;
